/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ELEMENT_TYPE_ID } from '../../../../redux/slices/element.models';
import { CollectionData, CollectionElement, CollectionElementOption } from '../../../../redux/models/database.model';
import { LABEL_COLOUR_KEYS, getLabelColour } from '../../../../tables/Body/colours';
import classNames from '../../../../utils/tailwind';

interface CollectionTextInputProps {
  name: string;
  type: string;
  prop: string;
  value: any;
  handleChange: (val: string, prop: string) => void;
}

export function CollectionTextInput({ name, type, prop, value, handleChange }: CollectionTextInputProps) {
  const itemId = `collection-input-${name}`;
  return (
    <div className="col-span-full">
      <label htmlFor={itemId} className="block text-sm font-medium leading-6 text-gray-900">
        {name}
      </label>
      <div className="mt-2">
        <input
          type={type}
          name={itemId}
          id={itemId}
          placeholder={`Enter ${name}...`}
          onChange={(e) => handleChange(e.target.value, prop)}
          value={value || ''}
          className="block w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
}

export function CollectionCheckboxInput({ name }: { name: string }) {
  return (
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">
        <input
          id="comments"
          name="comments"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
        />
      </div>
      <div className="text-sm leading-6">
        <label htmlFor="comments" className="font-medium text-gray-900">
          {name}
        </label>
      </div>
    </div>
  );
}

interface OptionsInputProps {
  name: string;
  options: string[];
  value: any;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function OptionsInput({ name, options, value, handleChange }: OptionsInputProps) {
  const itemId = `collection-input-${name}`;
  return (
    <div className="sm:col-span-3">
      <label htmlFor={itemId} className="block text-sm font-medium leading-6 text-gray-900">
        {name}
      </label>
      <div className="mt-2">
        <select
          id={itemId}
          name={itemId}
          value={value}
          onChange={handleChange}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
        >
          <option value="">{`Select ${name}`}</option>
          {options.map((item) => (
            <option key={`collection-element-type-${item}`} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

interface CollectionElementTypeInputProps {
  value: any;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function CollectionElementTypeInput({ value, handleChange }: CollectionElementTypeInputProps) {
  const options = Object.values(ELEMENT_TYPE_ID).sort((a, b) => a.localeCompare(b));
  return <OptionsInput name="Type" options={options} value={value} handleChange={handleChange} />;
}

export function CollectionElementOptionColoutsInput({ value, handleChange }: CollectionElementTypeInputProps) {
  return <OptionsInput name="Colour" options={LABEL_COLOUR_KEYS} value={value} handleChange={handleChange} />;
}

interface CollectionElementOptionsInputProps {
  options: CollectionElementOption[];
  handleEdit: (option: CollectionElementOption) => void;
  handleAdd: () => void;
  handleRemove: (option: CollectionElementOption) => void;
}

export function CollectionElementOptionsInput({
  options,
  handleAdd,
  handleEdit,
  handleRemove,
}: CollectionElementOptionsInputProps) {
  const itemId = `collection-input-options`;
  const labelClass = 'mr-2 inline-flex rounded-full px-2.5 py-0.5 text-xs font-medium';
  return (
    <div className="sm:col-span-3">
      <label htmlFor={itemId} className="block text-sm font-medium hover:font-black leading-6">
        Options
      </label>
      <div className="my-2">
        {options.map((option) => (
          <div key={option.id} className={classNames(getLabelColour(option.color), labelClass)}>
            <button type="button" onClick={() => handleEdit(option)}>
              <p>{option.name || '[no name set]'}</p>
            </button>
            <button type="button" onClick={() => handleRemove(option)}>
              <XMarkIcon className="ml-1 h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={handleAdd}
        className="rounded-md bg-white mt-2 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        Add
      </button>
    </div>
  );
}

interface RelOptionsInputProps {
  element: CollectionElement;
  collections: CollectionData[];
  handleChange: (val: string, prop: string) => void;
}

export function RelOptionsInput({ element, collections, handleChange }: RelOptionsInputProps) {
  const itemId = `collection-input-${element.name}`;
  const collectionId = element.collection_id || '';
  const col = collections.find((collection) => collection.entity_id === collectionId);
  const selectedElements = col?.elements || [];

  return (
    <div className="sm:col-span-3">
      <label htmlFor={itemId} className="block text-sm font-medium leading-6 text-gray-900">
        Collection
      </label>
      <div className="mt-2">
        <select
          id={itemId}
          name={itemId}
          value={collectionId}
          onChange={(e) => handleChange(e.target.value, 'collection_id')}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
        >
          <option value="">Select Collection</option>
          {collections.map((collection) => (
            <option key={`collection-element-type-${collection.entity_id}`} value={collection.entity_id}>
              {collection.name}
            </option>
          ))}
        </select>
      </div>
      <label htmlFor={itemId} className="block text-sm font-medium leading-6 text-gray-900 mt-4">
        Element Display Prop
      </label>
      <div className="mt-2">
        <select
          id={itemId}
          name={itemId}
          value={element.element_display_prop || ''}
          onChange={(e) => handleChange(e.target.value, 'element_display_prop')}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
        >
          <option value="">Select Display Prop</option>
          {selectedElements.map((elementData) => (
            <option key={`collection-element-type-${elementData.name}`} value={elementData.property}>
              {elementData.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
