import React from 'react';
import { DocumentTextIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { FileValues } from '../../TableCell.modals';
import { notify } from '../../../../components/shared/Notification';
import FileApi from './FileApi';
import { Queue } from './attachment.utils';

interface Props {
  file: FileValues;
  queueId: string;
}

const TEXT = 'This file type is not currently being supported please contact support for more information';

export default function TableAttachmentCellItem({ file, queueId }: Props) {
  const isImg = file.filetype === 'image/png' || file.filetype === 'image/jpg' || file.filetype === 'image/jpeg';
  const isPdf = file.filetype === 'application/pdf';
  const isFile = file && file?.filetype !== undefined;
  const handleClick = async () => {
    try {
      const downloadedFile = await FileApi.downloadFile(file);
      Queue.addPath(queueId, downloadedFile.path || '');
      const url = downloadedFile.path;
      if (url) window.open(url, '_blank');
    } catch (error) {
      notify('Error downloading file', 'Please contact support if the problem persists', 'error');
    }
  };
  return (
    <button type="button" onClick={() => handleClick()}>
      <span className="hover:cursor-pointer hover:bg-white w-full break-words text-cell-input-text text-sm min-h-[25px] min-w-[25px] flex items-center relative">
        {isImg && <PhotoIcon className="w-6 h-6" />}
        {isPdf && <DocumentTextIcon className="w-6 h-6" />}
        {!isPdf && !isImg && isFile && (
          <div className="overflow-clip truncate text-ellipsis">
            <p className="text-xs text-gray-600">{TEXT}</p>
          </div>
        )}
      </span>
    </button>
  );
}
