/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import UserApiProvider from '../../services/UserApiProvider';
import { User, UserDirectory } from '../models/auth.models';
import { createContainer, EntityContainer } from './core.models';
import { Organisation } from '../models/database.model';
import { getLocalOrganisation } from './organisation';
import { createDirectory } from './users.utils';

const SLICE_NAME = 'users';

type InitialState = {
  isUsersLoaded: boolean;
  errorOnLoadingData: boolean;
  current: User | null;
  users: EntityContainer<User>;
  view: UserDirectory[];
};

const initialState = {
  isUsersLoaded: false,
  errorOnLoadingData: false,
  current: null,
  users: {},
  view: [],
} as InitialState;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.current = action.payload;
    },
    setUsers(state, action) {
      state.users = createContainer(action.payload);
      state.view = createDirectory(action.payload);
    },
    setAddUser(state, action) {
      const users = {
        ...state.users,
        [action.payload.entity_id]: action.payload,
      };
      state.users = users;
      state.view = createDirectory(Object.values(users));
    },
    setDeleteUser(state, action) {
      const userId = action.payload?.entity_id;
      if (userId) {
        const users = {
          ...state.users,
        };
        delete users[userId];
        state.users = users;
        state.view = createDirectory(Object.values(users));
      }
    },
    setIsUsersLoaded(state, action) {
      state.isUsersLoaded = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.errorOnLoadingData = action.payload;
    },
  },
});

export const { setUsers, setCurrentUser, setIsUsersLoaded, setErrorOnLoadingData, setAddUser, setDeleteUser } =
  slice.actions;

export const loadCurrentUser = (userId: string) => async (dispatch: any) => {
  try {
    const org: Organisation = getLocalOrganisation();
    const orgId = org.entity_id;
    const { data } = await UserApiProvider.getUserById(userId, orgId);
    const user = data?.data || {};
    dispatch(setCurrentUser(user));
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = `Couldn't load users. ${error.message}. Please contact support if the problem persists.`;
      throw new Error(errorMessage);
    }
  }
};

export const loadUsers = (organizationId: string) => async (dispatch: any) => {
  dispatch(setIsUsersLoaded(false));
  try {
    const { data } = await UserApiProvider.getUsers(organizationId);
    const users = data?.data || [];
    dispatch(setUsers(users));
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = `Couldn't load users. ${error.message}. Please contact support if the problem persists.`;
      throw new Error(errorMessage);
    }
  } finally {
    dispatch(setIsUsersLoaded(true));
  }
};

const usersReducer = slice.reducer;
export default usersReducer;
export const allState = (state: any) => state[SLICE_NAME];
export const selectUsers = (state: any): EntityContainer<User> => state[SLICE_NAME].users;
export const selectUserDirectory = (state: any): UserDirectory[] => state[SLICE_NAME].view;
export const selectIsUsersLoaded = (state: any): boolean => state[SLICE_NAME].isUsersLoaded;
export const errorOnLoadingData = (state: any) => state[SLICE_NAME].errorOnLoadingData;
