/* eslint-disable @typescript-eslint/no-explicit-any */
import { PermissionStore, RolePermission } from '../models/auth.models';

export const createPermissionStore = (permissions: RolePermission[]): PermissionStore => {
  return permissions.reduce((store: any, permission: RolePermission) => {
    const parts = permission.name.split(':');
    const key = parts[1] || '';
    const existing = store[key] || [];
    return {
      ...store,
      [key]: existing.length ? [...existing, permission] : [permission],
    };
  }, {});
};

export const otherThings = () => {};
