/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CollectionRequest } from '../slices/collection.models';
import { App } from './app.models';
import { Collection, CollectionElement } from './database.model';
import { DataModel, EntityContainer } from '../slices/core.models';
import { DateRangeType } from '../../utils/date.utils';

export interface ReportDateFilters {
  id: string;
  prop: string;
  range: DateRangeType;
}

export interface ReportConfig {
  collectionId: string;
  groupBy: string;
  name: string;
  operation: string;
  nameElementProp: string;
  valueElementProp: string;
  prop?: string;
  format?: string;
}

export interface ReportData {
  name: string;
  app: App;
  config: ReportConfig;
  date_range?: ReportDateFilters;
  collection: Collection;
  group: CollectionElement;
  filters: CollectionRequest;
}

export type Report = DataModel<ReportData>;

export type ReportsState = {
  isLoaded: boolean;
  isLoading: boolean;
  errorOnLoad: boolean;
  config: Report[];
  reports: EntityContainer<any>;
};

export const configs: ReportConfig[] = [
  // {
  //   collectionId: '9dc04a48-aff2-4db0-b6e6-4ad267e9340f',
  //   groupBy: 'customer_id',
  //   name: 'Invoices',
  //   valueElementProp: '',
  //   nameElementProp: 'customer',
  //   operation: 'sum',
  //   prop: 'total',
  //   format: 'dollar',
  // },
];

export const initialReportState: ReportsState = {
  isLoaded: false,
  isLoading: false,
  errorOnLoad: false,
  config: [],
  reports: {},
};
