import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Spinner from '../../components/shared/Spinner';
import RelationShipListContainer from '../../components/relationship-list/RelationshipListContainer';
import EntityApiProvider from '../../services/EntityApiProvider';
import { CollectionElement, RelsContainer } from '../../redux/models/database.model';
import { createFilters } from '../Body/Cells/TableRelCell/utils';
import { Entity } from '../../redux/models/auth.models';
import { notify } from '../../components/shared/Notification';
import { DEFAULT_REQUEST_BODY } from '../../redux/slices/collection.models';

const sortResults = (items: Entity[], displayName: string) => {
  return [...items].sort((a, b) => {
    const aName = a.data[displayName] || '';
    const bName = b.data[displayName] || '';
    return aName.localeCompare(bName);
  });
};

interface Props {
  open: boolean;
  rels: RelsContainer;
  relUrl: string;
  selectedEntities: Entity[];
  displayName: string;
  isForm: boolean;
  multi: boolean;
  element: CollectionElement;
  saveSelectedEntities: (entities: Entity[]) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submitFormik: () => void;
}

export default function MultiRelationModal({
  open,
  rels,
  selectedEntities,
  relUrl,
  displayName,
  isForm = false,
  multi = false,
  element,
  setOpen,
  saveSelectedEntities,
  submitFormik,
}: Props) {
  const defaultData = rels.data || [];
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState<Entity[]>(defaultData);
  const [selectedEntityData, setSelectedEntityData] = useState<Entity[]>(selectedEntities);
  const handleRemoveEntity = (entity: Entity) => {
    const removed = selectedEntityData.filter((selected) => selected.entity_id !== entity.entity_id);
    setSelectedEntityData(removed);
  };
  const handleSelectEntity = (entity: Entity) => {
    const isSelected = selectedEntityData.some((selected) => selected.entity_id === entity.entity_id);
    if (isSelected) {
      handleRemoveEntity(entity);
    } else {
      setSelectedEntityData([...selectedEntityData, entity]);
    }
  };
  const debouncedResults = useMemo(() => {
    const searchRels = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (relUrl !== '') {
        try {
          setIsLoading(true);
          const filters = val.length > 0 ? createFilters(val, element) : DEFAULT_REQUEST_BODY;
          const response = await EntityApiProvider.getEntityData(relUrl, filters);
          const data = EntityApiProvider.parseResponse(response);
          const sortedData = sortResults(data.items, displayName);
          setResults(sortedData);
        } catch (error) {
          notify(`Error searching ${element.name}`, 'Please contact support.', 'error');
        } finally {
          setIsLoading(false);
        }
      }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      searchRels(e);
    };

    return debounce(handleSearch, 300);
  }, [relUrl, element, displayName]);

  const closeWindow = async () => {
    await saveSelectedEntities(selectedEntityData);
    if (multi && !isForm) {
      submitFormik();
    }
    setOpen(false);
  };

  useEffect(() => {
    const loadData = async (sortKey: string) => {
      if (relUrl !== '') {
        try {
          setIsLoading(true);
          const filters = {
            ...DEFAULT_REQUEST_BODY,
          };
          const response = await EntityApiProvider.getEntityData(relUrl, filters);
          const data = EntityApiProvider.parseResponse(response);
          const sortedData = sortResults(data.items, sortKey);
          setResults(sortedData);
        } catch (error) {
          notify(`Error searching ${element.name}`, 'Please contact support.', 'error');
        } finally {
          setIsLoading(false);
        }
      }
    };
    loadData(displayName);
  }, [relUrl, element, displayName]);
  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeWindow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pt-7 px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full max-w-6xl sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={closeWindow}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <h3 className="font-medium text-xl">{`Select ${element.name || ''}`}</h3>
                  <input
                    className="focus:shadow appearance-none border rounded w-full py-2 px-3 my-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={debouncedResults}
                    placeholder="Search"
                    type="text"
                    name="search"
                  />
                </div>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <div>
                    <RelationShipListContainer
                      searchResults={results}
                      relatedCollection={rels}
                      multiSelect={multi}
                      selectedEntities={selectedEntityData}
                      displayName={displayName}
                      setOpen={setOpen}
                      handleSelect={handleSelectEntity}
                      handleXClick={handleRemoveEntity}
                    />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
