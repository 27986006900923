/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityContainer } from '../../../redux/slices/core.models';
import ApexHomePage from './apex';
import HopperHomePage from './hopper';

const data: EntityContainer<any> = {
  hopper: HopperHomePage,
  apex: ApexHomePage,
};

export default data;
