/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PopupContainer from '../../../../components/popup/PopupContainer';
import { FileValues } from '../../TableCell.modals';
import AttachmentRowItem from './AttachmentRowItem';

interface Props {
  showPopup: boolean;
  formikValue: any;
  editable: boolean;
  setShowPopup: (val: boolean) => void;
  handleXClick: (idx: number) => void;
  handleClick: (fl: FileValues) => void;
}

export default function AttachmentModal({
  showPopup,
  formikValue,
  editable,
  handleClick,
  setShowPopup,
  handleXClick,
}: Props) {
  return (
    <PopupContainer open={showPopup} setOpen={setShowPopup}>
      {formikValue?.map((file: FileValues, idx: number) => {
        return (
          <AttachmentRowItem
            key={`attachment-modal-row-${idx}`}
            file={file}
            idx={idx}
            editable={editable}
            handleOpen={handleClick}
            handleDelete={handleXClick}
          />
        );
      })}
    </PopupContainer>
  );
}
