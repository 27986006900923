/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useMemo } from 'react';
import Analytics from '../utils/analytics';
import { fetchFeatureFlags, getLocalFeatureFlags } from '../redux/slices/organisation';
import { deepEqual } from '../utils/core.utils';
import useAuth from '../hooks/useAuth';

const getInitialData = (name, key) => ({
  key,
  name,
  organization_ids: [],
  resource_ids: [],
  user_ids: [],
  is_enabled: false,
});

const initialState = {
  automation_editor: getInitialData('Automation Editor', 'automation_editor'),
  calendar_view: getInitialData('Calendar View', 'calendar_view'),
  grid_view: getInitialData('Grid View', 'grid_view'),
  board_view: getInitialData('Board View', 'board_view'),
  reports: getInitialData('Reports', 'reports'),
};

export const FeatureFlagsContext = React.createContext(initialState);

export function FeatureFlagsProvider({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const [features, setFeatures] = React.useState(initialState);

  useEffect(() => {
    const handleLoad = async () => {
      try {
        const localData = getLocalFeatureFlags();
        if (localData && Object.keys(localData).length) setFeatures(localData);
        const fetchedData = await fetchFeatureFlags();
        const isEqual = deepEqual(localData, fetchedData);
        if (!isEqual) setFeatures(fetchedData);
      } catch (error) {
        Analytics.capture(error);
      }
    };
    if (isAuthenticated && isInitialized) handleLoad();
  }, [isAuthenticated, isInitialized]);

  const providerValue = useMemo(
    () => ({
      ...features,
    }),
    [features]
  );
  return <FeatureFlagsContext.Provider value={providerValue}>{children}</FeatureFlagsContext.Provider>;
}
