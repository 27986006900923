/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CollectionElement } from '../../redux/models/database.model';
import FormErrorMessage from '../Form/FormErrorMessage';

interface Props {
  element: CollectionElement;
  children: React.ReactNode;
  errors: any;
}

export default function ElementRow({ element, children, errors }: Props) {
  return (
    <div className="py-2 flex flex-col md:flex-row items-center w-full border-b-2 border-gray-100" key={element.name}>
      <p className="whitespace-nowrap text-gray-500 text-sm mr-2 min-w-[20%]">{element.name}</p>
      <div className="w-full">
        {children}
        {errors ? <FormErrorMessage error={`${errors}`} /> : null}
      </div>
    </div>
  );
}
