import React, { useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import classNames from '../../../utils/tailwind';

interface Props<T> {
  placeholder: string;
  selectedItem: T | null;
  data: T[];
  renderListItem: (item: T, selected: boolean) => React.ReactNode;
  keyExtractor: (item: T) => string;
  nameExtractor: (item: T) => string;
  setSeleced: React.Dispatch<React.SetStateAction<T | null>>;
}

export default function DirectoryItemSearch<T>({
  placeholder,
  selectedItem,
  data,
  nameExtractor,
  keyExtractor,
  renderListItem,
  setSeleced,
}: Props<T>) {
  const [query, setQuery] = useState('');
  const filteredPeople =
    query === ''
      ? data
      : data.filter((item: T) => {
          return nameExtractor(item).toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox as="div" value={selectedItem} onChange={setSeleced} nullable>
      <div className="relative mt-2">
        <Combobox.Input
          className={classNames(
            selectedItem === null ? 'text-gray-600' : 'text-gray-900',
            'w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
          )}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: T) => nameExtractor(item) || placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((item: T) => (
              <Combobox.Option
                key={keyExtractor(item)}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-blue-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    {renderListItem(item, selected)}
                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-blue-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
