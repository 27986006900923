/* eslint-disable @typescript-eslint/no-explicit-any */
import { ENTITYURL } from '../../../../constants/core.contants';
import HopperServiceApiProvider from '../../../../services/hopperServiceApiProvider';
import { getValueFromSessionStorage } from '../../../../utils/core.utils';
import { downloadDataConvert, imageDataConvert } from '../../../Form/Form.ults';
import { FileValues } from '../../TableCell.modals';
import FileCache from './FileCache';
import { Queue } from './attachment.utils';

export default class FileApi {
  private static cache: FileCache = new FileCache();

  static download = async (url: string, values: [FileValues]) => {
    if (values.length > 0) {
      const fl = values[0];
      const key = fl.name;
      if (FileApi.cache.exists(key)) {
        return FileApi.cache.get(key);
      }
    }
    const { data } = await HopperServiceApiProvider.downloadFile(url, values);
    const file: FileValues = data.data[0];
    FileApi.cache.add(file);
    return file;
  };

  static downloadFile = async (file: FileValues) => {
    const url = getValueFromSessionStorage(ENTITYURL);
    const downloadValue: any = downloadDataConvert(file);
    const downloadedFile = await FileApi.download(url, downloadValue);
    return downloadedFile;
  };

  // eslint-disable-next-line consistent-return
  static upload = async (url: string, file: File, queueId: string) => {
    const inQueue = Queue.isInQueue(file, queueId);
    if (inQueue) {
      return null;
    }
    Queue.start(queueId, file);
    const fileName = file.name;
    const upload = [{ name: fileName, filetype: file.type }];
    let filePath = '';
    const { data } = await HopperServiceApiProvider.uploadFile(url, upload);
    const uploadObj = data.data[0];
    const imageData = imageDataConvert(fileName, data);
    filePath = imageData.path;
    Queue.add(queueId, imageData);
    await fetch(uploadObj.path, {
      method: 'PUT',
      body: file,
    });
    // const updatedValue: any = updateDataConvert(uploadObj);
    // const path: FileValues = await FileApi.download(url, updatedValue);
    // Queue.addPath(queueId, path.path || '');
    if (filePath !== '') {
      Queue.remove(queueId, file);
    }
    return imageData;
  };
}
