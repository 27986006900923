import DateService from './dateService';

export enum DateRangeType {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Yesterday = 'Yesterday',
  Last7Days = 'Last 7 Days',
  Last30Days = 'Last 30 Days',
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  NextMonth = 'Next Month',
  Custom = 'Custom',
}

export const getDateRangeFilters = (type: DateRangeType) => {
  switch (type) {
    case DateRangeType.Today:
      return {
        dateFrom: DateService.getStartOfTheDayISO(),
        dateTo: DateService.getEndOfTheDayISO(),
      };
    case DateRangeType.Tomorrow:
      return {
        dateFrom: DateService.getStartOfTheDayISO(DateService.addDaysToDateISO(null, 1)),
        dateTo: DateService.getEndOfTheDayISO(DateService.addDaysToDateISO(null, 1)),
      };
    case DateRangeType.Yesterday:
      return {
        dateFrom: DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 1)),
        dateTo: DateService.getEndOfTheDayISO(DateService.subtractDaysFromDateISO(null, 1)),
      };
    case DateRangeType.Last7Days:
      return {
        dateFrom: DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 6)),
        dateTo: DateService.getEndOfTheDayISO(),
      };
    case DateRangeType.Last30Days:
      return {
        dateFrom: DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 29)),
        dateTo: DateService.getEndOfTheDayISO(),
      };
    case DateRangeType.ThisMonth:
      return {
        dateFrom: DateService.getStartOfTheMonthISO(),
        dateTo: DateService.getEndOfTheMonthISO(),
      };
    case DateRangeType.LastMonth:
      return {
        dateFrom: DateService.getStartOfTheMonthISO(DateService.subtractMonthsFromDateISO(null, 1)),
        dateTo: DateService.getEndOfTheMonthISO(DateService.subtractMonthsFromDateISO(null, 1)),
      };
    case DateRangeType.NextMonth:
      return {
        dateFrom: DateService.getStartOfTheMonthISO(DateService.addMonthsToDateISO(null, 1)),
        dateTo: DateService.getEndOfTheMonthISO(DateService.addMonthsToDateISO(null, 1)),
      };
    default:
      return {
        dateFrom: '',
        dateTo: '',
      };
  }
};
