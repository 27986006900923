import React from 'react';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { NetworkError } from '../../redux/slices/core.models';

const HELP = 'If you need immediate help from our customer service team please ';
const NON_URGENT = " If it isn't an urgent matter please contact support to report the issue.";
const SUPPORT_NO = process.env.REACT_APP_SUPPORT_PHONE_NUMBER || '';
export default function ErrorPage({ error }: { error: NetworkError | null }) {
  const code = error?.code || 0;
  const title = error?.title || '';
  const message = error?.message || '';
  return (
    <div className="text-center max-w-2xl">
      <p className="text-base font-semibold text-blue-600">{code}</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{title}</h1>
      <p className="mt-6 text-base leading-7 text-gray-600">{message}</p>
      <p className="mt-6 text-base leading-7 text-gray-600">
        {HELP}
        <a href={`tel:${SUPPORT_NO}`} className="text-blue-600">
          call us.
        </a>
        {NON_URGENT}
      </p>
      <p className="text-gray-600 mt-4">Thanks for your patience!</p>
      <p className="text-gray-600 mt-4">
        For urgent situations please
        <a href={`tel:${SUPPORT_NO}`} className="pl-1 text-blue-600">
          call us.
        </a>
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a
          href="tel:778-836-6894"
          className="rounded-md flex bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          <PhoneIcon className="h-5 w-5" aria-hidden="true" />
          <span className="ml-1">Call us</span>
        </a>
        <a href="www.google.com" className="text-sm font-semibold text-gray-900">
          Contact support <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  );
}
