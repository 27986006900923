/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { RelConfig } from '../form.models';
import { Entity } from '../../../../../redux/models/auth.models';
import { loadEntities } from '../../../../../redux/slices/database';
import { DEFAULT_REQUEST_BODY } from '../../../../../redux/slices/collection.models';
// import EntityApiProvider from '../../../../../services/EntityApiProvider';
import Analytics from '../../../../../utils/analytics';

interface Props {
  name: string;
  value: Entity | null | undefined;
  relConfig: RelConfig;
  onChange: (option: Entity | null | undefined) => void;
}

/**
 * - Find collection in user collections
 * - set base url from app
 * - Build options from app url + collection path
 */
function RelSelectInput({ name, value, relConfig, onChange }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [options, setOptions] = useState<Entity[]>([]);
  const app = relConfig?.app;
  const collection = relConfig?.collection;
  // console.log(config);
  // console.log(value);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const option = options.find((entity) => entity.entity_id === id);
    onChange(option);
  };

  useEffect(() => {
    const load = async (baseUrl: string, path: string) => {
      try {
        setIsLoaded(false);
        const response = await loadEntities(baseUrl, path, DEFAULT_REQUEST_BODY);
        console.log(response);
        if (response.status === 200) {
          const items = response.data.data?.items || [];
          setOptions(items);
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setIsLoaded(true);
      }
    };
    if (!isLoaded) load(app.data.base_url, collection.data.path);
  }, [app, collection, isLoaded]);
  return (
    <select
      id={`select-element-${name}`}
      name={name}
      value={value?.entity_id}
      onChange={handleChange}
      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
    >
      <option value="">{`Select ${name}`}</option>
      {options.map((entity) => {
        const id = entity.entity_id;
        const label = entity.data[relConfig?.collection_display_prop] || '';
        return (
          <option key={id} value={id}>
            {label}
          </option>
        );
      })}
    </select>
  );
}

export default RelSelectInput;
