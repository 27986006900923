/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Auth0Client } from '@auth0/auth0-spa-js';
import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { decodeToken } from 'react-jwt';

import { auth0Config, LOCAL_STORAGE_TOKEN_KEY } from '../config';
import { ALLCOLLECTIONS, ENTITYURL, SIDEBAR } from '../constants/core.contants';
import { removeValueFromSessionStorage } from '../utils/core.utils';
import Analytics from '../utils/analytics';
import DateService from '../utils/dateService';

const INITIALIZE = 'INITIALIZE';
const START_AUTH_REDIRECT = 'START_AUTH_REDIRECT';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const DASHBOARD_SIDEBAR = 'dashboard-sidebar';

export const isTokenValid = (token: string) => {
  if (!token) return false;
  const decoded: any = decodeToken(token);
  const exp = decoded?.exp || null;
  if (exp) {
    const isFuture = DateService.isFutureUnix(exp);
    return isFuture;
  }
  return false;
};

interface InitialState {
  isLoggingIn: boolean;
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: null | any;
}

interface UserToken {
  orgs: string[];
  permissions: string[];
  scope: string;
  sub: string;
}

const initialState: InitialState = {
  isLoggingIn: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const hopperLogo = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/613fc0f54822219d0fed2893_hopper-color.svg';
const logo = process.env.REACT_APP_LOGO_URL || hopperLogo;
const title = process.env.REACT_APP_LOGIN_TITLE || 'Welcome to Hopper';
const description = process.env.REACT_APP_LOGIN_DESCRIPTION || 'Sign In to continue to dashboard';

const authOptions: any = {
  clientId: auth0Config.clientId || '',
  domain: auth0Config.domain || '',
  authorizationParams: {
    audience: auth0Config.audience || '',
    redirect_uri: window.location.origin,
  },
  cacheLocation: 'localstorage',
  logo_url: logo,
  page_title: title,
  page_description: description,
};

export const auth0Client = new Auth0Client(authOptions);

const reducer = (state: InitialState, action: { type: string; payload?: any }): InitialState => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === START_AUTH_REDIRECT) {
    const { isLoggingIn } = action.payload;
    return {
      ...state,
      isLoggingIn,
    };
  }
  if (action.type === SIGN_IN) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        await auth0Client.checkSession();
        // const isAuthenticated = await auth0Client.isAuthenticated();
        const token = await auth0Client.getTokenSilently();
        window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
        const isAuthed = isTokenValid(token);
        if (token && isAuthed) {
          const user = await auth0Client.getUser();
          const userId = user?.email;
          // const orgs = user?.orgs || [];
          // if (orgs.length === 1) {
          //   const org = orgs[0];
          //   setOrgCode(org);
          // };
          if (userId) Analytics.identify(userId);
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user: user || null },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user: null },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      }
    };
    initialize();
  }, []);

  const signIn = async () => {
    dispatch({
      type: START_AUTH_REDIRECT,
      payload: { isLoggingIn: true },
    });
    const loginRedirect = await auth0Client?.loginWithRedirect({
      appState: {
        goToProjects: true, // state to restore when getting redirected back
      },
    });
    return loginRedirect;
  };

  const onloadLogin = async (): Promise<any> => {
    try {
      dispatch({
        type: START_AUTH_REDIRECT,
        payload: { isLoggingIn: true },
      });
      const redirectResult = await auth0Client.handleRedirectCallback();
      const isAuthenticated = await auth0Client?.isAuthenticated();
      dispatch({
        type: START_AUTH_REDIRECT,
        payload: { isLoggingIn: false },
      });
      if (isAuthenticated) {
        const user = await auth0Client?.getUser();
        const token = await auth0Client.getTokenSilently();
        window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
        const userId = user?.email;
        if (userId) Analytics.identify(userId);
        dispatch({ type: SIGN_IN, payload: { user: user || null } });
        window.removeEventListener('load', onloadLogin);
        // window.location.replace(`${window.location.origin}/projects`);
        return redirectResult;
      }
      return window.removeEventListener('load', onloadLogin);
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  if (window.location.href.includes('code')) window.addEventListener('load', onloadLogin);

  const signOut = (): void => {
    auth0Client?.logout({ openUrl: false });
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    window.localStorage.removeItem(DASHBOARD_SIDEBAR);
    removeValueFromSessionStorage(SIDEBAR);
    removeValueFromSessionStorage(ALLCOLLECTIONS);
    removeValueFromSessionStorage(ENTITYURL);
    removeValueFromSessionStorage('organisation');
    dispatch({ type: SIGN_OUT });
  };

  const getToken = (): any | null => {
    const accessToken = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    if (accessToken) {
      const token: UserToken | null = decodeToken(accessToken);
      return token;
    }
    return null;
  };

  const providerValue: any = useMemo(
    () => ({
      ...state,
      method: 'auth0',
      user: {
        id: state?.user?.sub,
        avatar: state?.user?.picture,
        email: state?.user?.email,
        displayName: state?.user?.nickname,
        role: 'user',
      },
      getToken,
      signIn,
      signOut,
    }),
    [state]
  );

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
