/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import moment from 'moment';
import { CollectionElementDropdownSelect } from '../../components/shared/DropdownSelect';
import { CollectionElement } from '../../redux/models/database.model';
import DropdownSelectAPI from '../../components/shared/DropdownSelectAPI';

const valuePlaceholder = 'Enter value';

const formatDateVal = (value: string, element: CollectionElement) => {
  if (element.format) {
    const val = moment(value).format('YYYY-MM-DD');
    return val;
  }
  return value;
};

const formatDateFilter = (value: string, element: CollectionElement) => {
  if (element.format) {
    const val = moment(value, 'YYYY-MM-DD').toISOString();
    return val;
  }
  return value;
};

export default function FilterFormValueInput({ element, filter, options, formik, idx, errors, columns, dataCy }: any) {
  const type = element?.type || '';
  if (type.includes('select')) {
    return (
      <CollectionElementDropdownSelect
        dropdownOptions={options}
        valueId={filter.value}
        setSelected={formik.setValues}
        dataCy={dataCy}
        errors={errors?.filters?.length > 0 ? errors.filters[idx]?.op : undefined}
        idx={idx}
        placeholder={valuePlaceholder}
      />
    );
  }
  if (type.includes('rel')) {
    return (
      <DropdownSelectAPI
        entity={filter?.field?.name || ''}
        errors={errors?.filters?.length > 0 ? errors.filters[idx]?.op : undefined}
        name="value"
        dataCy={dataCy}
        idx={idx}
        placeholder={valuePlaceholder}
        columns={columns}
        formik={formik}
      />
    );
  }

  if (type === 'datetime') {
    return (
      <input
        type="date"
        className="border rounded-md p-2 text-xs"
        data-cy={`${dataCy}-date_input`}
        value={formatDateVal(formik.values.filters[idx].value ?? '', element)}
        required
        onChange={(event) => {
          const newFilters = [...formik.values.filters];
          newFilters[idx].value = formatDateFilter(event.target.value, element) || '';
          formik.setFieldValue('filters', newFilters);
        }}
      />
    );
  }

  if (type === 'bool') {
    return (
      <input
        type="checkbox"
        className="border rounded-md p-2 text-xs"
        placeholder={valuePlaceholder}
        value={formik.values.filters[idx]?.value ?? ''}
        required
        data-cy={`${dataCy}-checkbox`}
        onChange={(event) => {
          const newFilters = [...formik.values.filters];
          newFilters[idx].value = event.target.checked || false;
          formik.setFieldValue('filters', newFilters);
        }}
      />
    );
  }

  return (
    <input
      type={type}
      className="border rounded-md p-2 text-xs"
      placeholder={valuePlaceholder}
      value={formik.values.filters[idx]?.value ?? ''}
      required
      data-cy={`${dataCy}-${type}`}
      onChange={(event) => {
        const newFilters = [...formik.values.filters];
        newFilters[idx].value = event.target.value || '';
        formik.setFieldValue('filters', newFilters);
      }}
    />
  );
}
