/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { EntityContainer } from '../../../../redux/slices/core.models';
import { FormField as FormFieldModel, FormType } from '../forms/form.models';

const { Embedded, Select } = FormType;

export const getConfigValue = (field: FormFieldModel) => {
  if (field.type === Select) return field.value.enum_value;
  return field.value;
};

export const createConfig = (fields: FormFieldModel[]): EntityContainer<any> => {
  return fields.reduce((config, field) => {
    return {
      ...config,
      [field.prop]: field.type !== Embedded ? getConfigValue(field) : createConfig(field.children_fields || []),
    };
  }, {});
};
