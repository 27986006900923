import React from 'react';
import DirectoryItemInput from '../DirectoryItemInput';
import AddNewUserToggle from './AddNewUserToggle';

interface FormProps {
  name: string;
  isNameValid: boolean;
  email: string;
  emailValid: boolean;
  password: string;
  passwordValid: boolean;
  retypePassword: string;
  passwordMatch: boolean;
  newUser: boolean;
  isApex: boolean;
  setNewUser: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: (prop: string, value: string) => void;
  onSubmit: () => void;
}

export default function AddUserForm({
  name,
  isNameValid,
  email,
  emailValid,
  password,
  passwordValid,
  retypePassword,
  passwordMatch,
  newUser,
  isApex,
  setNewUser,
  handleChange,
  onSubmit,
}: FormProps) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <form onSubmit={handleSubmit} className="my-12">
      <DirectoryItemInput
        name="Name"
        type="name"
        placeholder="John Carter"
        isValid={isNameValid}
        value={name}
        prop="name"
        errorMessage="User must include a name"
        handleChange={handleChange}
      />
      <DirectoryItemInput
        name="Email"
        type="email"
        placeholder="email@example.com"
        isValid={emailValid}
        value={email}
        prop="email"
        errorMessage="Not a valid email address"
        handleChange={handleChange}
      />
      {!isApex && <AddNewUserToggle enabled={newUser} setEnabled={setNewUser} />}
      {newUser && (
        <DirectoryItemInput
          name="Password"
          type="password"
          placeholder="*************"
          isValid={passwordValid}
          value={password}
          prop="password"
          errorMessage="Password must include at least 8 characters, one lowercase letter, one uppercase letter, one digit and one special character."
          handleChange={handleChange}
        />
      )}
      {newUser && (
        <DirectoryItemInput
          name="Repeat Password"
          type="password"
          placeholder="*************"
          isValid={passwordMatch}
          value={retypePassword}
          prop="repeatPassword"
          errorMessage="Passwords must match."
          handleChange={handleChange}
        />
      )}
    </form>
  );
}
