/* eslint-disable @typescript-eslint/no-explicit-any */
export interface DataModel<T> {
  author: string;
  data: T;
  entity_id: string;
  entity_type: string;
  modified_at?: string;
  created_at?: string;
  roles?: string[];
  version: string;
}

export const DEFAULT_DATA_MODEL: DataModel<any> = {
  author: '',
  data: {},
  entity_id: '',
  entity_type: '',
  roles: [],
  version: '',
};

export interface EntityContainer<T> {
  [index: string]: T;
}

/**
 * Network Errors:
 * - 400: Bad request
 * - 403: Permissions error
 * - 500: Server error
 */
export interface NetworkError {
  title: string;
  message: string;
  code: number;
}

export const NotFound: NetworkError = {
  code: 404,
  title: 'Page not found',
  message: 'Sorry, we couldn’t find the page you’re looking for.',
};

export const PermissionsError: NetworkError = {
  code: 403,
  title: 'Permissions Error',
  message: "You don't have permission to access this item. Please contact your admin to change your permission levels.",
};

export const ServerError: NetworkError = {
  code: 500,
  title: 'Well, this is unexpected...',
  message: "An error has occured and we're working to fix the problem! We'll be up and running shortly.",
};

export function createContainer<T>(data: DataModel<T>[]): EntityContainer<DataModel<T>> {
  return data.reduce((dir: EntityContainer<DataModel<T>>, item: DataModel<T>) => {
    return {
      ...dir,
      [item.entity_id]: item,
    };
  }, {});
}
