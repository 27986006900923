import { CollectionElement } from '../../../../redux/models/database.model';
import { DEFAULT_REQUEST_BODY } from '../../../../redux/slices/collection.models';
import EntityApiProvider from '../../../../services/EntityApiProvider';

export const createFilters = (search: string, element: CollectionElement) => {
  const filters = DEFAULT_REQUEST_BODY;
  if (search === '') {
    return filters;
  }
  return {
    ...filters,
    filters: [
      {
        field: {
          id: 'Name',
          name: `data.${element.element_display_prop}`,
          type: 'string',
        },
        op: 'regex',
        value: search,
      },
    ],
  };
};

export const searchRels = async (url: string, search: string, element: CollectionElement) => {
  const response = await EntityApiProvider.getEntityData(url, createFilters(search, element));
  const data = EntityApiProvider.parseResponse(response);
  return data.items;
};

// export const loadRel = async (url: string, elementId: string, element: CollectionElement) => {
//   try {
//     const response = await EntityApiProvider.getEntityById(url);
//     const entities = response.data.data.items || [];
//     return entities;
//   } catch (error) {
//     throw error;
//   }
// }
