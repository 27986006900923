/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';

import { selectDisplayGroup, setGroupData } from '../../../redux/slices/database';
import { TableData } from '../../../redux/models/database.model';
import useAppDispatch from '../../../hooks/useAppDispatch';
import DataGrid from '../../DataGrid/DataGrid';

interface Props {
  onEdit: () => void;
}

function GroupedTableContainer({ onEdit }: Props) {
  const dispatch = useAppDispatch();
  const groupData = useSelector(selectDisplayGroup);
  const title = groupData.group?.name || '';
  const handleExpand = (item: TableData) => {
    const data = groupData.data.map((itemData: TableData) => {
      const isOpen = itemData.title === item.title ? !item.isOpen : itemData.isOpen;
      return {
        ...itemData,
        isOpen,
      };
    });
    dispatch(setGroupData(data));
  };
  return (
    <div>
      {groupData.data.map((group: TableData, idx: number) => (
        <DataGrid
          key={`${group.title}-${idx}`}
          title={title}
          displayData={group}
          onExpand={handleExpand}
          search=""
          isEmpty={false}
          onEdit={onEdit}
        />
      ))}
    </div>
  );
}

export default GroupedTableContainer;
