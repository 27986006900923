/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import UserApiProvider from '../../services/UserApiProvider';
import { AUTH } from './slices';
import { User, AuthState, Organisation } from '../models/database.model';
import { saveValueToLocalStorage, setPermissions } from '../../utils/core.utils';
import { AppState } from '../models/app.models';

const SLICE_NAME = AUTH;
const MULTI_ORG_KEY = 'multiOrg';

const initialState = {
  isUsersLoaded: false,
  errorOnLoadingData: false,
  user: null,
} as AuthState;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.user = action.payload;
    },
    setIsUsersLoaded(state, action) {
      state.isUsersLoaded = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.errorOnLoadingData = action.payload;
    },
  },
});

export const { setCurrentUser, setIsUsersLoaded, setErrorOnLoadingData } = slice.actions;

export const loadCurrentUser: any = () => async (dispatch: any) => {
  try {
    const { data } = await UserApiProvider.getUserData();
    const user: User = data?.data;
    const orgs: Organisation[] = user?.data.organizations || [];
    const permissions = user.data.permissions || [];
    const isMultiOrg = orgs.length > 1;
    setPermissions(permissions);
    saveValueToLocalStorage(MULTI_ORG_KEY, isMultiOrg);
    dispatch(setCurrentUser(user));
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = `Couldn't load users. ${error.message}. Please contact support if the problem persists.`;
      throw new Error(errorMessage);
    }
  }
};

const authReducer = slice.reducer;
export default authReducer;
export const allState = (state: AppState) => state[SLICE_NAME];
export const selectUser = (state: AppState): User | null => state[SLICE_NAME].user;
export const selectIsUsersLoaded = (state: AppState): boolean => state[SLICE_NAME].isUsersLoaded;
export const errorOnLoadingData = (state: AppState) => state[SLICE_NAME].errorOnLoadingData;
export const selectPermissions = (state: AppState) => {
  const user = selectUser(state);
  return user?.data.permissions || [];
};
export const selectOrgs = (state: AppState) => {
  const user = selectUser(state);
  const orgs = user?.data.organizations || [];
  return [...orgs].sort((a: Organisation, b: Organisation) => a.data.name.localeCompare(b.data.name));
};

export const selectApps = (state: AppState) => {
  const user = selectUser(state);
  const orgs = user?.data.organizations || [];
  const apps = orgs.flatMap((org) => org.data.apps || []);
  return [...apps];
};
