/* eslint-disable  */
import { FilePond, registerPlugin } from 'react-filepond';
import React, { useEffect, useState } from 'react';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';

import 'filepond/dist/filepond.min.css';
import FileApi from '../Body/Cells/MultiAttachment/FileApi';
import { Queue } from '../Body/Cells/MultiAttachment/attachment.utils';
import { notify } from '../../components/shared/Notification';
import { FileValues } from '../Body/TableCell.modals';
// import { ProcessServerConfigFunction } from 'filepond';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileRename);

type FixedFile = File & { webkitRelativePath?: string };

interface Props {
  queueId: string;
  uploadUrl: string;
  isMulti?: boolean;
  isModal: boolean;
  handleFileUpload: (filepath: FileValues) => void;
}

function Uploader({ handleFileUpload, isMulti = false, isModal, queueId, uploadUrl }: Props) {
  const [files, setFiles] = useState([]);

  // eslint-disable-next-line consistent-return
  const handleUpload = async (file: File) => {
    try {
      const filepath = await FileApi.upload(uploadUrl, file, queueId);
      if (filepath) {
        const key = Queue.fileKey(file);
        setFiles((prev) => [...prev.filter((fl) => Queue.fileKey(fl) !== key)]);
        handleFileUpload(filepath);
      }
    } catch (e) {
      notify("Couldn't upload attachment.", 'Please contact support if the problem persists', 'error');
    }
  };
  const uploadFiles = (files: File[]) => {
    files.forEach((file) => handleUpload(file));
  }
  const server = {
    process: (fieldName: any, file: any, metadata: any, load: any, error: any, progress: any, abort: any) => {
      return {
        abort: () => {
          abort();
        },
      };
    },
  };

  useEffect(() => {
    return () => {
      Queue.suspend(queueId);
    }
  }, [queueId]);

  return (
    <FilePond
      files={files}
      onupdatefiles={(fileItems: any) => {
        const items = fileItems.map((fileItem: any) => fileItem.file);
        setFiles(items);
        uploadFiles(items);
      }}
      allowMultiple={isMulti}
      allowDrop={isModal}
      maxFiles={isMulti ? 20 : 1}
      server={server}
      fileRenameFunction={null}
      credits={false}
      name="files"
      labelIdle={
        isModal
          ? `Drag & Drop document or <span class="filepond--label-action">Browse</span>`
          : `<span class="filepond--label-action">Browse</span>`
      }
    />
  );
}

Uploader.defaultProps = {
  isMulti: false,
};

export default Uploader;
