/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { XMarkIcon, BoltIcon, SquaresPlusIcon, UserIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BRANDING, SidebarItems } from './Sidebar.model';
import { getValueFromSessionStorage } from '../../utils/core.utils';
import { getIsMultiOrgUser, selectOrganisation } from '../../redux/slices/organisation';
import { SIDEBAR } from '../../constants/core.contants';
import classNames from '../../utils/tailwind';
import useAuth from '../../hooks/useAuth';
import { Permission } from '../../redux/models/database.model';
import { getColour } from './colours';
import TopNavItem from './TopNavItem';
import SidebarSearch from './SidebarSearch';
import InfoModal from '../popup/InfoModal';
import useFeatureFlags from '../../hooks/useFeatureFlags';

const HOPPER_LOGO = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/6418aca4cdaacc41798a7b5e_Hopper.png';

const getSearchItems = (searchText: string, items: SidebarItems[]) => {
  if (searchText === '') {
    return items.sort((a, b) => a.title.localeCompare(b.title));
  }
  const searched = items.filter((item: SidebarItems) =>
    item.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );
  return searched.sort((a, b) => a.title.localeCompare(b.title));
};

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Sidebar({ sidebarOpen, setSidebarOpen }: SidebarProps) {
  const { user, signOut } = useAuth();
  const location = useLocation();
  const features = useFeatureFlags();
  const org = useSelector(selectOrganisation);
  const [searchText, setSearchText] = useState('');
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const signOutHandler = () => signOut();
  const isMultiOrg = getIsMultiOrgUser();
  const name = org?.data?.name || '';
  const logoUrl = org?.data?.logo_url || HOPPER_LOGO;
  const orgCode = org?.data?.organization_code;
  const isApex = orgCode === 'apex';
  const branding = isApex ? BRANDING.apex : BRANDING.hopper;
  const activeNav = branding.active;
  const inactiveNav = branding.inactive;
  const permissionData = org?.data.permissions || [];
  const permissions = permissionData.reduce((a: object, b: Permission) => {
    return {
      ...a,
      [b.id]: b,
    };
  }, {});
  const isTopNav =
    permissions.automation !== undefined || permissions.integration !== undefined || permissions.user !== undefined;
  const navigation = [];
  const isReportsEnabled = features.reports?.is_enabled || false;

  if (isReportsEnabled && permissions.report) {
    navigation.push({ name: 'Dashboard', path: `/${orgCode}/dashboard`, icon: ChartBarIcon, current: true });
  }

  if (permissions.automation) {
    navigation.push({ name: 'Automations', path: `/${orgCode}/automations`, icon: BoltIcon, current: true });
  }

  if (permissions.integration) {
    navigation.push({ name: 'Integrations', path: `/${orgCode}/integrations`, icon: SquaresPlusIcon, current: true });
  }

  if (permissions.user) {
    navigation.push({ name: 'Users', path: `/${orgCode}/users`, icon: UserIcon, current: true });
  }

  const sidebarItemData: SidebarItems[] = getValueFromSessionStorage(SIDEBAR) || [];
  const sidebarItems = sidebarItemData.filter((item: SidebarItems) => {
    const id = item.resourceId;
    return permissions[id];
  });
  const subNavItems = getSearchItems(searchText, sidebarItems);

  const handleInfo = () => {
    setIsInfoOpen(true);
  };
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img className={isApex ? 'h-8 w-auto' : 'h-8 w-8 rounded-full'} src={logoUrl} alt={name} />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="px-2">
                    <div className="space-y-1">
                      {navigation.map((item) => (
                        <TopNavItem key={item.path} item={item} />
                      ))}
                    </div>
                    <div className="mt-8">
                      <h3 className="px-3 text-sm font-medium text-gray-500" id="mobile-teams-headline">
                        Database
                      </h3>
                      <div className="mt-1 space-y-1" role="group" aria-labelledby="mobile-teams-headline">
                        {subNavItems.map((item: SidebarItems) => (
                          <NavLink
                            key={item.path}
                            to={`/${orgCode}/collections${item.path}`}
                            className={({ isActive }) =>
                              classNames(
                                isActive ? activeNav : inactiveNav,
                                'group flex items-center rounded-md px-3 py-2 text-base font-medium leading-5'
                              )
                            }
                          >
                            {!isApex ? (
                              <span
                                className={classNames(getColour(item.title), 'mr-4 h-2.5 w-2.5 rounded-full')}
                                aria-hidden="true"
                              />
                            ) : null}
                            <span className="truncate">{item.title}</span>
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={classNames(
          branding.bg,
          'hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 z-50'
        )}
      >
        <div className="flex items-center">
          <div className="flex flex-shrink-0 items-center px-6">
            <img className={isApex ? 'w-full h-24' : 'h-8 w-8 rounded-full'} src={logoUrl} alt={name} />
          </div>
          {!isApex ? <h3 className="font-bold text-lg text-gray-900">{org?.data?.name || ''}</h3> : null}
        </div>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="mt-5 flex h-0 flex-1 flex-col overflow-y-auto pt-1">
          {/* User account dropdown */}
          <Menu as="div" className="relative inline-block px-3 text-left">
            <div>
              <Menu.Button
                className={classNames(
                  branding.profileBg,
                  'group w-full rounded-md px-3.5 py-2 text-left text-sm font-medium'
                )}
              >
                <span className="flex w-full items-center justify-between">
                  <span className="flex min-w-0 items-center justify-between space-x-3">
                    <img
                      className="h-10 w-10 rounded-full flex-shrink-0 rounded-full bg-gray-300"
                      src={user.avatar}
                      alt={user.displayName}
                    />
                    <span className="flex min-w-0 flex-1 flex-col">
                      <span className={classNames(branding.primaryText, 'truncate text-sm font-medium')}>
                        {user.displayName}
                      </span>
                      <span className={classNames(branding.secondaryText, 'truncate text-sm')}>{user.email}</span>
                    </span>
                  </span>
                  <ChevronUpDownIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 left-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {isMultiOrg ? (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/organisations"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Organisations
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                ) : null}
                {isTopNav ? (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={handleInfo}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                        >
                          Info
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ) : null}
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={signOutHandler}
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full text-left'
                        )}
                      >
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          {/* Sidebar Search */}
          <SidebarSearch searchText={searchText} branding={branding.searchBg} setSearch={setSearchText} />
          {/* Navigation */}
          <nav className="mt-6 px-3">
            <div className="space-y-1">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.path}
                  data-cy={`${item.name}-navbar-top-item`}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? branding.active : branding.inactive,
                      'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                    )
                  }
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      location.pathname.includes(item.path)
                        ? 'text-gray-500'
                        : 'text-gray-400 group-hover:text-gray-600',
                      'mr-3 h-6 w-6 flex-shrink-0'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </NavLink>
              ))}
            </div>
            <div className="mt-8">
              {/* Secondary navigation */}
              <h3
                className={classNames(branding.secondaryText, 'px-3 text-sm font-medium')}
                id="desktop-teams-headline"
              >
                Database
              </h3>
              <div className="mt-1 space-y-1" role="group" aria-labelledby="desktop-teams-headline">
                {subNavItems.map((item: SidebarItems) => (
                  <NavLink
                    key={item.path}
                    to={`/${orgCode}/collections${item.path}`}
                    data-cy={`${item.title}-navbar-database-item`}
                    className={({ isActive }) =>
                      classNames(
                        isActive ? activeNav : inactiveNav,
                        'group flex items-center rounded-md px-3 py-2 text-sm font-medium'
                      )
                    }
                  >
                    {!isApex ? (
                      <span
                        className={classNames(getColour(item.title), 'mr-4 h-2.5 w-2.5 rounded-full')}
                        aria-hidden="true"
                      />
                    ) : null}
                    <span className="truncate">{item.title}</span>
                  </NavLink>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>
      <InfoModal open={isInfoOpen} setOpen={setIsInfoOpen} />
    </>
  );
}
