/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { Automation, AutomationStepData, Token } from '../../../redux/slices/automation.models';
import { App, AppAction } from '../../../redux/models/app.models';
import { FormField as FormFieldModel } from './forms/form.models';
import Analytics from '../../../utils/analytics';
import { buildForm, saveStep, deleteStep, loadActionApps, setAutomation } from '../../../redux/slices/automation.details';
import FormField from './forms/FormField';
import { notify } from '../../../components/shared/Notification';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { TextFieldToken } from './forms/FormFields/FormTextInput.utils';
import AutomationFormContainer from './components/AutomationFormContainer';
import AutomationFormHeader from './components/AutomationFormHeader';
import Loader from './components/Loader';
import { createConfig } from './utils/form.utils';

const LOGO_URL = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/6418aca4cdaacc41798a7b5e_Hopper.png';

interface FormLayoutItemProps {
  automation: Automation;
  step: AutomationStepData;
  order: number;
  openStep: number;
  tokens: Token[];
  setOpen: React.Dispatch<React.SetStateAction<number>>;
}

export default function StepLayoutItem({ automation, step, tokens, order, openStep, setOpen }: FormLayoutItemProps) {
  const dispatch = useAppDispatch();
  const defaultApps: App[] = step.app ? [step.app] : [];
  const [apps, setApps] = useState<App[]>(defaultApps);
  const [isLoadingApps, setIsLoadingApps] = useState(false);
  const [app, setSelectedApp] = useState<App | null | undefined>(step.app);
  const actions = app?.data.actions || [];
  const selActn = actions.find((acn) => acn.action_id === step.action_id || '');
  const [appAction, setSelectedAppAction] = useState<AppAction | undefined>(selActn);
  const [formId, setFormId] = useState(step.action?.form_id || '');
  const [fields, setFields] = useState<FormFieldModel[]>([]);
  const [page, setPage] = useState(-1);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const isOpen = openStep === order;
  const appId = app?.entity_id;
  const logoUrl = app?.data.logo_url || LOGO_URL;
  const name = `${order}. ${selActn?.name || 'Action'}`;
  const details = app?.data.name || '';
  const tokenData: TextFieldToken[] = tokens
    .filter((token) => token.position < order)
    .map((token) => {
      return {
        id: token.id,
        display: token.name,
      };
    });

  const buildNextPage = async (formId: string, page: number, config: any) => {
    setIsLoadingForm(true);
    console.log('buildNextPage');
    const response = await buildForm(formId, page, config);
    if (response.status === 200) {
      const formResponseData = response.data;
      console.log(formResponseData);
      setFields(formResponseData.fields);
      setPage(formResponseData.page);
      setIsLoadingForm(false);
    }
  };
  
  const handleOpen = async () => {
    // get trigger info
    try {
      setOpen(order);
      if (formId !== '') {
        const config = step?.config.config || {};
        buildNextPage(formId, -1, config);
      }
    } catch (error) {
      Analytics.capture(error);
    }
  };

  const handleSelectApp = (app: App | undefined) => {
    setSelectedApp(app);
    setSelectedAppAction(undefined);
    setFields([]);
  };

  const handleClose = () => setOpen(0);

  const handleSelectAction = async (acn: AppAction | undefined) => {
    await setSelectedAppAction(acn);
    const frmId = acn?.form_id || '';
    const pg = acn?.start_page || 0;
    const cnfg = acn?.config || null;
    await setFormId(frmId);
    await setPage(pg);
    if (frmId === '') setFields([]);
    if (frmId !== '') buildNextPage(frmId, pg, cnfg);
  }
  const handleForm = async (field: FormFieldModel, idx: number) => {
    const newFields = fields.map((fld, fldIdx) => {
      if (fldIdx === idx) return field;
      return fld;
    });
    setFields(newFields);
    if (field.load_next_page) {
      const config = createConfig(newFields);
      buildNextPage(formId, page, config);
    }
  };
  const handleSave = async () => {
    if (!app || !appAction) return;
    try {
      const requestBody: AutomationStepData = {
        ...step,
        action_id: appAction.action_id,
        app,
        config: createConfig(fields),
        type: appAction.type,
        integration: appAction.integration
      };
      console.log(fields);
      console.log(createConfig(fields));
      const response = await saveStep(automation, requestBody);
      if (response?.status === 200) {
        notify('Success!', 'Saved action', 'success');
        handleClose();
      } else {
        notify('Error', `${response?.status} error code trying to save automation trigger.`, 'error');
      }
    } catch (error) {
      if (error instanceof Error) notify('Error Saving Action', `${error.message}`, 'error');
      Analytics.capture(error);
    }
  };

  const handleDelete = async () => {
    try {
      if (step.automation_step_id !== '') {
        const response = await deleteStep(automation, step);
        if (response.status >= 300) throw new Error('Error deleting');
      }
      const steps = automation.data.steps || [];
      const data = {
        ...automation,
        data: {
          ...automation.data,
          steps: steps.filter((stp) => stp.automation_step_id !== step.automation_step_id),
        },
      };
      dispatch(setAutomation(data));
    } catch (error) {
      Analytics.capture(error);
    }
  };

  useEffect(() => {
    const load = async () => {
      setIsLoadingApps(true);
      const response = await loadActionApps();
      if (response.status === 200) {
        const fetchedApps: App[] = response.data.data || [];
        setApps(fetchedApps);
        if (appId) {
          const selectedApp = fetchedApps.find((fetchedApp) => fetchedApp.entity_id === appId);
          if (selectedApp) setSelectedApp(selectedApp);
        }
      }
      setIsLoadingApps(false);
    };
    if (isOpen) load();
  }, [isOpen, appId]);
  return (
    <AutomationFormContainer
      name={name}
      details={details}
      logoUrl={logoUrl}
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleSave={handleSave}
      handleDelete={handleDelete}
    >
      <AutomationFormHeader
        apps={apps}
        app={app}
        isLoadingApps={isLoadingApps}
        action={appAction}
        handleSelectApp={handleSelectApp}
        handleSelectAction={(acn) => handleSelectAction(acn)}
      >
        {isLoadingForm && <Loader />}
        {!isLoadingForm && fields.length > 0 && (
          <div>
            {fields.filter((fld) => fld.visible).map((fld, idx) => {
              return (
                <FormField
                  key={idx}
                  field={fld}
                  tokens={tokenData}
                  idx={idx}
                  onChange={(field) => handleForm(field, idx)}
                />
              );
            })}
          </div>
        )}
      </AutomationFormHeader>
    </AutomationFormContainer>
  );
}
