/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { axiosInstance } from '../utils/axios';
import HopperServiceConfigProvider from './hopperServiceConfigProvider';
import { CollectionRequest } from '../redux/slices/collection.models';

class ReportApiProvider {
  static async getReportById(reportId: string) {
    return axiosInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.getReportById(reportId),
      validateStatus: () => true,
    });
  }

  static async findReports(filters: CollectionRequest) {
    return axiosInstance({
      method: 'POST',
      url: HopperServiceConfigProvider.findReports(),
      data: filters,
      validateStatus: () => true,
    });
  }

  static parseResponse(response: AxiosResponse<any, any>) {
    switch (response.status) {
      case 200:
        return response.data.data;
      case 403:
        throw new Error("You don't have permissions to view this error");
      case 500:
        throw new Error('Server error');
      default:
        throw new Error('Error loading response');
    }
  }
}

export default ReportApiProvider;
