/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { AutomationTriggerData, Token } from '../../../redux/slices/automation.models';
import { App, AppTrigger } from '../../../redux/models/app.models';
import Analytics from '../../../utils/analytics';
import { buildForm, loadActionApps, loadTriggerApps, saveTrigger } from '../../../redux/slices/automation.details';
import { notify } from '../../../components/shared/Notification';
import AutomationFormContainer from './components/AutomationFormContainer';
import AutomationFormHeader from './components/AutomationFormHeader';
import Loader from './components/Loader';
import { FormField as FormFieldModel } from './forms/form.models';
import { TextFieldToken } from './forms/FormFields/FormTextInput.utils';
import FormField from './forms/FormField';
import { createConfig } from './utils/form.utils';

interface FormLayoutItemProps {
  automationId: string;
  trigger: AutomationTriggerData;
  order: number;
  openStep: number;
  tokens: Token[];
  setOpen: React.Dispatch<React.SetStateAction<number>>;
}

const LOGO_URL = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/6418aca4cdaacc41798a7b5e_Hopper.png';

export default function TriggerLayoutItem({ automationId, trigger, order, tokens, openStep, setOpen }: FormLayoutItemProps) {
  const defaultApps = trigger.app ? [trigger.app] : [];
  const [apps, setApps] = useState<App[]>(defaultApps);
  const [isLoadingApps, setIsLoadingApps] = useState(false);
  const [app, setSelectedApp] = useState<App | null | undefined>(trigger.app);
  const triggers = app?.data.triggers || [];
  const selTrg = triggers.find((trg) => trg.trigger_id === trigger.trigger_id || '');
  const [appTrigger, setSelectedAppTrigger] = useState<AppTrigger | undefined>(selTrg);
  const [formId, setFormId] = useState(trigger.trigger?.form_id || '');

  const appId = app?.entity_id;
  const logoUrl = app?.data.logo_url || LOGO_URL;
  const name = `1. ${selTrg?.name || 'Trigger'}`;
  const details = app?.data.name || '';
  const isOpen = openStep === order;
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [fields, setFields] = useState<FormFieldModel[]>([]);
  const [page, setPage] = useState(trigger.trigger?.start_page || -1);
  const tokenData: TextFieldToken[] = tokens.map((token) => {
    return {
      id: token.id,
      display: token.name,
    };
  });

  const buildNextPage = async (newFormId: string, newPage: number, newConfig: any) => {
    setIsFormLoading(true);
    const response = await buildForm(newFormId, newPage, newConfig);
    if (response.status === 200) {
      const formResponseData = response.data;
      console.log(formResponseData);
      setFields(formResponseData.fields);
      setPage(formResponseData.page);
      setIsFormLoading(false);
    }
  };
  const handleForm = async (field: FormFieldModel, idx: number) => {
    const newFields = fields.map((fld, fldIdx) => {
      if (fldIdx === idx) return field;
      return fld;
    });
    setFields(newFields);
    if (field.load_next_page) {
      const config = createConfig(newFields);
      buildNextPage(formId, page, config);
    }
  };
  const handleOpen = async () => {
    // get trigger info
    try {
      setOpen(order);
      if (formId !== '') {
        const config = trigger?.config.config || {};
        buildNextPage(formId, -1, config);
      }
    } catch (error) {
      Analytics.capture(error);
    }
  };

  const handleClose = () => {
    setOpen(0);
  }

  const handleSelectApp = (selectedApp: App | undefined) => {
    setSelectedApp(selectedApp);
    setSelectedAppTrigger(undefined);
  }

  const handleSelectTrigger = async (trigg: AppTrigger | undefined) => {
    await setSelectedAppTrigger(trigg);
    const frmId = trigg?.form_id || '';
    const pg = trigg?.start_page || 0;
    const cnfg = trigg?.config || {};
    await setFormId(frmId);
    await setPage(pg);
    buildNextPage(frmId, pg, cnfg);
  }
  
  const handleSave = async () => {
    if (!app || !appTrigger) return;
    try {
      console.log(createConfig(fields));
      const response = await saveTrigger(automationId, {
        config: createConfig(fields),
        app,
        trigger_id: appTrigger.trigger_id,
        type: appTrigger.type,
      });
      if (response.status === 200) {
        handleClose();
        notify('Success!', 'Saved trigger', 'success');
      } else {
        notify('Error', `${response.status} error code trying to save automation trigger.`, 'error');
      }
    } catch (error) {
      if (error instanceof Error) notify('Error Saving Automation', `${error.message}`, 'error');
      Analytics.capture(error);
    }
  };
  const showTrigger = false;
  useEffect(() => {
    const load = async () => {
      setIsLoadingApps(true);
      const response = await loadTriggerApps();
      if (response.status === 200) {
        const fetchedApps: App[] = response.data.data || [];
        setApps(fetchedApps);
        if (appId) {
          const selectedApp = fetchedApps.find((fetchedApp) => fetchedApp.entity_id === appId);
          if (selectedApp) setSelectedApp(selectedApp);
        }
      }
      setIsLoadingApps(false);
    };
    if (isOpen) load();
  }, [isOpen, appId]);

  return (
    <AutomationFormContainer
      name={name}
      details={details}
      logoUrl={logoUrl}
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleSave={handleSave}
    >
      <AutomationFormHeader
        apps={apps}
        app={app}
        isLoadingApps={isLoadingApps}
        trigger={appTrigger}
        showTrigger
        handleSelectApp={handleSelectApp}
        handleSelectTrigger={(tr) => handleSelectTrigger(tr)}
      >
        {isFormLoading && <Loader />}
        {!isFormLoading && fields.length > 0 && (
          <div>
            {fields.map((fld, idx) => {
              return (
                <FormField
                  key={idx}
                  field={fld}
                  tokens={tokenData}
                  idx={idx}
                  onChange={(field) => handleForm(field, idx)}
                />
              );
            })}
          </div>
        )}
      </AutomationFormHeader>
    </AutomationFormContainer>
  );
}
