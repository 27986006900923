/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CollectionElement } from '../../redux/models/database.model';
import ElementRow from './ElementRow';
import AddEntityInput from './AddEntityInput';

interface Props {
  elements: CollectionElement[];
  resetArr: boolean;
  formik: any;
  setResetArr: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddEntityElements({ elements, resetArr, formik, setResetArr }: Props) {
  return (
    <div className="w-full">
      {elements?.map((ele) => {
        const errors = formik.errors[ele.property];
        const id = `add-entity-modal-${ele.name}-${ele.position}`;
        return (
          <ElementRow key={id} element={ele} errors={errors}>
            <AddEntityInput
              ele={ele}
              elements={elements}
              resetArr={resetArr}
              formik={formik}
              setResetArr={setResetArr}
            />
          </ElementRow>
        );
      })}
    </div>
  );
}
