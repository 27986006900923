import React from 'react';
import './App.css';
import { useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import routes from './routes';

import { AuthProvider } from './context/Auth0Context';
import store from './redux/store';
import './assets/tailwind/style.scss';
import { Notification } from './components/shared/Notification';
import { FeatureFlagsProvider } from './context/FeatureFlagContext';
import { UserProvider } from './context/UserContext';

function App() {
  const content = useRoutes(routes);
  const title = process.env.REACT_APP_APP_NAME || 'Hopper';
  return (
    <HelmetProvider>
      <Helmet titleTemplate={title} defaultTitle={title} />
      <Provider store={store}>
        <AuthProvider>
          <UserProvider>
            <FeatureFlagsProvider>
              <div className="App">{content}</div>
            </FeatureFlagsProvider>
          </UserProvider>
        </AuthProvider>
        <Notification />
      </Provider>
    </HelmetProvider>
  );
}

export default App;
