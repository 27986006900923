/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { initialPageLoad } from '../../../redux/slices/database';
import useAppDispatch from '../../../hooks/useAppDispatch';
import PageHeader from '../../../components/headers/PageHeader';

import {
  addStep,
  loadAutomation,
  resetAutomation,
  selectAutomation,
  selectIsAutomationLoading,
} from '../../../redux/slices/automation.details';
import { getValueFromSessionStorage } from '../../../utils/core.utils';
import { ALLCOLLECTIONS, SIDEBAR } from '../../../constants/core.contants';
import { getUserOrgByCode, persistOrganisation, selectOrganisation } from '../../../redux/slices/organisation';
import Sidebar from '../../../components/sidebar/Sidebar';
import Navbar from '../../../components/navbar/Navbar';
import FormLayout from './FormLayout';
import Spinner from '../../../components/shared/Spinner';
import AutomationSidebar from './components/AutomationSidebar';
import AddAutomationButton from './components/AddAutomationButton';
import { loadGroups, selectGroupsList, selectIsGroupsLoaded } from '../../../redux/slices/groups';

export default function AutomationDetails() {
  const { id } = useParams();
  const { orgCode }: any = useParams();
  const dispatch: any = useAppDispatch();
  const groups = useSelector(selectGroupsList);
  const isGroupsLoaded = useSelector(selectIsGroupsLoaded);
  const navigate = useNavigate();

  const automation = useSelector(selectAutomation);
  const isLoading = useSelector(selectIsAutomationLoading);
  const sidebarItem = getValueFromSessionStorage(SIDEBAR);
  const collections = getValueFromSessionStorage(ALLCOLLECTIONS);
  const org = useSelector(selectOrganisation);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleAddStep = () => dispatch(addStep(automation));

  useEffect(() => {
    const loadOrg = async (code: string) => {
      const fetchedOrg = await getUserOrgByCode(code);
      if (fetchedOrg) {
        await dispatch(persistOrganisation(fetchedOrg));
        dispatch(initialPageLoad());
      } else {
        navigate('/organisations');
      }
    };
    if (org.entity_id === '') {
      loadOrg(orgCode);
    }
  }, [org, orgCode, dispatch, navigate]);

  useEffect(() => {
    if (!collections || !sidebarItem) {
      dispatch(initialPageLoad());
    }
  }, [collections, sidebarItem, dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(loadAutomation(id));
    }
    return () => {
      dispatch(resetAutomation());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (!isGroupsLoaded) dispatch(loadGroups(orgCode));
  }, [isGroupsLoaded, orgCode, dispatch]);
  return (
    <section className="flex h-screen">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64 w-full">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <PageHeader title={automation?.data?.name || ''} />
        <div className="flex  h-full">
          <div className="p-8 flex-1">
            {isLoading && (
              <div className="flex pt-32 w-full h-full items-center justify-center">
                <Spinner />
              </div>
            )}
            {automation && !isLoading && <FormLayout automation={automation} />}
            {automation && !isLoading && <AddAutomationButton handleAdd={handleAddStep} />}
          </div>
          {automation && !isLoading && <AutomationSidebar automation={automation} groupsList={groups} />}
        </div>
      </div>
    </section>
  );
}
