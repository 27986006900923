/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import React, { useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  width?: string;
  minWidth?: string;
  formikValue: any;
  placeholder?: string;
  editable: boolean;
  setFormikValue: any;
  formikProperty: any;
  submitFormik: any;
}

function CheckboxCell({
  width,
  minWidth,
  formikValue,
  editable,
  setFormikValue,
  placeholder,
  formikProperty,
  submitFormik,
}: Props) {
  const input = React.useRef<HTMLInputElement>(null);
  const [isInput, setIsInput] = useState(false);

  const handleClick = () => {
    if (editable) {
      setIsInput(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e);
    // const typedInput = e.currentTarget.value;
    // setFormikValue(formikProperty, typedInput);
    // if (e.key === 'Enter' && formikValue !== '') {
    //   submitFormik();
    // }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`handle change ${formikProperty}: `, e.target.checked);
    const typedInput = e.target.checked;
    setFormikValue(formikProperty, typedInput);
    submitFormik();
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const typedInput = e.target.checked;
    setFormikValue(formikProperty, typedInput);
    submitFormik();
  };
  return (
    <div className="table-cell-row relative hover:border-2 hover:border-cell-border" style={{ minWidth, width }}>
      <div
        onClick={handleClick}
        className={`table-inner-cell ${isInput ? 'w-full' : 'hover:border-2 hover:border-cell-border'}`}
      >
        <input
          ref={input}
          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          type="checkbox"
          disabled={!editable}
          placeholder={placeholder}
          checked={formikValue || false}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
}

CheckboxCell.defaultProps = {
  width: '100%',
  minWidth: '100%',
  placeholder: '',
};

export default CheckboxCell;
