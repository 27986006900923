/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
import React, { useState } from 'react';
import CollectionInfo from './CollectionInfo';
import CollectionElementForm from './CollectionElementForm';
import { Collection, CollectionData, CollectionElement } from '../../../../redux/models/database.model';
import { ELEMENT_TYPE_ID } from '../../../../redux/slices/element.models';
import { App } from '../../../../redux/models/app.models';

interface Props {
  collection: Collection;
  app: App;
  onSave: (collection: Collection) => void;
}
export default function CollectionDetailsForm({ collection, app, onSave }: Props) {
  const collectionData = app.data.collections || [];
  const collections = collectionData.filter((colllection) => colllection.entity_id !== collection.entity_id);
  const [data, setData] = useState<CollectionData>(collection.data);
  const [elements, setElements] = useState<CollectionElement[]>(collection.data.elements);
  const handleDataChange = (val: string, prop: string) => {
    console.log(`${prop}: ${val}`);
    const updates = {
      ...data,
      [prop]: val,
    };
    setData(updates);
  };
  const handleElementChange = (element: CollectionElement, idx: number) => {
    const updates = elements.map((el, updateIdx) => {
      if (updateIdx === idx) {
        return element;
      }
      return el;
    });
    setElements(updates);
  };
  const handleRemoveElement = (idx: number) => {
    const confirmResult = window.confirm('Are you sure you want to delete the element?');
    if (confirmResult) {
      const updates = elements.filter((el, updateIdx) => updateIdx !== idx);
      setElements(updates);
    }
  };
  const handleAddElement = () => {
    const defaultElement: CollectionElement = {
      collection_id: null,
      element_display_prop: null,
      format: null,
      name: '',
      options: null,
      position: elements.length - 1,
      property: '',
      precision: null,
      required: false,
      type: ELEMENT_TYPE_ID.string,
      rel_filter_prop: null,
      width: 150,
      visible: true,
    };
    setElements([...elements, defaultElement]);
  };
  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updates = {
      ...collection,
      data: {
        ...data,
        elements,
      },
    };
    onSave(updates);
  };
  return (
    <form onSubmit={handleSave}>
      <div className="space-y-12">
        <CollectionInfo collection={data} handleChange={handleDataChange} handleAdd={handleAddElement} />
        {elements.map((el, idx) => (
          <CollectionElementForm
            key={idx}
            element={el}
            collections={collections}
            idx={idx}
            handleChange={handleElementChange}
            handleRemove={handleRemoveElement}
          />
        ))}
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
