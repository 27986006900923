/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from '../../../../utils/tailwind';
import { Colours, Feature, FeatureItem } from '../../models';

export default function TextLeftFeature({ colours, data }: { colours: Colours; data: Feature }) {
  return (
    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
      <div className="relative">
        <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{data.header}</h3>
        <p className="mt-3 text-lg text-gray-500">{data.details}</p>
        <dl className="mt-10 space-y-10">
          {data.items.map((item: FeatureItem, idx: number) => (
            <div key={`left-layout-feature-item-${idx}`} className="relative">
              <dt>
                <div
                  className={classNames(
                    colours.buttonBg,
                    'absolute flex h-12 w-12 items-center justify-center rounded-xl  text-white'
                  )}
                >
                  <item.icon className="h-8 w-8" aria-hidden="true" />
                </div>
                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{item.header}</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">{item.details}</dd>
            </div>
          ))}
        </dl>
      </div>

      <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
        <svg
          className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
        >
          <defs>
            <pattern
              id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
        </svg>
        <img className="relative mx-auto" width={490} src={data.imageUrl} alt={`${data.header}`} />
      </div>
    </div>
  );
}
