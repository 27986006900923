/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';

import Sidebar from '../../components/sidebar/Sidebar';
import { initialPageLoad } from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';
import Navbar from '../../components/navbar/Navbar';
import { Tab, Tabs } from './models';

import { selectOrganisation } from '../../redux/slices/organisation';
import { getValueFromSessionStorage } from '../../utils/core.utils';
import { ALLCOLLECTIONS, SIDEBAR } from '../../constants/core.contants';

import {
  selectIsUsersLoaded,
  loadUsers,
  selectUserDirectory,
  selectUsers,
  setDeleteUser,
} from '../../redux/slices/users';
import {
  loadGroups,
  loadOrgPermissions,
  selectGroupDirectory,
  selectGroups,
  selectIsGroupsLoaded,
  selectIsPermissionsLoaded,
  selectPermissions,
  setDeleteGroup,
} from '../../redux/slices/groups';
import { Group, User } from '../../redux/models/auth.models';
import UserProfile from './user/UserProfile';
import GroupProfile from './group/GroupProfile';
import DirectoryHeader from './directory/DirectoryHeader';
import UserDirectoryList from './directory/UserDirectoryList';
import GroupDirectoryList from './directory/GroupDirectoryList';
import AddUserModal from './modal/user/AddUserModal';
import AddGroupModal from './modal/group/AddGroupModal';
import Spinner from '../../components/shared/Spinner';
import { canCreateUsersAndGroups } from '../../redux/slices/groups.utils';

function UsersList() {
  // Selectors
  const { orgCode }: any = useParams();
  const users = useSelector(selectUsers);
  const userDirectory = useSelector(selectUserDirectory);
  const isUsersLoaded = useSelector(selectIsUsersLoaded);
  const isGroupsLoaded = useSelector(selectIsGroupsLoaded);
  const isPermissionsLoaded = useSelector(selectIsPermissionsLoaded);
  const groups = useSelector(selectGroups);
  const groupsDirectory = useSelector(selectGroupDirectory);
  const permissions = useSelector(selectPermissions);
  const org = useSelector(selectOrganisation);

  // State
  const [tabs, setTabs] = useState<Tab[]>(Tabs);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [addUser, setAddUser] = useState<boolean>(false);
  const [addGroup, setAddGroup] = useState<boolean>(false);

  // data
  const entityId = org?.entity_id;
  const apps = org?.data.apps || [];
  const appData =
    [...apps].sort((a, b) => {
      const aName = a.data.name || '';
      const bName = b.data.name || '';
      return aName.localeCompare(bName);
    }) || [];
  const dispatch: any = useAppDispatch();
  const sidebarItem = getValueFromSessionStorage(SIDEBAR);
  const collections = getValueFromSessionStorage(ALLCOLLECTIONS);
  const isUsersSelected = tabs[0].current;
  const groupList = Object.values(groups);
  const isCreateEnabled = canCreateUsersAndGroups(org);
  const isGroups = groupList.length > 0;
  const isUsers = Object.values(users).length > 0;

  // helpers
  const handleSelectTab = (tab: Tab) => {
    const updates = tabs.map((tb: Tab) => ({
      ...tb,
      current: tb.name === tab.name,
    }));
    setTabs(updates);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleAddUser = () => {
    setAddUser(true);
  };

  const handleAddGroup = () => {
    setAddGroup(true);
  };

  const handleDeleteUser = (user: User | null) => {
    if (user) {
      const userData = Object.values(users).filter((us: User) => us.entity_id !== user.entity_id);
      if (userData.length) {
        userData.sort((a: User, b: User) => {
          const aName = a.data.name || '';
          const bName = b.data.name || '';
          return aName.localeCompare(bName);
        });
        const [firstUser] = userData;
        if (firstUser) {
          setSelectedUser(firstUser);
        }
      }
      dispatch(setDeleteUser(user));
    }
  };

  const handleDeleteGroup = (group: Group | null) => {
    if (group) {
      const groupData = Object.values(groups).filter((gr: Group) => gr.id !== group.id);
      if (groupData.length) {
        groupData.sort((a: Group, b: Group) => {
          const aName = a.name || '';
          const bName = b.name || '';
          return aName.localeCompare(bName);
        });
        const [firstGroup] = groupData;
        if (firstGroup) {
          setSelectedGroup(firstGroup);
        }
      } else {
        setSelectedGroup(null);
      }
      dispatch(setDeleteGroup(group));
    }
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300);
  }, []);

  useEffect(() => {
    if (!collections || !sidebarItem) {
      dispatch(initialPageLoad());
    }
  }, [collections, sidebarItem, dispatch]);

  useEffect(() => {
    const handleLoad = async (orgId: string, orgCodes: string) => {
      if (!isUsersLoaded) {
        dispatch(loadUsers(orgId));
      }
      if (!isGroupsLoaded) {
        dispatch(loadGroups(orgCodes));
      }
      if (!isPermissionsLoaded) {
        dispatch(loadOrgPermissions(orgId));
      }
    };
    if (orgCode && entityId) {
      handleLoad(entityId, orgCode);
    }
  }, [dispatch, orgCode, entityId, isUsersLoaded, isGroupsLoaded, isPermissionsLoaded]);

  useEffect(() => {
    const userData = Object.values(users);
    if (!selectedUser && userData.length) {
      userData.sort((a: User, b: User) => {
        const aName = a.data.name || '';
        const bName = b.data.name || '';
        return aName.localeCompare(bName);
      });
      const [firstUser] = userData;
      if (firstUser) {
        setSelectedUser(firstUser);
      }
    }
    const groupData = Object.values(groups);
    if (!selectedGroup && groupData.length) {
      groupData.sort((a: Group, b: Group) => {
        const aName = a.name || '';
        const bName = b.name || '';
        return aName.localeCompare(bName);
      });
      const [firstGroup] = groupData;
      if (firstGroup) {
        setSelectedGroup(firstGroup);
      }
    }
  }, [users, selectedUser, groups, selectedGroup]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  return (
    <div className="min-h-full overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64 overflow-hidden">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1">
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
              {/* Breadcrumb */}
              <nav className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
                <a
                  href="app.gethopper.io"
                  className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span>Directory</span>
                </a>
              </nav>
              {isUsersSelected && isUsersLoaded && isUsers ? (
                <UserProfile
                  user={selectedUser}
                  groups={groups}
                  orgId={entityId}
                  canEdit={isCreateEnabled}
                  handleDelete={handleDeleteUser}
                />
              ) : null}
              {!isUsersSelected && isGroupsLoaded && isGroups ? (
                <GroupProfile
                  group={selectedGroup}
                  apps={appData}
                  orgId={entityId}
                  orgName={org.data.name}
                  permissionStore={permissions}
                  userStore={users}
                  canEdit={isCreateEnabled}
                  handleDelete={handleDeleteGroup}
                />
              ) : null}
            </main>
            <aside className="hidden w-96 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
              <DirectoryHeader
                title="Directory"
                details={`Search directory of ${Object.keys(users).length} users and ${groupList.length} groups.`}
                tabs={tabs}
                create={isCreateEnabled}
                handleAddUser={handleAddUser}
                handleAddGroup={handleAddGroup}
                handleSelectTab={handleSelectTab}
                handleSearch={debouncedResults}
              />
              <nav className="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">
                {!isUsersLoaded ? (
                  <div className="w-full h-36 flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : null}
                {isUsersSelected && isUsersLoaded ? (
                  <UserDirectoryList
                    directory={userDirectory}
                    searchText={searchText}
                    isLoaded={isUsersLoaded}
                    setSelected={setSelectedUser}
                  />
                ) : null}
                {!isUsersSelected && isGroupsLoaded ? (
                  <GroupDirectoryList
                    directory={groupsDirectory}
                    searchText={searchText}
                    isLoaded={isGroupsLoaded}
                    setSelected={setSelectedGroup}
                  />
                ) : null}
              </nav>
            </aside>
          </div>
        </main>
      </div>
      <AddUserModal organizationId={entityId} open={addUser} setOpen={setAddUser} />
      <AddGroupModal orgCode={orgCode} open={addGroup} setOpen={setAddGroup} />
    </div>
  );
}

export default UsersList;
