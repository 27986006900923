/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Automation } from '../../redux/slices/automation.models';
import Sidebar from '../../components/sidebar/Sidebar';
import PageHeader from '../../components/headers/PageHeader';
import AutomationsList from './AutomationsList';
import Navbar from '../../components/navbar/Navbar';
import Spinner from '../../components/shared/Spinner';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { Group } from '../../redux/models/auth.models';
import { createAutomation } from '../../redux/slices/automations';

interface Props {
  isLoading: boolean;
  automations: Automation[];
  groups: Group[];
}

export default function AutomationContainer({ isLoading, automations, groups }: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const features = useFeatureFlags();
  const isEditorEnabled = features.automation_editor?.is_enabled || false;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCreateAutomation = async () => {
    try {
      setLoading(true);
      const response = await createAutomation('Untitled Automation');
      console.log(response.data.data);
      if (response.status === 200) {
        const automationId = response.data.data.entity_id;
        navigate(`/${orgCode}/automations/${automationId}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="min-h-full">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1">
          <PageHeader
            title="Automations"
            button={isEditorEnabled}
            buttonTitle="New"
            loading={loading}
            handleAction={handleCreateAutomation}
          />
          <div className="">
            {isLoading && (
              <div className="flex pt-32 w-full h-full items-center justify-center">
                <Spinner />
              </div>
            )}
            {!isLoading ? <AutomationsList automations={automations} groups={groups} /> : null}
          </div>
        </main>
      </div>
    </div>
  );
}
