import React from 'react';
import ListItem from './ListItem';
import { Group } from '../../../redux/models/auth.models';
import { Automation } from '../../../redux/slices/automation.models';

interface Props {
  automations: Automation[];
  groups: Group[];
}

export default function AutomationsList({ automations, groups }: Props) {
  const data = automations || [];
  return (
    <div className="overflow-hidden bg-white shadow">
      <ul className="divide-y divide-gray-200">
        {data.map((automation) => (
          <ListItem key={automation.entity_id} automation={automation} groups={groups} />
        ))}
      </ul>
    </div>
  );
}
