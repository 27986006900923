import React, { useState } from 'react';
import { PencilIcon } from '@heroicons/react/20/solid';
import OptionsMenu from './OptionsMenu';

interface Props {
  name: string;
  handleChange: (name: string) => void;
  handleDelete: () => void;
}

export default function AutomationSidebarHeader({ name, handleChange, handleDelete }: Props) {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div>
      <div className="mt-4 flex items-start justify-between">
        <div>
          {!isEditing && (
            <h2 className="text-lg font-medium text-gray-900">
              <span className="sr-only">Details for </span>
              {name}
            </h2>
          )}
          {isEditing && <input type="text" value={name} onChange={(e) => handleChange(e.target.value)} />}
        </div>
        {!isEditing && (
          <div className="flex">
            <button
              type="button"
              onClick={() => setIsEditing(true)}
              className="relative ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <span className="absolute -inset-1.5" />
              <PencilIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Edit</span>
            </button>
            <OptionsMenu handleDelete={handleDelete} />
          </div>
        )}
        {isEditing && (
          <button
            type="button"
            onClick={() => setIsEditing(false)}
            className="relative ml-4 flex p-2 items-center justify-center rounded-md bg-white text-blue-500 text-xs font-medium hover:bg-gray-100 hover:text-blue-800"
          >
            <span>Save</span>
          </button>
        )}
      </div>
    </div>
  );
}
