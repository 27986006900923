/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { create, all } from 'mathjs';

const config = {};
const math = create(all, config);

export const stopComplainingLinter = () => {
  console.log('great');
};

export const format = (value: any, unit: any, precision: number) => {
  if (unit === 'dollar') {
    const USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return USDollar.format(parseFloat(value));
  }
  if (unit === 'number') {
    const ans = math.unit(value, unit);
    return math.format(ans, { precision });
  }
  return `${value}`;
};
