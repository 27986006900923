/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityContainer } from './core.models';
import { Group, GroupDirectory } from '../models/auth.models';
import { Organisation } from '../models/database.model';

export const isAppPermission = (name: string) => {
  return name.includes(':automation:') || name.includes(':integration:') || name.includes(':user:');
};

export function createGroupContainer(data: Group[]): EntityContainer<Group> {
  return data.reduce((dir: EntityContainer<Group>, item: Group) => {
    return {
      ...dir,
      [item.id]: item,
    };
  }, {});
}

export const createGroupDirectory = (users: Group[]): GroupDirectory[] => {
  const container = users.reduce((dir: EntityContainer<Group[]>, group: Group) => {
    const name = group.name || '';
    const firstLetter = name.charAt(0);
    if (dir[firstLetter]) {
      return {
        ...dir,
        [firstLetter]: [...dir[firstLetter], group],
      };
    }
    return {
      ...dir,
      [firstLetter]: [group],
    };
  }, {});

  return Object.keys(container)
    .map((title: string) => ({
      letter: title,
      data: [...container[title]].sort((a: Group, b: Group) => {
        const aName = a.name || '';
        const bName = b.name || '';
        return aName.localeCompare(bName);
      }),
    }))
    .sort((a: any, b: any) => {
      return a.letter.localeCompare(b.letter);
    });
};

export const canCreateUsersAndGroups = (org: Organisation) => {
  return (
    org.data.permissions.find(
      (userPermission) =>
        userPermission.id === 'user' && userPermission.access_level === 'org' && userPermission.permission === 'write'
    ) !== undefined
  );
};

export class GroupStore {
  private cache: EntityContainer<Group[]> = {};

  add(orgCode: string, groups: Group[]) {
    this.cache[orgCode] = groups;
  }

  get(orgCode: string) {
    return this.cache[orgCode];
  }

  exists(orgCode: string) {
    return this.cache[orgCode] !== undefined && this.cache[orgCode] !== null;
  }
}
