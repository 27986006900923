/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import { Automation } from './automation.models';
import { AUTOMATIONS } from './slices';
import { AppState } from '../models/app.models';
import { AutomationState } from '../models/automation.models';
import AutomationApiProvider from '../../services/AutomationApiProvider';
import { EntityContainer } from './core.models';

const SLICE_NAME = AUTOMATIONS;

const initialState: AutomationState = {
  container: {},
  isLoaded: false,
  isLoading: false,
  isError: false,
  list: [],
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAutomations(state, action) {
      state.container = action.payload;
      const items: Array<Automation> = Object.values(action.payload);
      items.sort((a: any, b: any) => {
        const aName = a?.data?.name || '';
        const bName = b?.data?.name || '';
        return aName.localeCompare(bName);
      });
      state.list = items;
    },
    setIsAutomationsLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    setIsAutomationsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.isError = action.payload;
    },
  },
});

export const { setAutomations, setIsAutomationsLoaded, setErrorOnLoadingData, setIsAutomationsLoading } = slice.actions;

export const createAutomation = (name: string) => {
  return AutomationApiProvider.create(name);
};

export const loadAutomations: any = (organisationId: string, isEditor: boolean) => async (dispatch: any) => {
  dispatch(setIsAutomationsLoading(true));
  dispatch(setErrorOnLoadingData(false));
  try {
    const { data } = await HopperServiceApiProvider.getAutomationData(organisationId, isEditor);
    const automations = data?.data || [];
    const dataContainer = automations.reduce((store: EntityContainer<Automation>, automation: Automation) => {
      return {
        ...store,
        [automation.entity_id]: automation,
      };
    }, {});
    dispatch(setAutomations(dataContainer));
    dispatch(setIsAutomationsLoaded(true));
  } catch (error) {
    dispatch(setErrorOnLoadingData(true));
    if (error instanceof Error) {
      const errorMessage = `Couldn't get Automations. ${error.message}. Please contact support if the problem persists.`;
      throw new Error(errorMessage);
    }
  } finally {
    dispatch(setIsAutomationsLoading(false));
  }
};

const automationsReducer = slice.reducer;
export default automationsReducer;
export const selectAutomationsList = (state: AppState) => state[SLICE_NAME].list;
export const isAutomationsLoaded = (state: AppState) => state[SLICE_NAME].isLoaded;
export const isAutomationsLoading = (state: AppState) => state[SLICE_NAME].isLoading;
export const errorOnLoadingData = (state: AppState) => state[SLICE_NAME].isError;
