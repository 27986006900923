/* eslint-disable import/no-cycle */
/* eslint-disable */
import React from 'react';
import { FormField as FormFieldModel } from './form.models';
import FormField from './FormField';
import { TextFieldToken } from './FormFields/FormTextInput.utils';

interface Props {
  form: FormFieldModel;
  tokens: TextFieldToken[];
  onFormChange: (form: FormFieldModel) => void;
}

// const isVisible = (form: FormModel, field: FormFieldModel) => {
//   const dependencies = field.dependencies || [];
//   return dependencies.some((dep) => {
//     const matchingField = form.fields.find((fld) => fld.prop === dep.field_name);
//     if (matchingField) {
//       return dep.values.some((depVal) => isEqual(getFieldValue(matchingField), depVal));
//     }
//     return false;
//   });
// };

export default function Form({ form, tokens, onFormChange }: Props) {
  console.log('render Form');
  console.log(form);
  const fields = form.children_fields || [];
  const visible = fields.filter((field) => field.visible);
  const handleChange = (field: FormFieldModel, idx: number) => {
    const newForms = {
      ...form,
      children_fields: fields.map((fld, fldIdx) => {
        if (fldIdx === idx) {
          return field;
        }
        return fld;
      }),
    };
    onFormChange(newForms);
  };
  return (
    <div>
      {visible.map((field, idx) => (
        <FormField
          key={`${field.name}-${field.prop}-${field.prop}`}
          idx={idx}
          tokens={tokens}
          field={field}
          onChange={handleChange}
        />
      ))}
    </div>
  );
}
