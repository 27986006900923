export const AUTH = 'auth';
export const AUTOMATIONS = 'automations';
export const AUTOMATION_DETAILS = 'automationDetails';
export const COLLECTIONS = 'collections';
export const DATABASE = 'database';
export const GROUPS = 'groups';
export const INTEGRATIONS = 'integrations';
export const ORGANISATION = 'organisation';
export const REPORTS = 'reports';
export const USERS = 'users';
