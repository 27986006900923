/* eslint-disable react/require-default-props */
import React from 'react';

interface Props<T> {
  title: string;
  details: string;
  imgUrl?: string;
  data: T;
  setSelected: React.Dispatch<React.SetStateAction<T | null>>;
}

export default function DirectoryListItem<T>({ title, details, imgUrl, setSelected, data }: Props<T>) {
  return (
    <div className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500 hover:bg-gray-50">
      {imgUrl ? (
        <div className="flex-shrink-0">
          <img className="h-10 w-10 rounded-full" src={imgUrl || ''} alt={title || ''} />
        </div>
      ) : null}
      <div className="min-w-0 flex-1">
        <button type="button" onClick={() => setSelected(data)} className="focus:outline-none">
          {/* Extend touch target to entire panel */}
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 text-left">{title || ''}</p>
          <p className="truncate text-sm text-gray-500 text-left">{details || ''}</p>
        </button>
      </div>
    </div>
  );
}
