/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
// import useAuth from '../hooks/useAuth';
import Sidebar from '../../components/sidebar/Sidebar';
import { initialPageLoad } from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';
import Navbar from '../../components/navbar/Navbar';
import IntegrationList from './IntegrationsList';

import { getLocalOrganisation } from '../../redux/slices/organisation';
import PageHeader from '../../components/headers/PageHeader';
import { isIntegrationsLoaded, loadIntegrations, selectIntegrationsList } from '../../redux/slices/integrations';
import { getValueFromSessionStorage } from '../../utils/core.utils';
import { ALLCOLLECTIONS, SIDEBAR } from '../../constants/core.contants';

function IntegrationsList() {
  const areIntegrationsLoaded = useSelector(isIntegrationsLoaded);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { entity_id: entityId } = getLocalOrganisation();
  const integrations = useSelector(selectIntegrationsList);
  const dispatch: any = useAppDispatch();

  const sidebarItem = getValueFromSessionStorage(SIDEBAR);
  const collections = getValueFromSessionStorage(ALLCOLLECTIONS);

  useEffect(() => {
    if (!collections || !sidebarItem) {
      dispatch(initialPageLoad());
    }
  }, [collections, sidebarItem, dispatch]);

  useEffect(() => {
    if (entityId) {
      dispatch(loadIntegrations(entityId));
    }
  }, [dispatch, entityId]);

  return (
    <div className="min-h-full">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1">
          <PageHeader title="Integrations" />
          <div className="p-8">{areIntegrationsLoaded ? <IntegrationList integrations={integrations} /> : null}</div>
        </main>
      </div>
    </div>
  );
}

export default IntegrationsList;
