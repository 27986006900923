/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Transition, Switch, Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  Squares2X2Icon,
  QueueListIcon,
  ViewColumnsIcon,
  Cog6ToothIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import isEqual from 'lodash/isEqual';
import classNames from '../../utils/tailwind';
import FormErrorMessage from '../../tables/Form/FormErrorMessage';
import { Filter } from '../../tables/Header/FilterButton.modals';
import { DropdownOptionsProps } from './Dropdown.modal';
import { Group } from '../../redux/models/auth.models';
import { getLocalOrganisation } from '../../redux/slices/organisation';
import useAppDispatch from '../../hooks/useAppDispatch';
import { loadGroups, selectGroupsList, selectIsGroupsLoaded, selectIsGroupsLoading } from '../../redux/slices/groups';
import Spinner from './Spinner';
import { COLLECTION_VIEW_TYPE, CollectionElement, CollectionElementOption } from '../../redux/models/database.model';
import { selectCollection, updateCollectionElements } from '../../redux/slices/database';

interface DropdownSelectProps {
  dropdownOptions: string[];
  setSelected: any;
  idx: number;
  placeholder: string;
  name: string;
  errors: string;
  typeSelector: boolean;
  setValue: React.Dispatch<React.SetStateAction<string | null>>;
  columns: any;
  displayName?: string | null;
  dataCy?: string;
}

export function DropdownSelect({
  dropdownOptions,
  setSelected,
  placeholder,
  idx,
  name,
  errors,
  typeSelector = false,
  setValue,
  columns,
  displayName = null,
  dataCy = '',
}: DropdownSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const handleClick = (value: string, e: React.MouseEvent) => {
    e.preventDefault();
    if (typeSelector) {
      setValue(value);
    }
    setDisplayValue(value);
    if (name === 'field') {
      const el = columns.find((ele: { name: string }) => ele.name === value);
      setSelected((prev: any) => ({
        ...prev,
        filters: prev.filters.map((fl: Filter, filterIdx: number) => {
          if (filterIdx === idx) {
            const prop = el.type === 'rel' ? `data.${el.rel_filter_prop}` : `data.${el.property}`;
            return {
              op: null,
              value: null,
              field: {
                id: el.name,
                name: prop,
                type: el.type,
              },
            };
          }
          return fl;
        }),
      }));
    } else if (name === 'operator') {
      setSelected((prev: any) => {
        return { ...prev, operator: value };
      });
    } else if (name === 'op') {
      setSelected((prev: any) => {
        const newArr = [...prev.filters];
        newArr[idx].op = value;
        return {
          ...prev,
          filters: newArr,
        };
      });
    } else if (name === 'value') {
      setSelected((prev: any) => ({
        ...prev,
        filters: [...prev.filters.slice(0, idx), { ...prev.filters[idx], value }, ...prev.filters.slice(idx + 1)],
      }));
    }
    setIsOpen(false);
  };
  console.log(`data-cy: ${dataCy}`);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          onClick={() => setIsOpen(!isOpen)}
          data-cy={dataCy}
          className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          {displayName || displayValue || placeholder}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {dropdownOptions &&
              dropdownOptions.map((ele, idxInner) => {
                return (
                  <Menu.Item key={name + idxInner + idx}>
                    {({ active }) => (
                      <a
                        onClick={(e) => handleClick(ele, e)}
                        data-cy={`${dataCy}-${ele}`}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full text-left'
                        )}
                      >
                        {ele}
                      </a>
                    )}
                  </Menu.Item>
                );
              })}
          </div>
        </Menu.Items>
      </Transition>
      {errors && <FormErrorMessage error={errors} />}
    </Menu>
  );
}

interface CollectionElementDropdownSelectProps {
  dropdownOptions: CollectionElementOption[];
  valueId: string;
  setSelected: any;
  idx: number;
  placeholder: string;
  errors: string;
  displayName?: string | null;
  dataCy?: string;
}

export function CollectionElementDropdownSelect({
  dropdownOptions,
  setSelected,
  valueId,
  placeholder,
  idx,
  errors,
  dataCy = '',
  displayName = null,
}: CollectionElementDropdownSelectProps) {
  const selectedName = dropdownOptions.find((option) => option.id === valueId)?.name || '';
  const [isOpen, setIsOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState(selectedName);
  const handleClick = (value: CollectionElementOption, e: React.MouseEvent) => {
    e.preventDefault();
    setDisplayValue(value.name);
    const { id } = value;
    setSelected((prev: any) => {
      return {
        ...prev,
        filters: [...prev.filters.slice(0, idx), { ...prev.filters[idx], value: id }, ...prev.filters.slice(idx + 1)],
      };
    });
    setIsOpen(false);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          onClick={() => setIsOpen(!isOpen)}
          data-cy={`${dataCy}_select_btn`}
          className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          {displayName || displayValue || placeholder}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {dropdownOptions &&
              dropdownOptions.map((ele) => {
                return (
                  <Menu.Item key={ele.id}>
                    {({ active }) => (
                      <a
                        onClick={(e) => handleClick(ele, e)}
                        data-cy={`${dataCy}-${ele.name}`}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full text-left'
                        )}
                      >
                        {ele.name}
                      </a>
                    )}
                  </Menu.Item>
                );
              })}
          </div>
        </Menu.Items>
      </Transition>
      {errors && <FormErrorMessage error={errors} />}
    </Menu>
  );
}

export function PermissionsDropdown({
  dataCy,
  setPermissionSelected,
  permissionSelected,
}: {
  dataCy: string;
  setPermissionSelected: (group: Group) => void;
  permissionSelected: Group | null;
}) {
  const dispatch: any = useAppDispatch();
  const org = getLocalOrganisation();
  const orgCode = org.data.organization_code;
  const groups = useSelector(selectGroupsList);
  const isLoaded = useSelector(selectIsGroupsLoaded);
  const isLoading = useSelector(selectIsGroupsLoading);
  const [isOpen, setIsOpen] = useState(false);
  const placeholder = 'Permissions';

  const close = () => {
    setIsOpen(false);
  };
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, ele: DropdownOptionsProps) => {
    e.preventDefault();
    setPermissionSelected(ele);
    close();
  };

  useEffect(() => {
    const groupData = groups || [];
    const isEmpty = groupData.length === 0;
    if (orgCode && !isLoaded && !isLoading && isEmpty) {
      dispatch(loadGroups(orgCode));
    }
  }, [dispatch, orgCode, isLoaded, isLoading, groups]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          data-cy={`${dataCy}-permissions-toggle_btn`}
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          {permissionSelected ? permissionSelected?.name : placeholder}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {isLoaded ? (
              groups.map((ele: Group) => (
                <Menu.Item key={ele.id}>
                  {({ active }) => (
                    <a
                      href="#"
                      data-cy={`${dataCy}-permissions-${ele.name || ''}`}
                      onClick={(e) => handleClick(e, ele)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {ele.name || ''}
                    </a>
                  )}
                </Menu.Item>
              ))
            ) : (
              <Spinner size="small" />
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

interface PaginationDropdownProps {
  filterKey: string;
  openFilter: string | null;
  pageSize: number;
  selectPageSize: (pageSize: number) => void;
  setOpenFilter: (filter: string | null) => void;
}

export function PaginationDropdown({
  pageSize,
  filterKey,
  openFilter,
  setOpenFilter,
  selectPageSize,
}: PaginationDropdownProps) {
  const sizes = [50, 100, 150, 200];
  const isOpen = filterKey === openFilter;

  const handleOpen = () => {
    setOpenFilter(isOpen ? null : filterKey);
  };
  const close = () => {
    setOpenFilter(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, ele: number) => {
    e.preventDefault();
    selectPageSize(ele);
    close();
  };

  return (
    <Menu as="div" className="relative inline-block text-left pt-1">
      <div>
        <Menu.Button
          onClick={() => handleOpen()}
          data-cy="collection_header_pagination_menu-toggle_btn"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs text-gray-900 hover:bg-gray-50"
        >
          {`Showing ${pageSize}`}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {sizes.map((size: number) => (
              <Menu.Item key={size}>
                {({ active }) => (
                  <a
                    href="#"
                    onClick={(e) => handleClick(e, size)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    {`Show ${size} results`}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

interface CollectionViewDropdownProps {
  isCalendarEnabled: boolean;
  isGridEnabled: boolean;
  isBoardEnabled: boolean;
  view: string;
  filterKey: string;
  openFilter: string | null;
  setOpenFilter: (filter: string | null) => void;
  selectView: (view: string) => void;
}

const COLLECTION_VIEW_ICONS = {
  [COLLECTION_VIEW_TYPE.CALENDAR]: <CalendarIcon className="h-4 w-4" aria-hidden="true" />,
  [COLLECTION_VIEW_TYPE.BOARD]: <ViewColumnsIcon className="h-4 w-4" aria-hidden="true" />,
  [COLLECTION_VIEW_TYPE.GRID]: <Squares2X2Icon className="h-4 w-4" aria-hidden="true" />,
  [COLLECTION_VIEW_TYPE.TABLE]: <QueueListIcon className="h-4 w-4" aria-hidden="true" />,
};

export function CollectionViewDropdown({
  isCalendarEnabled,
  isBoardEnabled,
  isGridEnabled,
  view,
  filterKey,
  openFilter,
  setOpenFilter,
  selectView,
}: CollectionViewDropdownProps) {
  const namerise = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1).toLocaleLowerCase()}`;
  const isOpen = filterKey === openFilter;

  const handleOpen = () => {
    setOpenFilter(isOpen ? null : filterKey);
  };

  const options = [];
  if (isCalendarEnabled) options.push(COLLECTION_VIEW_TYPE.CALENDAR);
  if (isBoardEnabled) options.push(COLLECTION_VIEW_TYPE.BOARD);
  if (isGridEnabled) options.push(COLLECTION_VIEW_TYPE.GRID);
  options.push(COLLECTION_VIEW_TYPE.TABLE);
  const close = () => {
    setOpenFilter(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, ele: string) => {
    e.preventDefault();
    selectView(ele);
    close();
  };

  return (
    <Menu as="div" className="relative inline-block text-left pt-1">
      <div>
        <Menu.Button
          onClick={handleOpen}
          data-cy="collection_header_view_type_menu-toggle_btn"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs text-gray-900 hover:bg-gray-50"
        >
          <div className="flex items-center">
            {COLLECTION_VIEW_ICONS[view]}
            <span className="ml-1">{namerise(view)}</span>
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </Menu.Button>
      </div>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option: string) => (
              <Menu.Item key={option}>
                {({ active }) => (
                  <a
                    href="#"
                    onClick={(e) => handleClick(e, option)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    <div className="flex items-center">
                      {COLLECTION_VIEW_ICONS[option]}
                      <span className="ml-1">{namerise(option)}</span>
                    </div>
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

interface ToggleProps {
  element: CollectionElement;
  isOn: boolean;
  onToggle: (element: CollectionElement, visibile: boolean) => void;
}

export default function Toggle({ element, isOn, onToggle }: ToggleProps) {
  const [enabled, setEnabled] = useState(isOn);
  const handleEnabled = (isEnabled: boolean) => {
    setEnabled(isEnabled);
    onToggle(element, isEnabled);
  };
  return (
    <Switch.Group as="div" className="flex items-center justify-between mx-4 my-2">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-xs font-medium leading-6 text-gray-900" passive>
          {element.name}
        </Switch.Label>
      </span>
      <Switch
        checked={enabled}
        onChange={handleEnabled}
        className={classNames(
          enabled ? 'bg-blue-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  );
}

interface ConfigureViewDropdownProps {
  filterKey: string;
  openFilter: string | null;
  setOpenFilter: (filter: string | null) => void;
}

const getElementId = (el: CollectionElement) => {
  return `${el.name}-${el.position}-${el.property}-${el.type}`;
};

export function ConfigureViewDropdown({ filterKey, openFilter, setOpenFilter }: ConfigureViewDropdownProps) {
  const dispatch = useAppDispatch();
  const collection = useSelector(selectCollection);
  const initElements = collection?.elements || [];
  const [elements, setElements] = useState(initElements);
  const isOpen = filterKey === openFilter;

  const handleOpen = () => {
    setOpenFilter(isOpen ? null : filterKey);
  };

  const handleToggle = (element: CollectionElement, visibile: boolean) => {
    const els = elements.map((el) => {
      if (isEqual(el, element)) {
        return {
          ...el,
          visible: visibile,
        };
      }
      return el;
    });
    setElements(els);
  };

  const handleSave = () => {
    if (!collection) return;
    dispatch(updateCollectionElements(elements, collection));
  };
  useEffect(() => {
    const initNames = initElements.map((el) => getElementId(el));
    const elNames = elements.map((el) => getElementId(el));
    if (!isEqual(initNames, elNames)) setElements(initElements);
  }, [initElements, elements]);
  return (
    <Popover className="relative z-50">
      {() => (
        <>
          <Popover.Button
            onClick={handleOpen}
            data-cy="collection_header_configure_view_menu-toggle_btn"
            className="bg-white hover:bg-gray-100 focus:bg-gray-100 group inline-flex h-full items-center rounded-md text-xs hover:text-gray-900 rounded px-2 py-1 mx-3 cursor-pointer"
          >
            <div className="flex items-center">
              <Cog6ToothIcon className="h-4 w-4" aria-hidden="true" />
              <span className="ml-1">Configure</span>
              <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
              {() => (
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white p-4">
                    <div className="">
                      {elements.map((element, elIdx) => (
                        <Toggle
                          key={`config-toggle-${element.name}-${elIdx}`}
                          element={element}
                          isOn={element.visible || false}
                          onToggle={handleToggle}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="bg-gray-50 p-4">
                    <button
                      type="button"
                      onClick={handleSave}
                      className="block rounded-md p-3 w-full transition duration-150 ease-in-out hover:bg-gray-100"
                    >
                      <p className="text-xs font-medium text-blue-600">Save</p>
                    </button>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
