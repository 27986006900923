/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { getValueFromSessionStorage } from '../../../../utils/core.utils';
import { ENTITYURL } from '../../../../constants/core.contants';
import Spinner from '../../../../components/shared/Spinner';
import Uploader from '../../../Modal/Uploader';
import { Queue } from '../MultiAttachment/attachment.utils';
import AttachmentRowItem from '../MultiAttachment/AttachmentRowItem';

interface Props {
  queueId: string;
  width?: string;
  minWidth?: string;
  file: any;
  setFormikValue: any;
  formikProperty: string;
  submitFormik: () => void;
  isForm: boolean;
  editable: boolean;
  isModal: boolean;
}

function TableAttachmentRow({
  queueId,
  width,
  minWidth,
  file,
  setFormikValue,
  formikProperty,
  submitFormik,
  isForm = false,
  editable,
  isModal = false,
}: Props) {
  const [filePath, setFilePath] = useState(null);
  const [fileLoaded, setFileLoaded] = useState(false);
  const postURL = getValueFromSessionStorage(ENTITYURL);

  const handleClick = () => {
    if (filePath) {
      window.open(filePath, '_blank');
    }
  };

  const handleFileUpload = async () => {
    const files = Queue.files(queueId);
    if (files.length > 0) {
      const uploadedFile = files[0];
      setFormikValue(formikProperty, uploadedFile);
    }
    if (!isForm) {
      submitFormik();
    }
  };

  const handleXClick = () => {
    const confirmResult = window.confirm('Are you sure you want to delete the file?');
    if (confirmResult) {
      setFormikValue(formikProperty, {});
      setFileLoaded(false);
      setFilePath(null);
      if (!isForm) {
        submitFormik();
      }
    }
  };

  return (
    <div style={{ minWidth, width }}>
      {Object.keys(file).length !== 0 ? (
        <div className="table-inner-cell hover:cursor-pointer flex align-middle">
          <div className="flex flex-wrap items-start">
            <AttachmentRowItem
              key={`singlattachment-row-${0}`}
              file={file}
              idx={0}
              editable={editable}
              handleOpen={handleClick}
              handleDelete={handleXClick}
            />
          </div>
        </div>
      ) : (
        !isForm &&
        (Object.keys(file).length === 0 ? (
          <div className="table-cell-row relative flex align-middle text-gray-600 text-xs">
            {' '}
            <p>No files</p>{' '}
          </div>
        ) : (
          !fileLoaded && <Spinner size="small" />
        ))
      )}
      {(isModal || isForm) && editable && (
        <div className="mt-6 w-full">
          <Uploader
            queueId={queueId}
            uploadUrl={postURL}
            handleFileUpload={handleFileUpload}
            isModal={isModal}
            isMulti
          />
        </div>
      )}
    </div>
  );
}

TableAttachmentRow.defaultProps = {
  width: '100%',
  minWidth: '100%',
};

export default TableAttachmentRow;
