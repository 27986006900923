import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCollectionUrl, selectDisplayTableData, selectRequest, setNextPage } from '../../redux/slices/database';
import { notify } from '../../components/shared/Notification';
import Spinner from '../../components/shared/Spinner';
import useAppDispatch from '../../hooks/useAppDispatch';
import EntityApiProvider from '../../services/EntityApiProvider';

export default function LoadMoreButton() {
  const dispatch = useAppDispatch();
  const url = useSelector(selectCollectionUrl);
  const request = useSelector(selectRequest);
  const tableData = useSelector(selectDisplayTableData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInFlight, setIsInFlight] = useState<boolean>(false);
  const count = tableData.rows.length || 0;
  const totalRecords = tableData.count || 0;
  const pageSize = request.pagination.page_size;
  const nextCount = count + pageSize;
  const max = totalRecords <= nextCount ? totalRecords : nextCount;
  const btnTitle = `Load ${count} - ${max}`;
  const handleLoadMore = async () => {
    if (isInFlight) {
      return;
    }
    const nextPage = request.pagination.page + 1;
    const filters = {
      ...request,
      pagination: {
        ...request.pagination,
        page: nextPage,
      },
    };
    try {
      await setIsInFlight(true);
      setIsLoading(true);
      const response = await EntityApiProvider.getEntityData(url, filters);
      const data = EntityApiProvider.parseResponse(response);
      const totalCount = data.total_count || 0;
      dispatch(setNextPage({ data, filters, totalCount }));
    } catch (error) {
      notify("Couldn't load next page", '', 'error');
    } finally {
      setIsLoading(false);
      setIsInFlight(false);
    }
  };

  return (
    <div className="min-w-fit relative pl-10 mt-12 mb-24">
      <button
        type="button"
        onClick={handleLoadMore}
        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        {btnTitle}
        {isLoading ? <Spinner size="small" /> : null}
      </button>
    </div>
  );
}
