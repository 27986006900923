/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';

import ProfileRelsCard from '../shared/ProfileRelsCard';
import { EntityContainer } from '../../../redux/slices/core.models';
import DirectoryItemSearch from '../directory/DirectoryItemSearch';
import Spinner from '../../../components/shared/Spinner';
import DirectoryItemSearchResult from '../directory/DirectoryItemSearchResult';

interface Props<T> {
  title: string;
  data: T[];
  store: EntityContainer<T>;
  inFlight: EntityContainer<boolean>;
  isImage: boolean;
  canEdit: boolean;
  getId: (item: T) => string;
  getName: (item: T) => string;
  getDetails: (item: T) => string;
  getImg: (item: T) => string;
  onAdd: (item: T) => void;
  onRemove: (item: T) => void;
}
export default function GroupUsers<T>({
  title,
  data,
  store,
  inFlight,
  isImage,
  canEdit,
  getName,
  getId,
  getImg,
  getDetails,
  onAdd,
  onRemove,
}: Props<T>) {
  const [selectedItem, setSelectedItem] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleAddItem = async () => {
    if (selectedItem && canEdit) {
      setIsLoading(true);
      await onAdd(selectedItem);
      setIsLoading(false);
      setSelectedItem(null);
    }
  };
  return (
    <div className="space-y-2 mx-auto mt-12 max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
      {canEdit && (
        <div className="space-y-2">
          <label htmlFor="add-team-members" className="text-sm font-medium text-gray-500">
            {title}
          </label>
          <p id="add-team-members-helper" className="sr-only">
            Search by name
          </p>
          <div className="flex items-center">
            <div className="flex-grow">
              <DirectoryItemSearch
                selectedItem={selectedItem}
                data={Object.values(store)}
                placeholder="Search by name"
                nameExtractor={getName}
                keyExtractor={getId}
                setSeleced={setSelectedItem}
                renderListItem={(item, selected) => (
                  <DirectoryItemSearchResult
                    name={getName(item)}
                    isImage={isImage}
                    imgUrl={getImg(item)}
                    selected={selected}
                  />
                )}
              />
            </div>
            <span className="ml-3 pt-2">
              <button
                type="button"
                onClick={handleAddItem}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {isLoading ? (
                  <Spinner size="small" />
                ) : (
                  <PlusIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                )}
                Add
              </button>
            </span>
          </div>
        </div>
      )}

      <div className="">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-8">
          {data.map((item) => (
            <ProfileRelsCard
              key={getId(item)}
              name={getName(item)}
              details={getDetails(item)}
              imgUrl={getImg(item)}
              data={item}
              isLoading={inFlight[getId(item)] || false}
              canEdit={canEdit}
              onDelete={onRemove}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
