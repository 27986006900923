/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import classNames from '../../utils/tailwind';

interface Props {
  searchText: string;
  branding: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

export default function SidebarSearch({ searchText, branding, setSearch }: Props) {
  return (
    <div className="mt-5 px-3">
      <label htmlFor="search" className="sr-only">
        Search databases
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" aria-hidden="true">
          <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          value={searchText}
          onChange={(e) => setSearch(e.target.value)}
          className={classNames(branding, 'block w-full rounded-md border-0 py-1.5 pl-9 sm:text-sm sm:leading-6')}
          placeholder="Search Databases"
        />
      </div>
    </div>
  );
}
