import { EntityContainer } from '../../redux/slices/core.models';

export type SidebarItems = {
  id: undefined | string;
  title: string;
  baseURL: string;
  path: string;
  resourceId: string;
};

export type SidebarProps = {
  areItemsLoading: boolean;
};

export interface SidebarBranding {
  bg: string;
  inactive: string;
  active: string;
  layout: string;
  primaryText: string;
  secondaryText: string;
  searchBg: string;
  profileBg: string;
}

export const BRANDING: EntityContainer<SidebarBranding> = {
  apex: {
    layout: 'large',
    bg: 'lg:bg-sky-900',
    inactive: 'text-sky-100 hover:bg-sky-700 hover:text-sky-100',
    active: 'bg-sky-100 text-sky-900',
    primaryText: 'text-sky-100',
    secondaryText: 'text-gray-400',
    searchBg: 'bg-sky-800 placeholder:text-gray-400 text-white focus:ring-sky-700',
    profileBg:
      'hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100',
  },
  hopper: {
    layout: 'small',
    bg: 'lg:bg-gray-100',
    inactive: 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
    active: 'bg-gray-200 text-gray-900',
    primaryText: 'text-gray-900',
    secondaryText: 'text-gray-900',
    searchBg:
      'ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600',
    profileBg:
      'hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100',
  },
};
