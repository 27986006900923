/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */

import { CollectionElement } from '../../redux/models/database.model';

export const DEFAULT_WIDTH_PX = 150;

const getCellWidthVal = (element: CollectionElement | null) => (element?.width ? element.width : DEFAULT_WIDTH_PX);

export const getTableWidth = (elements: CollectionElement[]) => {
  const bodyWidth = elements
    .map((element) => getCellWidthVal(element))
    .reduce((totalWidth, colWidth) => totalWidth + colWidth, 0);
  const screenWidth = innerWidth - 400;
  return screenWidth > bodyWidth ? screenWidth : bodyWidth;
};

export const getCellWidth = (element: CollectionElement | null) => {
  const width = element?.width ? `${element.width}px` : `${DEFAULT_WIDTH_PX}px`;
  return {
    minWidth: `${width}`,
    width,
  };
};
