/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { useState, Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from '../../../../utils/tailwind';
import { getLabelColour } from '../../colours';
import { CollectionElementOption } from '../../../../redux/models/database.model';
import SelectLabel from './SelectLabel';

const getOption = (id: string, options: CollectionElementOption[]) => {
  const option = options.find((op) => op.id === id);
  return option;
};

interface Props {
  dropdownOptions: any;
  width?: string;
  minWidth?: string;
  setFormikValue: any;
  submitFormik: any;
  formikProperty: any;
  options?: CollectionElementOption[];
  editable: boolean;
  single?: boolean;
}

function TableDropdownCell({
  dropdownOptions,
  width,
  minWidth,
  setFormikValue,
  submitFormik,
  formikProperty,
  options,
  editable,
  single = false,
}: Props) {
  const [isInput, setIsInput] = useState(false);
  const optionCheck = options || [];
  const remainingOptions = optionCheck;
  const dropdownHeight = remainingOptions.length * 35;
  const handleClick = () => {
    setIsInput(true);
  };

  const handleSelect = (e: any) => {
    if (editable) {
      const selectedName = e.target.textContent;
      const option = optionCheck.find((op) => op.name === selectedName);
      if (option) {
        const optionId = option.id || '';
        const handleMultiSelect = (optId: string) => {
          const ops = dropdownOptions || [];
          if (ops.includes(optId)) {
            return ops.filter((opt: string) => opt !== optionId);
          }
          return [...ops, optionId];
        };
        const newArr = single ? optionId : handleMultiSelect(optionId);
        setFormikValue(formikProperty, newArr);
        submitFormik();
        if (single) setIsInput(false);
      }
    }
  };

  return (
    <div className="table-cell-row relative" style={{ minWidth, width }}>
      <div className={`table-inner-cell flex-col justify-center items-start h-full ${isInput ? 'w-full' : ''}`}>
        <div
          onClick={handleClick}
          className={`w-full  ${isInput ? '' : 'min-h-[20px] '} ${
            isInput ? '' : 'hover:border-2 hover:border-cell-border'
          }`}
        >
          {dropdownOptions && dropdownOptions.length !== 0 && (
            <div className="flex">
              {!single ? (
                dropdownOptions.map((item: string, itemIdx: number) => (
                  <SelectLabel key={`options-${item}-${itemIdx}`} option={getOption(item, optionCheck)} />
                ))
              ) : (
                <SelectLabel option={getOption(dropdownOptions, optionCheck)} />
              )}
            </div>
          )}
        </div>
        {isInput && (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-1 focus:ring-offset-2 focus:ring-offset-gray-100 max-w-[200px]">
                {single ? <SelectLabel option={dropdownOptions} /> : 'Options'}
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                style={{ height: `${dropdownHeight}px` }}
                className="absolute left-0 z-20 mt-2 w-40 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1 flex flex-col justify-around items-start h-full px-2 overflow-x-scroll">
                  {remainingOptions.length >= 1 ? (
                    remainingOptions.map((ele, elIdx) => {
                      return (
                        <Menu.Item key={`remaining-options-${ele.id}-${elIdx}`}>
                          {({ active }: any) => {
                            const labelColour = getLabelColour(ele.color);
                            return (
                              <button
                                type="button"
                                onClick={handleSelect}
                                className={classNames(
                                  active ? labelColour : labelColour,
                                  'items-baseline rounded-full overscroll-x-scroll whitespace-nowrap px-2.5 py-0.5 text-xs font-medium md:mt-2 lg:mt-0'
                                )}
                              >
                                {ele.name}
                              </button>
                            );
                          }}
                        </Menu.Item>
                      );
                    })
                  ) : (
                    <Menu.Item>
                      {({ active }: any) => (
                        <button
                          onClick={() => setIsInput(false)}
                          type="button"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900 ' : ' text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left opacity-40 cursor-default'
                          )}
                        >
                          No items
                        </button>
                      )}
                    </Menu.Item>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
    </div>
  );
}

TableDropdownCell.defaultProps = {
  single: false,
  width: '100%',
  minWidth: '100%',
  options: [],
};

export default TableDropdownCell;
