/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { CollectionTextInput, CollectionCheckboxInput } from './CollectionInputs';
import { CollectionData } from '../../../../redux/models/database.model';

interface Props {
  collection: CollectionData;
  handleAdd: () => void;
  handleChange: (val: string, prop: string) => void;
}
export default function CollectionInfo({ collection, handleChange, handleAdd }: Props) {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">Collection Details</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">Top level collection info</p>
        <button
          type="button"
          onClick={handleAdd}
          className="rounded-md bg-white mt-2 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add Element
        </button>
      </div>

      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <CollectionTextInput name="Name" type="text" prop="name" value={collection.name} handleChange={handleChange} />
        <CollectionTextInput name="Path" type="text" prop="path" value={collection.path} handleChange={handleChange} />
        <CollectionTextInput
          name="Resource Id"
          type="text"
          prop="resource_id"
          value={collection.resource_id}
          handleChange={handleChange}
        />
        <CollectionCheckboxInput name="Visible" />
      </div>
    </div>
  );
}
