/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';

export default function FormButton({ setShowForm, showForm }: any) {
  return (
    <div className="fixed bottom-[100px] left-[270px] border-none z-20">
      <div className="relative flex flex-auto">
        <button
          type="button"
          data-cy="add_entity_modal-toggle_btn"
          className={`rounded-full  p-1 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${
            showForm ? 'bg-gray-100 hover:bg-gray-400' : 'bg-blue-600 focus-visible:outline-blue-600 hover:bg-blue-500'
          }`}
          onClick={() => setShowForm(!showForm)}
        >
          <PlusIcon
            className={`h-5 w-5 transition-all  ${showForm ? 'rotate-45 text-blue-500' : ''}`}
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
}
