/* eslint-disable import/no-cycle */
import { EntityContainer } from '../slices/core.models';
import { Automation, AutomationStep } from '../slices/automation.models';

export type StepContainer = EntityContainer<AutomationStep>;

export type AutomationDetailsState = {
  isLoaded: boolean;
  isLoading: boolean;
  errorOnLoad: boolean;
  automation: Automation | null;
  steps: StepContainer;
  selectedStepId?: string;
};

export const initialAutomationDetailsState: AutomationDetailsState = {
  isLoaded: false,
  isLoading: false,
  errorOnLoad: false,
  automation: null,
  steps: {},
  selectedStepId: '',
};
