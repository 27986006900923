import React from 'react';
import { User, UserDirectory } from '../../../redux/models/auth.models';
import { getSearchedUsers } from './directory.utils';
import DirectoryList from './DirectoryList';
import DirectoryListItem from './DirectoryListItem';

interface Props {
  directory: UserDirectory[];
  searchText: string;
  isLoaded: boolean;
  setSelected: React.Dispatch<React.SetStateAction<User | null>>;
}

export default function UserDirectoryList({ directory, searchText, isLoaded, setSelected }: Props) {
  if (!isLoaded) {
    return null;
  }
  return (
    <DirectoryList
      directory={directory}
      searchText={searchText}
      searchResults={getSearchedUsers}
      keyExtractor={({ entity_id: userId }) => userId}
      renderListItem={(user) => (
        <DirectoryListItem
          title={user.data.name}
          details={user.data.email}
          imgUrl={user.data.img_url}
          setSelected={setSelected}
          data={user}
        />
      )}
    />
  );
}
