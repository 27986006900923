import { EntityContainer } from './core.models';

export interface ElementType {
  type: string;
  name: string;
  isGroup: boolean;
  isEmbedded: boolean;
  isFooter: boolean;
  isSort: boolean;
  isFilter: boolean;
}

export const ELEMENT_TYPE_ID = {
  bool: 'bool',
  datetime: 'datetime',
  file: 'file',
  link: 'link',
  multi_file: 'multi_file',
  multi_rel: 'multi_rel',
  multi_role: 'multi_role',
  multi_select: 'multi_select',
  multi_string: 'multi_string',
  number: 'number',
  rel: 'rel',
  role: 'role',
  select: 'select',
  string: 'string',
};

export const ELEMENT_TYPES: EntityContainer<ElementType> = {
  bool: {
    type: ELEMENT_TYPE_ID.bool,
    name: 'Checkbox',
    isGroup: true,
    isEmbedded: false,
    isFilter: true,
    isFooter: false,
    isSort: true,
  },
  datetime: {
    type: ELEMENT_TYPE_ID.datetime,
    name: 'Date',
    isGroup: true,
    isEmbedded: false,
    isFilter: true,
    isFooter: false,
    isSort: true,
  },
  link: {
    type: ELEMENT_TYPE_ID.link,
    name: 'Link',
    isGroup: false,
    isEmbedded: false,
    isFilter: false,
    isFooter: false,
    isSort: true,
  },
  multi_rel: {
    type: ELEMENT_TYPE_ID.multi_rel,
    name: 'Multi Relation',
    isGroup: false,
    isEmbedded: true,
    isFilter: false,
    isFooter: false,
    isSort: false,
  },
  multi_select: {
    type: ELEMENT_TYPE_ID.multi_select,
    name: 'Multi Select',
    isGroup: false,
    isEmbedded: false,
    isFilter: true,
    isFooter: false,
    isSort: false,
  },
  multi_role: {
    type: ELEMENT_TYPE_ID.multi_role,
    name: 'Multi Role',
    isGroup: false,
    isEmbedded: false,
    isFilter: false,
    isFooter: false,
    isSort: false,
  },
  multi_string: {
    type: ELEMENT_TYPE_ID.multi_string,
    name: 'Multi String',
    isGroup: false,
    isEmbedded: false,
    isFilter: false,
    isFooter: false,
    isSort: false,
  },
  number: {
    type: ELEMENT_TYPE_ID.number,
    name: 'Number',
    isGroup: true,
    isEmbedded: false,
    isFilter: true,
    isFooter: true,
    isSort: true,
  },
  rel: {
    type: ELEMENT_TYPE_ID.rel,
    name: 'Relation',
    isGroup: true,
    isEmbedded: true,
    isFilter: true,
    isFooter: false,
    isSort: false,
  },
  role: {
    type: ELEMENT_TYPE_ID.role,
    name: 'Role',
    isGroup: false,
    isEmbedded: false,
    isFilter: false,
    isFooter: false,
    isSort: false,
  },
  select: {
    type: ELEMENT_TYPE_ID.select,
    name: 'Select',
    isGroup: true,
    isEmbedded: false,
    isFilter: true,
    isFooter: false,
    isSort: false,
  },
  string: {
    type: ELEMENT_TYPE_ID.string,
    name: 'Text',
    isEmbedded: false,
    isGroup: true,
    isFilter: true,
    isFooter: false,
    isSort: true,
  },
  file: {
    type: ELEMENT_TYPE_ID.file,
    name: 'Attachment',
    isEmbedded: false,
    isGroup: false,
    isFilter: false,
    isFooter: false,
    isSort: false,
  },
  multi_file: {
    type: ELEMENT_TYPE_ID.file,
    name: 'Attachment',
    isEmbedded: false,
    isGroup: false,
    isFilter: false,
    isFooter: false,
    isSort: false,
  },
};
