import { User, Group } from '../../../redux/models/auth.models';

export const getSearchedUsers = (search: string, users: User[]) => {
  if (search === '') {
    return users;
  }
  const token = search.toLocaleLowerCase();
  return users.filter(
    (user: User) =>
      user.data.name.toLocaleLowerCase().includes(token) || user.data.email.toLocaleLowerCase().includes(token)
  );
};

export const getSearchedGroups = (search: string, groups: Group[]) => {
  if (search === '') {
    return groups;
  }
  const token = search.toLocaleLowerCase();
  return groups.filter((group: Group) => group.name.toLocaleLowerCase().includes(token));
};
