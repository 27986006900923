import { EntityContainer } from '../../../../redux/slices/core.models';

export interface HopperFile {
  path: string;
  name: string;
  filetype: string;
}

// key by entity??
// what happens when upload fails
export class Queue {
  static inFlight: EntityContainer<EntityContainer<File>> = {};

  static downloaded: EntityContainer<EntityContainer<string>> = {};

  static uploaded: EntityContainer<EntityContainer<HopperFile>> = {};

  static filePaths: EntityContainer<EntityContainer<string>> = {};

  static fileKey(file: File) {
    return `${file.name}-${file.size}-${file.lastModified}`;
  }

  static isInQueue(file: File, queueId: string) {
    const key = Queue.fileKey(file);
    if (this.inFlight[queueId]) {
      return this.inFlight[queueId][key] !== undefined || this.downloaded[queueId][key] !== undefined;
    }
    return false;
  }

  static start(queueId: string, file: File) {
    const key = Queue.fileKey(file);
    if (this.inFlight[queueId]) {
      this.inFlight[queueId][key] = file;
    } else {
      this.inFlight[queueId] = {};
      this.inFlight[queueId][key] = file;
    }
    if (this.downloaded[queueId]) {
      this.downloaded[queueId][key] = key;
    } else {
      this.downloaded[queueId] = {};
      this.downloaded[queueId][key] = key;
    }
  }

  static add(queueId: string, file: HopperFile) {
    if (this.uploaded[queueId]) {
      this.uploaded[queueId][file.path] = file;
    } else {
      this.uploaded[queueId] = {};
      this.uploaded[queueId][file.path] = file;
    }
  }

  static addPath(queueId: string, path: string) {
    if (this.filePaths[queueId]) {
      this.filePaths[queueId][path] = path;
    } else {
      this.filePaths[queueId] = {};
      this.filePaths[queueId][path] = path;
    }
  }

  static getPaths(queueId: string) {
    return Object.values(this.filePaths[queueId]);
  }

  static remove(queueId: string, file: File) {
    const key = Queue.fileKey(file);
    if (this.inFlight[queueId] && this.inFlight[queueId][key]) {
      delete this.inFlight[queueId][key];
    }
  }

  static suspend(queueId: string) {
    delete this.inFlight[queueId];
    delete this.uploaded[queueId];
    delete this.filePaths[queueId];
    delete this.downloaded[queueId];
  }

  static removePaths(queueId: string) {
    if (this.inFlight[queueId]) {
      this.inFlight[queueId] = {};
    }
    if (this.filePaths[queueId]) {
      this.filePaths[queueId] = {};
    }
  }

  static isEmpty(queueId: string) {
    if (this.inFlight[queueId]) {
      return Object.keys(this.inFlight[queueId]).length === 0;
    }
    return false;
  }

  static files(queueId: string) {
    const obj = this.uploaded[queueId];
    if (!obj) {
      return [];
    }
    return Object.values(obj);
  }
}
