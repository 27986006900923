import React, { useEffect, useState } from 'react';
import { CollectionElement, TableData } from '../../redux/models/database.model';
import GridViewItem from './GridViewItem';
import { ELEMENT_TYPE_ID } from '../../redux/slices/element.models';
import { getFileElement, getImgEntity, getPrimaryElement, getRows } from './gridview.utils';

interface Props {
  search: string;
  data: TableData;
  isEmpty: boolean;
  onEdit: () => void;
}

const getGridViewElements = (elements: CollectionElement[], primary: CollectionElement) => {
  return elements.filter((el) => {
    const type = el.type || '';
    return (
      el.name !== primary.name &&
      el.property !== primary.property &&
      type !== ELEMENT_TYPE_ID.link &&
      type !== ELEMENT_TYPE_ID.role &&
      type !== ELEMENT_TYPE_ID.multi_role &&
      type !== ELEMENT_TYPE_ID.bool &&
      type !== ELEMENT_TYPE_ID.multi_string &&
      el.visible
    );
  });
};

export default function GridView({ search, data, isEmpty, onEdit }: Props) {
  const [isImage, setIsImage] = useState(false);
  const [elements, setElements] = useState<CollectionElement[]>([]);
  const entityData = data.rows || [];
  const entities = getRows(search, entityData);
  const primary = getPrimaryElement(elements, 'name');
  const els = getGridViewElements(elements, primary);
  useEffect(() => {
    setElements(data.columns || []);
    const fileElement = getFileElement(elements);
    if (fileElement) {
      const imgs = getImgEntity(entities, fileElement);
      if (imgs) setIsImage(true);
    }
  }, [elements, entities, data]);
  return (
    <div>
      {!isEmpty && (
        <ul className="p-8 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
          {entities.map((entity) => (
            <GridViewItem
              key={entity.entity_id}
              entity={entity}
              primary={primary}
              elements={els}
              thumbnail={isImage}
              onEdit={onEdit}
            />
          ))}
        </ul>
      )}
    </div>
  );
}
