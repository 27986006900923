import { EntityContainer } from '../../redux/slices/core.models';

const DEFAULT_COLOUR = 'bg-sky-500';

const Colours = {
  indigo: 'bg-indigo-500',
  green: 'bg-green-500',
  yellow: 'bg-yellow-500',
  gray: 'bg-gray-500',
  cyan: 'bg-cyan-500',
  red: 'bg-red-500',
  pink: 'bg-pink-500',
  orange: 'bg-orange-500',
  lime: 'bg-lime-500',
  teal: 'bg-teal-500',
  sky: 'bg-sky-500',
};

export const colours: EntityContainer<string> = {
  Rates: Colours.lime,
  'Load Types': Colours.lime,
  'Fuel Surcharges': Colours.lime,
  'Department Codes': Colours.lime,
  'GL Codes': Colours.lime,
  Invoices: Colours.lime,
  'Fuel Cards': Colours.yellow,
  'Fuel Purchases': Colours.yellow,
  'CSV Importer': Colours.yellow,
  'CSV Locations': Colours.yellow,
  HOS: Colours.yellow,
  Fleet: Colours.indigo,
  Vehicles: Colours.indigo,
};

export const getColour = (path: string) => {
  return colours[path] || DEFAULT_COLOUR;
};
