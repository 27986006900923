/* eslint-disable no-console */
import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CollectionElementOption } from '../../../../../redux/models/database.model';
import { CollectionTextInput, CollectionElementOptionColoutsInput } from '../CollectionInputs';

interface Props {
  open: boolean;
  option: CollectionElementOption;
  setOption: React.Dispatch<React.SetStateAction<CollectionElementOption | null>>;
  onSubmit: (option: CollectionElementOption) => void;
  onClose: () => void;
}

export default function ElementOptionModal({ open, option, setOption, onSubmit, onClose }: Props) {
  const cancelButtonRef = useRef(null);
  const handleUpdate = (val: string, prop: string) => {
    const updates = {
      ...option,
      [prop]: val,
    };
    setOption(updates);
  };
  const handleColour = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updates = {
      ...option,
      color: e.target.value,
    };
    setOption(updates);
  };
  const handleClose = () => {
    onClose();
  };
  const handleSubmit = () => {
    onSubmit(option);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                      Element Option
                    </Dialog.Title>
                  </div>
                </div>
                <form>
                  <CollectionTextInput
                    name="Name"
                    type="text"
                    prop="name"
                    value={option.name}
                    handleChange={handleUpdate}
                  />
                  <CollectionElementOptionColoutsInput value={option.color} handleChange={handleColour} />
                </form>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={handleClose}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
