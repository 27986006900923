/* eslint-disable */
import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import DirectoryItemSearch from '../../pages/users/directory/DirectoryItemSearch';
import DirectoryItemSearchResult from '../../pages/users/directory/DirectoryItemSearchResult';
import Spinner from '../shared/Spinner';
import { EntityContainer } from '../../redux/slices/core.models';

interface Props<T> {
  title: string;
  store: EntityContainer<T>;
  isImage: boolean;
  canEdit: boolean;
  getId: (item: T) => string;
  getName: (item: T) => string;
  getDetails: (item: T) => string;
  getImg: (item: T) => string;
  onAdd: (item: T) => void;
}

export default function AddGroupInput<T>({
  title,
  store,
  isImage,
  canEdit,
  getName,
  getId,
  getImg,
  onAdd,
}: Props<T>) {
  const [selectedItem, setSelectedItem] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleAddItem = async () => {
    if (selectedItem && canEdit) {
      setIsLoading(true);
      await onAdd(selectedItem);
      setIsLoading(false);
      setSelectedItem(null);
    }
  };
  return (
    <div className="space-y-2">
      <label htmlFor="add-team-members" className="text-sm font-medium text-gray-500">
        {title}
      </label>
      <p id="add-team-members-helper" className="sr-only">
        Search by name
      </p>
      <div className="flex items-center">
        <div className="flex-grow">
          <DirectoryItemSearch
            selectedItem={selectedItem}
            data={Object.values(store)}
            placeholder="Search by name"
            nameExtractor={getName}
            keyExtractor={getId}
            setSeleced={setSelectedItem}
            renderListItem={(item, selected) => (
              <DirectoryItemSearchResult
                name={getName(item)}
                isImage={isImage}
                imgUrl={getImg(item)}
                selected={selected}
              />
            )}
          />
        </div>
        <span className="ml-3 pt-2">
          <button
            type="button"
            onClick={handleAddItem}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {isLoading ? (
              <Spinner size="small" />
            ) : (
              <PlusIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
            Add
          </button>
        </span>
      </div>
    </div>
  );
}
