import icons from '../components/Features/icons';
import { Colours, CTA, Feature, Hero, LandingPage } from '../models';

const hero: Hero = {
  header: 'Automate your way to a ',
  headerSpan: 'better business',
  subtext: 'With the power of Hopper, you can reduce time spent on repetitive tasks and keep everything on track.',
  imageUrl:
    'https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80',
  buttonText: 'Log In',
};

const cta: CTA = {
  header: 'Business is a messy, complicated thing.',
  subtext: "But it doesn't have to be. We make it easy for you to run your company at the highest level possible.",
};

const features: Feature[] = [
  {
    header: 'Automate tedious tasks',
    details:
      "Automating repetitive tasks can save you time, increase profits, improve productivity, and more. You'll get better scalability, error minimization, and a higher standard of customer experience. In short: Automating repetitive tasks is an easy way to improve your business!",
    imageUrl: 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/638e6ee37c826e14b53b5c51_Warning%20Cards.png',
    items: [
      {
        icon: icons.envelope,
        header: 'Email & Spreadsheet Automation',
        details: 'Set your email to autopilot as we process incoming requests and trigger automated workflows.',
      },
      {
        icon: icons.envelope,
        header: 'Generate Invoices & Sales Records',
        details:
          'Work your way and take control of your data. Seemlessly integrate your software so you have a centralised source of truth for all your tools',
      },
    ],
  },
  {
    header: 'Manage large amounts of information',
    details:
      "Data management is hard. It's time-consuming, frustrating, and challenging.But it doesn't have to be! With Hopper, you can turn your disparate piles of data into a valuable resource that you can use to improve the quality and consistency of your information while reducing the amount of time you spend managing it in the first place.",
    imageUrl: 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/638e6f1067fd8f02806bbf3c_Report%20Card.png',
    items: [
      {
        icon: icons.chat,
        header: 'Connect The Unconnected',
        details:
          'Work your way and take control of your data. Seemlessly integrate your software so you have a centralised source of truth for all your tools.',
      },
    ],
  },
];

const colours: Colours = {
  primary: 'blue',
  buttonBg: 'bg-blue-500',
  buttonHover: 'hover:bg-blue-700',
  textAccent: 'text-blue-600',
};

const HopperHomePage: LandingPage = {
  colours,
  hero,
  cta,
  features,
  footer: '© 2020 DevShed Digital, Inc. All rights reserved.',
};

export default HopperHomePage;
