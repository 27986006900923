/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, DetailedHTMLProps, InputHTMLAttributes, useRef, useEffect } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  type: string;
  placeholder: string;
  width: string;
  minWidth: string;
  name: string;
  setFormikValue: any;
  reset: any;
  setReset: any;
}

function MultiCellInput({ type, placeholder, width, name, setFormikValue, reset, setReset, minWidth }: Props) {
  const [tags, setTags] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = (event: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
    const typedValue = inputRef.current!.value;
    if (event.key === 'Enter' && typedValue !== '') {
      setTags((prev) => {
        const newItem = [...prev, typedValue];
        setFormikValue(name, newItem);
        return newItem;
      });
      inputRef.current!.value = '';
    }
  };

  const handleClick = (idx: number) => {
    setTags((prev) => {
      prev.splice(idx, 1);
      return [...prev];
    });
  };

  useEffect(() => {
    if (reset) {
      setTags([]);
      setReset(false);
    }
  }, [setReset, reset]);

  return (
    <div className={`table-cell-row  ${tags.length > 0 ? `items-start flex-col` : ``}	`} style={{ width, minWidth }}>
      <div className={`flex flex-wrap ${tags.length > 0 ? `pb-2` : ``}`}>
        {tags &&
          tags.map((ele, idx) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div
                className=" break-words text-sm leading-5 rounded-md bg-cell-content text-cell-input-text w-fit m-1 px-1 relative"
                // eslint-disable-next-line react/no-array-index-key
                key={ele + idx}
              >
                <FontAwesomeIcon
                  fixedWidth
                  height={24}
                  width={24}
                  icon={faXmark}
                  className="pr-1 cursor-pointer"
                  onClick={() => handleClick(idx)}
                />
                <span>{ele}</span>
              </div>
            );
          })}
      </div>
      <input
        className="w-full"
        type={type}
        placeholder={placeholder}
        name={name}
        ref={inputRef}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}

export default MultiCellInput;
