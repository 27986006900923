/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ELEMENT_TYPE_ID } from '../../redux/slices/element.models';
import GridViewBadge from './GridViewBadge';
import { Entity } from '../../redux/models/auth.models';
import { CollectionElement, CollectionElementOption } from '../../redux/models/database.model';
import { getEntityDisplayValue } from '../../redux/slices/database.utils';

const { datetime, select, multi_select: multiSelect } = ELEMENT_TYPE_ID;

const getColour = (id: string, options: CollectionElementOption[] | null) => {
  if (!options) return 'blue';
  return options.find((op) => op.id === id)?.color || 'blue';
};

function MultiBadgeView({ entity, element }: { entity: Entity; element: CollectionElement }) {
  const vals: any = entity.data[element.property] || [];
  const options = element.options || [];
  return vals.map((item: string, idx: number) => {
    const option = options.find((op) => op.id === item);
    const name = option?.name || '';
    const colour = option?.color || 'green';
    return <GridViewBadge key={`gidview-badge-${item}-${idx}`} text={name} colour={colour} />;
  });
}

export default function GridViewItemRow({ entity, element }: { entity: Entity; element: CollectionElement }) {
  const name = element.name || '';
  const val = getEntityDisplayValue(entity, element);
  const isSingleBadge = element.type === select;
  const isMultiBadge = element.type === multiSelect;
  if (element.type === datetime) {
    return (
      <div className="flex justify-between gap-x-4 py-3">
        <dt className="text-gray-500">{name}</dt>
        <dd className="text-gray-700">
          <time dateTime={val}>{val}</time>
        </dd>
      </div>
    );
  }
  return (
    <div className="flex justify-between gap-x-4 py-3">
      <dt className="text-gray-500">{name}</dt>
      <dd className="flex items-start gap-x-2">
        {!isSingleBadge && !isMultiBadge && <div className="font-medium text-gray-900">{val}</div>}
        {isSingleBadge && val && (
          <GridViewBadge text={val} colour={getColour(entity.data[element.property] || '', element.options)} />
        )}
        {isMultiBadge && <MultiBadgeView entity={entity} element={element} />}
      </dd>
    </div>
  );
}
