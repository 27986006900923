import React from 'react';
import { ChevronDownIcon, ChevronRightIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { getLabelColour } from '../colours';
import classNames from '../../../utils/tailwind';
import { createCSVFile } from '../../../utils/csv/csv';
import { TableData } from '../../../redux/models/database.model';
import { DataModel } from '../../../redux/slices/core.models';
import { getTableWidth } from '../table.utils';

interface Props {
  title: string;
  displayData: TableData;
  rows: DataModel<object>[];
  onExpand?: (item: TableData) => void;
}

export default function GroupedTableHeader({ title, displayData, rows, onExpand }: Props) {
  const iconClass = 'text-gray-500 ml-1 h-4 w-4 group-hover:text-gray-500';
  const handleDownload = () => {
    createCSVFile(displayData);
  };
  const labelColour = getLabelColour(displayData.title);
  const columns = displayData.columns || [];
  const tableWidth = getTableWidth(columns);

  return (
    <div className="sticky z-10 w-full" style={{ minWidth: tableWidth, width: tableWidth }}>
      <div className="flex items-center bg-gray-100 rounded-t-lg bg-opacity-75">
        <h2 className="mt-2 px-2 py-1 text-xs font-medium text-gray-700">{`${title.toUpperCase()}: `}</h2>
        <button
          type="submit"
          onClick={() => onExpand && onExpand(displayData)}
          className={classNames(labelColour, 'mt-2 px-2 py-1 flex items-center rounded-full')}
        >
          <span className="text-xs font-bold">{displayData.title}</span>
          {displayData.isOpen ? <ChevronDownIcon className={iconClass} /> : <ChevronRightIcon className={iconClass} />}
        </button>
        <span className="text-xs text-gray-500 pt-2 px-2">{`Count: ${rows.length || 0}`}</span>
        <button
          type="button"
          onClick={() => handleDownload()}
          className="text-xs text-blue-600 hover:text-blue-800 hover:font-medium mr-2 mt-1"
        >
          <ArrowDownTrayIcon className="text-gray-500 ml-2 mr-1 h-4 w-4 hover:text-gray-900" />
        </button>
      </div>
    </div>
  );
}

GroupedTableHeader.defaultProps = {
  onExpand: () => {},
};
