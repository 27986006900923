/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../../components/shared/Notification';
import { REPORTS } from './slices';
import { AppState } from '../models/app.models';
import { ReportConfig, Report, initialReportState } from '../models/report.models';
import { CollectionFilter, CollectionRequest, DEFAULT_REQUEST_BODY } from './collection.models';
import { CollectionElement, CollectionGroupResponse, CollectionListResponse } from '../models/database.model';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import { Entity } from '../models/auth.models';
import { getEntityDisplayValue } from './database.utils';
import ReportApiProvider from '../../services/ReportApiProvider';
import { getDateRangeFilters } from '../../utils/date.utils';

const SLICE_NAME = REPORTS;

const initialState = initialReportState;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setConfig(state, action) {
      state.config = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    setReports(state, action) {
      state.reports = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.errorOnLoad = action.payload;
    },
    resetReports(state) {
      state.reports = [];
    },
  },
});

export const { resetReports, setConfig, setIsLoaded, setIsLoading, setErrorOnLoadingData, setReports } = slice.actions;

export const loadReports: any = (orgId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  dispatch(setErrorOnLoadingData(false));
  try {
    const filters: CollectionRequest = {
      ...DEFAULT_REQUEST_BODY,
      filters: [
        {
          field: {
            id: 'Organization',
            name: 'data.organization_id',
            type: 'rel',
          },
          op: 'eq',
          value: orgId,
        },
      ],
    };
    const response = await ReportApiProvider.findReports(filters);
    const data: CollectionListResponse = ReportApiProvider.parseResponse(response);
    dispatch(setConfig(data.items));
    dispatch(setIsLoaded(true));
  } catch (error) {
    dispatch(setErrorOnLoadingData(true));
    if (error instanceof Error) {
      const errorMessage = `Couldn't get Automations. ${error.message}. Please contact support if the problem persists.`;
      notify('', errorMessage, 'info');
    }
  } finally {
    dispatch(setIsLoading(false));
  }
};

const sumItems = (entities: Entity[], prop: string) => {
  return entities
    .reduce((count, entity) => {
      const total = entity.data[prop] || 0;
      return count + parseFloat(total);
    }, 0)
    .toFixed(2);
};

const getItemName = (entities: Entity[], element: CollectionElement | undefined) => {
  if (entities.length === 0) return '';
  return element ? getEntityDisplayValue(entities[0], element) : '';
};

export const getReportTotal = (config: ReportConfig, data: any) => {
  const isGrouped = config.groupBy !== '';
  if (isGrouped) {
    if (config.operation === 'sum') {
      const response: CollectionGroupResponse = data;
      const flattened = response.items.flatMap((item) => {
        return item.items;
      });
      const prop = config.prop || '';
      return sumItems(flattened, prop);
    }
    return data.total_count || 0;
  }
  if (config.operation === 'sum') {
    const items: Entity[] = data.items || [];
    const prop = config.prop || '';
    return sumItems(items, prop);
  }
  return data.total_count || 0;
};

export const getReportItems = (
  config: ReportConfig,
  data: any,
  nameElement: CollectionElement | undefined,
  valueElement: CollectionElement | undefined
) => {
  const isGrouped = config.groupBy !== '';
  const prop = config.prop || '';
  if (isGrouped) {
    const response: CollectionGroupResponse = data;
    return response.items
      .map((item) => {
        const total = item.total_count || 0;
        const amt = config.operation === 'sum' ? sumItems(item.items, prop) : `${total}`;
        return {
          name: getItemName(item.items, nameElement),
          total: parseFloat(amt),
        };
      })
      .sort((a, b) => b.total - a.total);
  }
  const items: Entity[] = data.items || [];
  return items.map((item) => {
    return {
      name: getItemName([item], nameElement),
      total: getItemName([item], valueElement),
    };
  });
};

const getDateFilters = (report: Report) => {
  const range = report.data.date_range;
  if (!range) return [];
  const dates = getDateRangeFilters(range.range);
  const filters: CollectionFilter[] = [
    {
      field: {
        id: range.id,
        name: range.prop,
        type: 'datetime',
      },
      op: 'gte',
      value: dates.dateFrom,
    },
    {
      field: {
        id: range.id,
        name: range.prop,
        type: 'datetime',
      },
      op: 'lte',
      value: dates.dateTo,
    },
  ];
  return filters;
};

export const loadReport = async (report: Report) => {
  const { collection, app } = report.data;
  const baseUrl = app.data?.base_url || '';
  const path = collection.data.path || '';
  const url = `${baseUrl}${path}`;
  const filters: CollectionRequest = {
    ...report.data.filters,
    filters: [...report.data.filters.filters, ...getDateFilters(report)],
    pagination: {
      page: 0,
      page_size: 1000,
    },
  };
  return HopperServiceApiProvider.getEntityData(url, filters);
};

const automationsReducer = slice.reducer;
export default automationsReducer;

export const selectConfig = (state: AppState) => state[SLICE_NAME].config;
export const selectIsReportsLoaded = (state: AppState) => state[SLICE_NAME].isLoaded;
export const selectIsReportsLoading = (state: AppState) => state[SLICE_NAME].isLoading;
export const errorOnLoadingData = (state: AppState) => state[SLICE_NAME].errorOnLoad;
