import React from 'react';
import { FooterCalculation, FooterItem } from '../../../redux/models/database.model';
import classNames from '../../../utils/tailwind';
import CalculationsFlyOutMenu from './CalculationsFlyOutMenu';

interface Props {
  item: FooterItem;
  index: number;
  onCalculate: (index: number, calculation: FooterCalculation) => string;
}

function TableFootRowItem({ item, index, onCalculate }: Props) {
  const { isSupported } = item;
  return (
    <div className={classNames(isSupported ? 'hover:bg-gray-100' : '', 'bg-white flex items-center justify-start')}>
      <CalculationsFlyOutMenu item={item} index={index} onCalculate={onCalculate} />
    </div>
  );
}

export default TableFootRowItem;
