/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { getValueFromSessionStorage } from '../../../../utils/core.utils';
import { FileValues } from '../../TableCell.modals';
import { ENTITYURL } from '../../../../constants/core.contants';
import Spinner from '../../../../components/shared/Spinner';
import Uploader from '../../../Modal/Uploader';
import { Queue } from './attachment.utils';
import FileApi from './FileApi';
import { notify } from '../../../../components/shared/Notification';
import AttachmentRowItem from './AttachmentRowItem';

interface Props {
  queueId: string;
  width: string;
  formikValue: any;
  minWidth: string;
  setFormikValue: any;
  formikProperty: any;
  submitFormik: any;
  isForm?: boolean;
  editable: boolean;
  isModal?: boolean;
}

function MultiAttachmentRow({
  queueId,
  formikValue,
  setFormikValue,
  formikProperty,
  submitFormik,
  isForm = false,
  editable,
  isModal = false,
  width,
  minWidth,
}: Props) {
  const postURL = getValueFromSessionStorage(ENTITYURL);
  const isValue = formikValue !== undefined && Array.isArray(formikValue);
  const data = formikValue ?? [];

  const handleClick = async (file: FileValues) => {
    try {
      const downloadedFile = await FileApi.downloadFile(file);
      Queue.addPath(queueId, downloadedFile.path || '');
      const url = downloadedFile.path;
      if (url) window.open(url, '_blank');
    } catch (error) {
      notify('Error downloading file', 'Please contact support if the problem persists', 'error');
    }
  };

  const handleFileUpload = async (filepath: FileValues) => {
    const existingFiles = formikValue || [];
    setFormikValue(formikProperty, [...existingFiles, ...Queue.files(queueId)]);
    if (Queue.isEmpty(queueId)) {
      if (!isForm) submitFormik();
    }
  };

  const handleXClick = (idx: number) => {
    const confirmResult = window.confirm('Are you sure you want to delete the file?');
    if (confirmResult) {
      setFormikValue(
        formikProperty,
        formikValue.filter((ele: string, index: number) => index !== idx)
      );
      if (!isForm) {
        submitFormik();
      }
    }
  };

  return (
    <div style={{ width, minWidth }}>
      {isValue ? (
        <div className="table-inner-cell hover:cursor-pointer flex align-middle">
          <div className="flex flex-wrap items-start">
            {data.map((file: FileValues, idx: number) => {
              return (
                <AttachmentRowItem
                  key={`multi-attachment-row-${idx}`}
                  file={file}
                  idx={idx}
                  editable={editable}
                  handleOpen={handleClick}
                  handleDelete={handleXClick}
                />
              );
            })}
            {!isValue && <p className="text-xs text-gray-600">No files</p>}
          </div>
        </div>
      ) : (
        !isForm && isValue && <Spinner size="small" />
      )}
      {(isModal || isForm) && editable && (
        <div className="mt-6 w-full">
          <Uploader
            queueId={queueId}
            uploadUrl={postURL}
            handleFileUpload={handleFileUpload}
            isModal={isModal}
            isMulti
          />
        </div>
      )}
    </div>
  );
}

MultiAttachmentRow.defaultProps = {
  isForm: false,
  isModal: false,
};

export default MultiAttachmentRow;
