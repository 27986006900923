import { axiosInstance, hopperInstance } from '../utils/axios';
import HopperServiceConfigProvider from './hopperServiceConfigProvider';

class GroupApiProvider {
  static getGroups(organizationCode: string) {
    return axiosInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.getGroups(organizationCode),
      validateStatus: () => true,
    });
  }

  static getGroupById(groupId: string) {
    return axiosInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.groupById(groupId),
      validateStatus: () => true,
    });
  }

  static getOrgPermissions(organizationId: string) {
    return hopperInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.getOrgPermissions(organizationId),
      validateStatus: () => true,
    });
  }

  static createGroup(data: { name: string; description: string; organization_code: string }) {
    return axiosInstance({
      method: 'POST',
      url: HopperServiceConfigProvider.createGroup(),
      validateStatus: () => true,
      data,
    });
  }

  static deleteGroup(groupId: string) {
    return axiosInstance({
      method: 'DELETE',
      url: HopperServiceConfigProvider.groupById(groupId),
      validateStatus: () => true,
    });
  }

  static addPermissions(groupId: string, data: { permission_ids: string[] }) {
    return axiosInstance({
      method: 'POST',
      url: HopperServiceConfigProvider.groupPermissions(groupId),
      validateStatus: () => true,
      data,
    });
  }

  static removePermissions(groupId: string, data: { permission_ids: string[] }) {
    return axiosInstance({
      method: 'DELETE',
      url: HopperServiceConfigProvider.groupPermissions(groupId),
      validateStatus: () => true,
      data,
    });
  }
}

export default GroupApiProvider;
