import React, { useState } from 'react';
import { notify } from '../../../../components/shared/Notification';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { setAddGroup } from '../../../../redux/slices/groups';
import GroupApiProvider from '../../../../services/GroupApiProvider';
import AddDirectoryItemModal from '../AddDirectoryItemModal';
import AddGroupForm from './AddGroupForm';

interface Props {
  orgCode: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddGroupModal({ orgCode, open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const isNameValid = name.length > 0;
  const isDescriptionValid = description.length > 0;
  const handleChange = (prop: string, value: string) => {
    if (prop === 'name') {
      setName(value);
    } else {
      setDescription(value);
    }
  };
  const onSubmit = async () => {
    const data = {
      name,
      description,
      organization_code: orgCode,
    };
    const response = await GroupApiProvider.createGroup(data);
    if (response.status === 200) {
      setName('');
      setDescription('');
      dispatch(setAddGroup(response.data.data));
      setOpen(false);
    } else {
      notify('Error Creating Group', "Couldn't add group", 'error');
    }
  };
  return (
    <AddDirectoryItemModal
      title="Create Group"
      open={open}
      valid={isNameValid && isDescriptionValid}
      setOpen={setOpen}
      onSubmit={onSubmit}
      renderForm={() => (
        <AddGroupForm name={name} description={description} handleChange={handleChange} onSubmit={onSubmit} />
      )}
    />
  );
}
