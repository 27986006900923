import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const ORGANISATIONS_PAGE = '/organisations';

interface LandingProps {
  children: React.ReactNode;
}

function Landing({ children }: LandingProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  if (isInitialized && isAuthenticated) {
    return <Navigate to={ORGANISATIONS_PAGE} />;
  }

  return (
    <section>
      {children}
      <Outlet />
    </section>
  );
}

export default Landing;
