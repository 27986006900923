/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { getEntityDisplayValue } from '../../redux/slices/database.utils';
import { CollectionData, CollectionElement } from '../../redux/models/database.model';
import { Entity } from '../../redux/models/auth.models';
import classNames from '../../utils/tailwind';

interface Props {
  entity: Entity;
  displayType: string;
  collection: CollectionData;
  handleSelect: any;
  multi: boolean;
  isSelected: boolean;
  setOpen: (open: boolean) => void;
}

interface EntityDetailItem {
  name: string;
  value: string;
}
function RelationShipListItem({ entity, displayType, collection, handleSelect, isSelected, setOpen, multi }: Props) {
  const displayEle: EntityDetailItem[] = collection.elements
    .filter(({ required }: CollectionElement) => required)
    .map((element) => {
      return {
        name: element.name || '',
        value: getEntityDisplayValue(entity, element),
      };
    });
  const title = entity?.data[displayType];

  const handleClick = () => {
    handleSelect(entity, title);
    if (!multi) {
      setOpen(false);
    }
  };
  const borderColour = isSelected ? 'border border-blue-500' : 'border';
  return (
    <li className={classNames(borderColour, 'rounded-md mb-5 py-3 px-3 hover:border-blue-700')} onClick={handleClick}>
      <h2 className="font-bold pb-3">{title || `No "${displayType}"`}</h2>
      <div className="flex justify-between ">
        {displayEle.map((detailItem) => {
          const eleValue =
            typeof detailItem.value === 'string' || typeof detailItem.value === 'number' ? detailItem.value : 'no name';
          return (
            <div className="" key={`${detailItem.name}-${detailItem.value}`}>
              <div className="">
                <p className="uppercase text-gray-400 text-sm	">{detailItem.name}</p>
                <p>{eleValue}</p>
              </div>
            </div>
          );
        })}
      </div>
    </li>
  );
}

export default RelationShipListItem;
