import React from 'react';
import { Directory } from '../../../redux/models/auth.models';

interface Props<T> {
  directory: Directory<T>[];
  searchText: string;
  renderListItem: (item: T) => React.ReactNode;
  keyExtractor: (item: T) => string;
  searchResults: (search: string, data: T[]) => T[];
}

export default function DirectoryList<T>({
  directory,
  searchText,
  searchResults,
  renderListItem,
  keyExtractor,
}: Props<T>) {
  return (
    <div>
      {directory?.map((dir: Directory<T>) => (
        <div key={dir.letter} className="relative">
          <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
            <h3>{dir.letter}</h3>
          </div>
          <ul className="relative z-0 divide-y divide-gray-200">
            {searchResults(searchText, dir.data).map((data: T) => (
              <li key={keyExtractor(data)}>{renderListItem(data)}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
