import React from 'react';
import classNames from '../../utils/tailwind';
import { EntityContainer } from '../../redux/slices/core.models';

const statuses: EntityContainer<string> = {
  blue: 'text-blue-700 bg-blue-50 ring-blue-600/20',
  cyan: 'text-cyan-700 bg-cyan-50 ring-cyan-600/20',
  green: 'text-green-700 bg-green-50 ring-green-600/20',
  gray: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  indigo: 'text-indigo-700 bg-indigo-50 ring-indigo-600/20',
  lime: 'text-lime-700 bg-lime-50 ring-lime-600/20',
  orange: 'text-orange-700 bg-orange-50 ring-orange-600/20',
  pink: 'text-pink-700 bg-pink-50 ring-pink-600/20',
  red: 'text-red-700 bg-red-50 ring-red-600/10',
  sky: 'text-sky-700 bg-sky-50 ring-sky-600/20',
  teal: 'text-teal-700 bg-teal-50 ring-teal-600/20',
  yellow: 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
};

interface Props {
  text: string;
  colour: string;
}

export default function GridViewBadge({ text, colour }: Props) {
  const classes = classNames(statuses[colour || 'green'], 'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset');
  return <div className={classes}>{text}</div>;
}
