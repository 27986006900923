import {
  ArrowTrendingUpIcon,
  BoltIcon,
  ChatBubbleBottomCenterTextIcon,
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  GlobeAmericasIcon,
  SparklesIcon,
  StarIcon,
} from '@heroicons/react/24/outline';

export default {
  bolt: BoltIcon,
  chat: ChatBubbleBottomCenterTextIcon,
  clipboard: ClipboardDocumentListIcon,
  currency: CurrencyDollarIcon,
  envelope: EnvelopeIcon,
  globe: GlobeAmericasIcon,
  trendingUp: ArrowTrendingUpIcon,
  sparkles: SparklesIcon,
  star: StarIcon,
};
