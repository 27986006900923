import React, { useEffect, useState } from 'react';

import AccessLevelList from './AccessLevelList';
import GroupToggle from './GroupToggle';
import PermissionDropdown from './PermissionDropdown';
import { PermissionStore } from '../../../redux/models/auth.models';
import { CollectionPermission } from './models';
import { EntityContainer } from '../../../redux/slices/core.models';
import Spinner from '../../../components/shared/Spinner';

interface Props {
  groupPermissions: PermissionStore;
  collection: CollectionPermission;
  orgName: string;
  groupName: string;
  inFlight: EntityContainer<boolean>;
  canEdit: boolean;
  handleSetEnabled: (collection: CollectionPermission) => void;
  onUpdate: (resourceId: string, permission: string, access: string) => void;
}

const getHelperText = (
  collection: CollectionPermission,
  permissions: PermissionStore,
  orgName: string,
  groupName: string
) => {
  const { name, resource_id: resourceId } = collection;
  const resource = resourceId || '';
  const noAccessText = `No access to any ${name}`;
  if (!resourceId || !permissions || !permissions[resource]) {
    return noAccessText;
  }
  const [permission] = permissions[resource];
  if (permission) {
    const parts = permission.name.split(':');
    const permissionName: EntityContainer<string> = {
      read: 'view',
      write: 'edit',
    };
    const getAccessDetails = (isReadOnly: boolean, access: string) => {
      const verbPrefix = isReadOnly ? 'shared with' : 'shared with or created by';
      const accessDetails: EntityContainer<string> = {
        org: `in ${orgName} database`,
        group: `${verbPrefix} members of the ${groupName} group`,
        author: `${verbPrefix} the user`,
      };
      return accessDetails[access];
    };
    const permissionDetails = permissionName[parts[2]];
    const isReadOnly = permissionDetails === permissionName.read;
    const accessDetails = getAccessDetails(isReadOnly, parts[3]);
    return `Can ${permissionDetails} all ${name} ${accessDetails}`;
  }
  return noAccessText;
};

export default function CollectionPermissionItem({
  collection,
  groupPermissions,
  groupName,
  orgName,
  inFlight,
  canEdit,
  handleSetEnabled,
  onUpdate,
}: Props) {
  const resourceId = collection?.resource_id || '';
  const [permissionLevel, setPermissionLevel] = useState<string>('read');
  const [accessLevel, setAccessLevel] = useState<string>('author');
  const isLoading = inFlight[resourceId] || false;
  const handleUpdatePermission = (permission: string) => {
    onUpdate(resourceId, permission, accessLevel);
  };

  const handleUpdateAccess = (access: string) => {
    onUpdate(resourceId, permissionLevel, access);
  };

  useEffect(() => {
    if (resourceId && groupPermissions && groupPermissions[resourceId]) {
      const [resource] = groupPermissions[resourceId];
      if (resource) {
        const parts = resource.name.split(':');
        setPermissionLevel(parts[2]);
        setAccessLevel(parts[3]);
      }
    }
  }, [resourceId, groupPermissions]);
  return (
    <div
      key={collection.name}
      className="mt-4 rounded-lg space-x-3 px-6 py-5 border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:border-gray-400"
    >
      <div className="relative flex items-center mb-4">
        <div className="min-w-0 flex-1">
          <div className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            <div className="flex">
              <p className="text-sm font-medium text-gray-900 mb-2 mr-2">{collection.name}</p>
              {isLoading ? <Spinner size="small" /> : null}
            </div>
            <p className="truncate text-sm text-gray-500">
              {getHelperText(collection, groupPermissions, orgName, groupName)}
            </p>
          </div>
        </div>
        {canEdit && (
          <GroupToggle enabled={collection.enabled || false} collection={collection} setEnabled={handleSetEnabled} />
        )}
      </div>
      <div className="flex">
        {collection.enabled && canEdit && (
          <PermissionDropdown permissionLevel={permissionLevel} setSelected={handleUpdatePermission} />
        )}
        {collection.enabled && canEdit && (
          <AccessLevelList name={collection.name} access={accessLevel} setSelected={handleUpdateAccess} />
        )}
      </div>
    </div>
  );
}
