import React from 'react';
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { CircleStackIcon } from '@heroicons/react/24/outline';
import classNames from '../../utils/tailwind';
import { Organisation } from '../../redux/models/database.model';

export default function UserCollections({ org }: { org: Organisation }) {
  const orgCode = org.data.organization_code;
  const permissions = org.data.permissions.map((permission) => permission.id);
  const collections = org.data.apps.flatMap((app) => app.data.collections) || [];
  const allowed = collections.filter((collection) => {
    if (collection.resource_id) {
      return permissions.includes(collection.resource_id);
    }
    return false;
  });
  return (
    <div className="mx-auto max-w-lg">
      <h2 className="text-base font-semibold leading-6 text-gray-900">Database Collections</h2>
      <p className="mt-1 text-sm text-gray-500">Get started by selecting a database to view your records.</p>
      <ul className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
        {allowed.map((collection) => (
          <li key={collection.entity_id}>
            <div className="group relative flex items-start space-x-3 py-4">
              <div className="flex-shrink-0">
                <span
                  className={classNames('bg-blue-500', 'inline-flex h-10 w-10 items-center justify-center rounded-lg')}
                >
                  <CircleStackIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="min-w-0 flex-1">
                <div className="text-sm font-medium text-gray-900">
                  <NavLink to={`/${orgCode}/collections${collection.path}`}>
                    <span className="absolute inset-0" aria-hidden="true" />
                    {collection.name}
                  </NavLink>
                </div>
                <p className="text-sm text-gray-500">{collection.path}</p>
              </div>
              <div className="flex-shrink-0 self-center">
                <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
