/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import {
  getEntityById,
  initialPageLoad,
  isEditable,
  // loadEntityById,
  loadEntityRelationships,
  // selectSelectionState,
  // setEditable,
  // setTitle,
} from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';
import Navbar from '../../components/navbar/Navbar';
import { getLocalOrganisation, getUserOrgByCode, persistOrganisation } from '../../redux/slices/organisation';
import { Collection, Organisation } from '../../redux/models/database.model';
import { loadGroups, selectGroupsList, selectIsGroupsLoaded, selectIsGroupsLoading } from '../../redux/slices/groups';

import EntityDetailsContainer from './components/EntityDetailsContainer';
import Spinner from '../../components/shared/Spinner';
import { getCollection } from '../../redux/slices/collections';
import { Entity } from '../../redux/models/auth.models';
import Analytics from '../../utils/analytics';

const loadEntity = (collection: Collection, entityId: string) => {
  const baseUrl = collection.data.app?.data.base_url || '';
  const path = collection.data.path || '';
  const url = `${baseUrl}/${path}/${entityId}`;
  return getEntityById(url);
};

/**
 * TODO:
 * - ~Add permissions dropdown~
 * - ~Add edit permissions~
 * - Add delete
 */
function EntityDetails() {
  const dispatch: any = useAppDispatch();
  const { collectionId, orgCode, entityId }: any = useParams();
  const isGroupsLoaded = useSelector(selectIsGroupsLoaded);
  const isGroupsLoading = useSelector(selectIsGroupsLoading);
  const groups = useSelector(selectGroupsList);
  const editable = useSelector(isEditable);
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [baseUrl] = useState('');
  const org: Organisation = getLocalOrganisation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [collection, setCollection] = useState<Collection | null>(null);
  const [entity, setEntity] = useState<Entity | null>(null);
  const handleCloseEntity = () => {};
  const shouldFetch = !isLoaded && !isLoading;
  useEffect(() => {
    const loadOrg = async (code: string) => {
      const fetchedOrg = await getUserOrgByCode(code);
      if (fetchedOrg) {
        await dispatch(persistOrganisation(fetchedOrg));
        await dispatch(initialPageLoad());
      }
      return fetchedOrg;
    };
    const load = async (_org: Organisation, _entityId: string, _collectionId: string) => {
      loadOrg(orgCode);
      const fetchedOrg = await getUserOrgByCode(orgCode);
      console.log(_org);
      try {
        setIsLoading(true);
        setIsLoaded(false);
        const colResponse = await getCollection(_collectionId);
        if (colResponse.status === 200) {
          const colData: Collection = colResponse.data.data;
          const elements = colData.data.elements || [];
          if (fetchedOrg) dispatch(loadEntityRelationships(fetchedOrg, elements));
          const entityResponse = await loadEntity(colData, _entityId);
          if (entityResponse.status === 200) {
            const entityData = entityResponse.data.data;
            setCollection(colData);
            setEntity(entityData);
          }
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setIsLoading(false);
        setIsLoaded(true);
      }
    };
    if (!isLoaded && !isLoading) load(org, entityId, collectionId);
  }, [org, orgCode, collectionId, dispatch, entityId, shouldFetch, isLoaded, isLoading, navigate]);

  useEffect(() => {
    const loadedOrgCode = org?.data.organization_code;
    const groupData = groups || [];
    const isGroupEmpty = groupData.length === 0;
    if (loadedOrgCode && !isGroupsLoaded && isGroupEmpty && !isGroupsLoading) {
      dispatch(loadGroups(loadedOrgCode));
    }
  }, [org, dispatch, collectionId, entityId, isGroupsLoaded, groups, isGroupsLoading]);

  return (
    <div className="min-h-full relative">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1 p-12">
          {isLoaded && entity && collection && org && (
            <EntityDetailsContainer
              baseUrl={baseUrl}
              collection={collection}
              entity={entity}
              editable={editable}
              organisation={org}
              closeDetailView={handleCloseEntity}
            />
          )}
          {isLoading && (
            <div className="flex pt-32 w-full h-full items-center justify-center">
              <Spinner />
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default EntityDetails;
