/* eslint-disable react/no-array-index-key */
import React from 'react';
import { CollectionElement } from '../../redux/models/database.model';
import { Filter } from './FilterButton.modals';
import FilterFormRow from './FilterFormRow';
import { ELEMENT_TYPES } from '../../redux/slices/element.models';

interface FormikProps {
  values: {
    filters: Filter[];
  };
}

interface Props {
  columns: CollectionElement[];
  formik: FormikProps;
  showFilters: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  deleteRow: (idx: number) => void;
}

function FilterForm({ columns, onSubmit, formik, showFilters, deleteRow }: Props) {
  // Remove this line and map the columns to bring back rels
  const columnRemoveRels = columns.filter((obj: CollectionElement) => ELEMENT_TYPES[obj.type]?.isFilter || false);
  const propOptions = columnRemoveRels.map((ele: CollectionElement) => ele.name) || [];
  return (
    <div>
      <form className="py-4 rounded-md justify-center items-center" onSubmit={onSubmit}>
        <div>
          {showFilters
            ? formik.values.filters.map((filter: Filter, idx: number) => (
                <FilterFormRow
                  key={`filter-menu-row-${idx}`}
                  idx={idx}
                  formik={formik}
                  propOptions={propOptions}
                  columns={columns}
                  filter={filter}
                  deleteRow={deleteRow}
                />
              ))
            : null}
        </div>
      </form>
    </div>
  );
}

export default FilterForm;
