/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-cycle */
/* eslint-disable */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { allRel, loadRelData, selectRelApps } from '../../../../redux/slices/database';
import RelationModal from '../../../Modal/RelationModal';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import HopperServiceApiProvider from '../../../../services/hopperServiceApiProvider';
import FormErrorMessage from '../../../Form/FormErrorMessage';
import { checkForName } from '../../../../utils/core.utils';
import 'react-datepicker/dist/react-datepicker.css';
import { getEntityDisplayName } from '../../../../redux/slices/database.utils';
import { DEFAULT_REQUEST_BODY } from '../../../../redux/slices/collection.models';
import { notify } from '../../../../components/shared/Notification';
import { Entity } from '../../../../redux/models/auth.models';
import { CollectionElement } from '../../../../redux/models/database.model';
import RelButton from './RelButton';
import EditEntityContainer from '../../../Modal/EditEntityContainer';

interface Props {
  element: CollectionElement;
  formikValue: any;
  formikProperty: string;
  errors?: any;
  isForm: boolean;
  editable: boolean;
  width: string;
  minWidth: string;
  setFormikValue: (prop: string, entity: Entity) => void;
  submitFormik: () => void;
}

function TableRelCell({
  element,
  formikValue,
  width,
  minWidth,
  setFormikValue,
  formikProperty,
  submitFormik,
  errors,
  isForm = false,
  editable,
}: Props) {
  const dispatch = useAppDispatch();
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const rels = useSelector(allRel);
  const relCollectionId = element.collection_id || '';
  const apps = useSelector(selectRelApps);
  const baseUrl = apps[relCollectionId]?.data?.base_url;
  const relContainer = rels[element.collection_id || ''] || {};
  const path = relContainer?.value?.path || '';
  const relUrl = `${baseUrl}${path}`;
  const isRelLoaded = relContainer.dataLoaded;
  const name = getEntityDisplayName(relContainer, formikValue?.data) || checkForName(formikValue?.data);
  const description = formikValue?.data?.description;
  const displayName = name || description || (formikValue === null && '+') || (isForm ? '+' : 'no name');
  const selectedOptions = formikValue ? [formikValue] : [];
  const { displayName: tagDisplayProp } = relContainer;
  const relEntityId = formikValue?.entity_id;
  const isRel = relEntityId !== undefined;
  const handleSelect = (item: Entity) => {
    setFormikValue(formikProperty, item);
    if (!isForm) {
      submitFormik();
    }
  };
  const handleEditRel = (item: Entity) => setFormikValue(formikProperty, item);
  const handleSelectRel = async () => {
    setSelectModalOpen(true);
    if (!isRelLoaded) {
      try {
        const response = await HopperServiceApiProvider.getEntityData(relUrl, DEFAULT_REQUEST_BODY);
        const entities = response.data.data.items || [];
        dispatch(loadRelData(element.collection_id, entities));
      } catch (error) {
        if (error instanceof Error) {
          const errorMessage = `Couldn't load records. ${error.message}. Please contact support if the problem persists.`;
          notify('Error loading', errorMessage, 'error');
        }
      }
    }
  };

  const handleOpen = () => {
    if (isRel) {
      setEditModalOpen(true);
    } else {
      setSelectModalOpen(true);
    }
  };
  return (
    <div className="table-cell-row relative hover:border-2 hover:border-cell-border" style={{ width, minWidth }}>
      <RelButton
        title={displayName}
        editable={editable}
        showClose={isRel}
        showAdd={!isRel}
        onOpen={handleOpen}
        onClose={handleSelectRel}
        onAdd={handleSelectRel}
      />
      {errors && <FormErrorMessage error={errors} />}
      {selectModalOpen && editable && (
        <RelationModal
          element={element}
          relUrl={relUrl}
          handleSelect={handleSelect}
          rels={relContainer}
          open={selectModalOpen}
          setOpen={setSelectModalOpen}
          selectedEntities={selectedOptions}
          displayName={tagDisplayProp}
          isForm={false}
          multi={false}
          handleXClick={() => {}}
          submitFormik={() => {}}
        />
      )}
      {editModalOpen && relEntityId && (
        <EditEntityContainer
          entityId={relEntityId}
          collectionId={relCollectionId}
          isOpen={editModalOpen}
          onUpdate={handleEditRel}
          onClose={() => setEditModalOpen(false)}
        />
      )}
    </div>
  );
}

TableRelCell.defaultProps = {
  errors: null,
};

export default TableRelCell;
