import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import Hero from './components/Hero';
import Features from './components/Features';
import data from './data';
import Analytics from '../../utils/analytics';
import { notify } from '../../components/shared/Notification';

export default function LandingContainer() {
  const { signIn, isLoggingIn, isAuthenticated, isInitialized } = useAuth();
  const navigate = useNavigate();
  const handleSignIn = async () => {
    try {
      if (isAuthenticated && isInitialized) {
        navigate('/organizations');
      } else {
        signIn();
      }
    } catch (error) {
      Analytics.capture(error);
      if (error instanceof Error) {
        const errorMessage = error.message || 'Something went wrong';
        notify('Error logging in', errorMessage, 'error');
      }
    }
  };
  const layout = process.env.REACT_APP_HOME_PAGE_LAYOUT || 'hopper';
  const homepage = data[layout] || data.hopper;
  if (isAuthenticated && isInitialized) {
    return <Navigate to="/projects" />;
  }
  return (
    <div className="bg-gray-50">
      <Hero
        colours={homepage.colours}
        data={homepage.hero}
        isInitialized={isInitialized}
        isLoggingIn={isLoggingIn}
        handleSignIn={handleSignIn}
      />
      <main>
        <Features colours={homepage.colours} cta={homepage.cta} features={homepage.features} />
      </main>
      <footer className="bg-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-4 pt-16 pb-8 sm:px-6 lg:px-8 lg:pt-24">
          <p className="mt-8 text-center text-base text-gray-400">{homepage.footer}</p>
        </div>
      </footer>
    </div>
  );
}
