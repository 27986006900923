import { QueryOperator } from '../redux/slices/collection.models';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const formFilterOptionsArr = [
  'Equal to',
  'Greater than',
  'Less than',
  'Greater than or equal to',
  'Less than or equal to',
  'Contains this',
  'Does not Contain',
];

export const formFilterOperatorArr = [QueryOperator.Or, QueryOperator.And];

export const formFilterOperators = {
  and: QueryOperator.And,
  or: QueryOperator.Or,
};

interface ColumnTypes {
  [key: string]: { values: string[]; name: string };
}

export const columnOperators: ColumnTypes = {
  bool: { values: ['Equals'], name: 'Boolean' },
  string: { values: ['Equals', 'Contains', 'IsEmpty', 'NotEmpty'], name: 'String' },
  number: {
    values: [
      'Equals',
      'Greater Than',
      'Less Than',
      'Greater Than Or Equal To',
      'Less Than Or Equal To',
      'IsEmpty',
      'NotEmpty',
    ],
    name: 'Number',
  },
  datetime: {
    values: [
      'Equals',
      'Greater Than',
      'Less Than',
      'Greater Than Or Equal To',
      'Less Than Or Equal To',
      // 'IsEmpty',
      // 'NotEmpty',
    ],
    name: 'Date',
  },
  select: { values: ['Equals', 'IsEmpty', 'NotEmpty'], name: 'Select' },
  multi_select: { values: ['Contains', 'IsEmpty', 'NotEmpty'], name: 'Multi Select' },
  multi_string: { values: ['Contains', 'IsEmpty', 'NotEmpty'], name: 'Multi String' },
  rel: { values: ['Equals', 'IsEmpty', 'NotEmpty'], name: 'One to Many' },
  multi_rel: { values: ['Equals', 'IsEmpty', 'NotEmpty'], name: 'One to Many' },
  attachment: { values: ['IsEmpty', 'NotEmpty'], name: 'Attachment' },
};

export const compareOpShorts: any = {
  Equals: 'eq',
  Contains: 'in', // for string only 'regex'
  'Greater Than': 'gt',
  'Less Than': 'lt',
  'Greater Than Or Equal To': 'gte',
  'Less Than Or Equal To': 'lte',
  IsEmpty: 'eq',
  NotEmpty: 'ne',
};
