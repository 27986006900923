/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { Report } from '../../../redux/models/report.models';
import classNames from '../../../utils/tailwind';
import { getReportItems, getReportTotal, loadReport } from '../../../redux/slices/reports';
import { format } from '../../../utils/formatter.numbers';
import Spinner from '../../../components/shared/Spinner';

// const statuses = {
//   Paid: 'text-green-700 bg-green-50 ring-green-600/20',
//   Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
//   Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
// };

interface Props {
  report: Report;
}

export default function ReportView({ report }: Props) {
  const { orgCode } = useParams();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const title = report.data.config.format ? format(total, report.data.config.format, 2) : `${total}`;
  const reportName = report.data.name || '';
  const logoUrl = report?.data.app?.data.logo_url || '';

  useEffect(() => {
    const load = async (rep: Report) => {
      try {
        setIsLoading(true);
        const response = await loadReport(rep);
        if (response.status === 200) {
          const { data } = response;
          const { collection, config } = rep.data;
          const nameElement = collection.data.elements.find((el) => el.property === config.nameElementProp);
          const valueElement = collection.data.elements.find((el) => el.property === config.valueElementProp);
          const tot = getReportTotal(config, data.data);
          const tims = getReportItems(config, data.data, nameElement, valueElement);
          const timData = tims.length < 4 ? tims : tims.slice(0, 3);
          setTotal(tot);
          setItems(timData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    load(report);
  }, [report]);
  return (
    <li className="overflow-hidden rounded-xl border border-gray-200">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <img
          src={logoUrl}
          alt={reportName}
          className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
        />
        <div>
          <div className="text-sm font-medium leading-6 text-gray-900">{title}</div>
          <div className="text-xs font-xs leading-6 text-gray-600">{`${reportName} • ${
            report.data.date_range?.range || ''
          }`}</div>
        </div>
        <Menu as="div" className="relative ml-auto">
          <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/${orgCode}/collections/${report.data.collection.entity_id}`}
                    className={classNames(
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    )}
                  >
                    View<span className="sr-only">, {reportName}</span>
                  </Link>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        {isLoading && (
          <div className="w-full h-24 flex justify-center items-center">
            <Spinner size="small" />
          </div>
        )}
        {!isLoading &&
          items.map((item, idx) => {
            return (
              <div key={`item-name-${idx}`} className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">{item.name || ''}</dt>
                <dd className="text-gray-700">
                  <time>{format(item.total || 0, report.data.config.format || 'hrs', 2)}</time>
                </dd>
              </div>
            );
          })}
      </dl>
    </li>
  );
}
