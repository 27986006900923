import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { VERSION_NO } from './release_notes';

const stage = process.env.REACT_APP_APP_STAGE || 'production';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: stage,
    integrations: [new BrowserTracing()],
    release: `hopper-front-end@${VERSION_NO}`,
    tracesSampleRate: 0.5,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
