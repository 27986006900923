class HopperServiceConfigProvider {
  static collectionById(collectionId: string) {
    return `/collection/${collectionId}`;
  }

  static getUserOrganizations() {
    return '/user-organizations';
  }

  static getUserData() {
    return '/user/data';
  }

  static findFeatureFlags() {
    return '/feature-flag/find';
  }

  static getUsers(organizationId: string) {
    return `/user?organization_id=${organizationId}`;
  }

  static user() {
    return '/user';
  }

  static userById(userId: string) {
    return `/user/${userId}`;
  }

  static userDetailsById(userId: string, orgId: string) {
    return `/user/${userId}?organization_id=${orgId}`;
  }

  static userGroups(userId: string) {
    return `/user/${userId}/groups`;
  }

  static userPermissions(userId: string) {
    return `/user/${userId}/permissions`;
  }

  static getGroups(organizationCode: string) {
    return `/group?organization_code=${organizationCode}`;
  }

  static groupById(groupId: string) {
    return `/group/${groupId}`;
  }

  static createGroup() {
    return '/group';
  }

  static groupPermissions(groupId: string) {
    return `/group/${groupId}/permissions`;
  }

  static getOrgPermissions(organizationId: string) {
    return `/organization/${organizationId}/permissions`;
  }

  static getOrganisation(organisationId: string) {
    return `/organization/${organisationId}`;
  }

  static getOrganisationAutomations(organisationId: string, isEditor: boolean) {
    const url = `/automation?organization_id=${organisationId}`;
    return isEditor ? `/v2${url}` : url;
  }

  static automationDetails(automationId: string) {
    return `/v2/automation/${automationId}`;
  }

  static automationStep(automationId: string, stepId: string) {
    return `/v2/automation/${automationId}/step/${stepId}`;
  }

  static createAutomationStep(automationId: string) {
    return `/v2/automation/${automationId}/step`;
  }

  static getTriggerApps() {
    return `/v2/automation/trigger/apps`;
  }

  static getActionApps() {
    return `/v2/automation/action/apps`;
  }

  static getTokens(automationId: string) {
    return `/v2/automation/${automationId}/tokens`;
  }

  static buildForm(formId: string) {
    return `/dynamic-form/build/${formId}`;
  }

  static getAutomationTrigger(automationId: string) {
    return `/v2/automation/${automationId}/trigger`;
  }

  static getAutomationStep(automationId: string, stepId: string) {
    return `/v2/automation/${automationId}/step/${stepId}`;
  }

  static postAutomation() {
    return '/v2/automation';
  }

  static getOrganisationIntegrations(organisationId: string) {
    return `/integration?organisation_id=${organisationId}`;
  }

  static getAutomationStepData() {
    return 'automation-step/find/entities';
  }

  static automationStepPath() {
    return '/automation-step';
  }

  static getPermissions(orgCode: string) {
    return `/group?organization_code=${orgCode}`;
  }

  static getReportById(reportId: string) {
    return `/report/${reportId}`;
  }

  static findReports() {
    return '/report/find';
  }
}

export default HopperServiceConfigProvider;
