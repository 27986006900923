import { CollectionElement } from '../redux/models/database.model';
import { Entity } from '../redux/models/auth.models';

const sum = (item: CollectionElement, rows: Entity[]) => {
  const { property } = item;
  if (!property) {
    return 0;
  }
  const total = rows.reduce((acc: number, curr: Entity) => {
    if (curr && curr.data && curr.data[property]) {
      return parseFloat(curr.data[property]) + acc;
    }
    return acc;
  }, 0);
  return total;
};

export default {
  sum,
};
