import { Entity } from '../../redux/models/auth.models';
import { Organisation } from '../../redux/models/database.model';
import BreadcrumbModel from '../../components/breadcrumbs/breadcrumb.models';
import { getCollection, getDefaultGroupBy, getEntityValue } from '../../redux/slices/database.utils';

export const buildEntityBreadcrumb = (org: Organisation, path: string, entity: Entity) => {
  let name = 'No name set';
  const collection = getCollection(org, path);
  const elements = collection?.elements || [];
  const element = getDefaultGroupBy(elements);
  if (element) name = getEntityValue(entity, element);
  const orgCode = org.data.organization_code;
  const breadcrumb: BreadcrumbModel = {
    name,
    path: `/${orgCode}/collections/${path}/${entity.entity_id}`,
    current: true,
  };
  return breadcrumb;
};

export const buildBreadcrumbs = (org: Organisation | null, path: string, entity: Entity | null) => {
  const pages: BreadcrumbModel[] = [];
  if (!org) return pages;
  const collection = getCollection(org, path);
  const orgCode = org.data.organization_code;
  if (org && collection) {
    pages.push({
      name: collection.name,
      path: `/${orgCode}/collections/${path}`,
      current: false,
    });
  }
  if (entity) {
    const breadcrumb = buildEntityBreadcrumb(org, path, entity);
    pages.push(breadcrumb);
  }
  return pages;
};
