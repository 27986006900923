/* eslint-disable no-console */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/20/solid';
import { Automation } from '../../../../redux/slices/automation.models';
import DateService from '../../../../utils/dateService';
import Toggle from './Toggle';
import AutomationSidebarHeader from './AutomationSidebarHeader';
import { deleteAutomation, updateAutomation } from '../../../../redux/slices/automation.details';
import { notify } from '../../../../components/shared/Notification';
import Spinner from '../../../../components/shared/Spinner';
import { Group } from '../../../../redux/models/auth.models';
import AddGroupInput from '../../../../components/permissions/AddGroupInput';
import { EntityContainer } from '../../../../redux/slices/core.models';

const DESCRIPTION_PLACEHOLDER = 'Add a description to this automation.';

interface Props {
  automation: Automation;
  groupsList: Group[];
}

export default function AutomationSidebar({ automation, groupsList }: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const roles = automation.roles || [];
  const defaultGroups = groupsList.filter((gr) => roles.includes(gr.id));
  const [groups, setGroups] = useState<Group[]>(defaultGroups);
  const [isSaving, setIsSaving] = useState(false);
  const [enabled, setEnabled] = useState(automation.data.is_on || false);
  const [name, setName] = useState(automation.data.name || '');
  const [description, setDescription] = useState(automation.data.description);
  const [editingDescription, setEditingDescription] = useState(false);
  const groupIds = groups.map((group) => group.id);
  const groupStore: EntityContainer<Group> = groupsList.reduce((store, gr) => {
    if (groupIds.includes(gr.id)) return store;
    return {
      ...store,
      [gr.id]: gr,
    };
  }, {});
  const handleRemoveGroup = (removedGroup: Group) => {
    const updates = groups.filter((gr) => gr.id !== removedGroup.id);
    setGroups(updates);
  };
  const handleClose = () => navigate(`/${orgCode}/automations`);
  const handleCancel = () => handleClose();
  const handleSave = async () => {
    const updates = {
      ...automation,
      roles: groups.map((group) => group.id),
      data: {
        ...automation.data,
        name,
        is_on: enabled,
        description,
      },
    };
    try {
      setIsSaving(true);
      const response = await updateAutomation(updates);
      if (response.status === 200) {
        handleClose();
      } else {
        notify("Couldn't Save Automation", `${response.data.description}`, 'error');
      }
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = `Couldn't save ${name}. ${error.message}. Please contact support if the problem persists.`;
        notify('', errorMessage, 'info');
        throw error;
      }
    } finally {
      setIsSaving(false);
    }
  };
  const handleDelete = async () => {
    try {
      setIsSaving(true);
      const response = await deleteAutomation(automation.entity_id);
      if (response.status === 200) handleClose();
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = `Couldn't get Automations. ${error.message}. Please contact support if the problem persists.`;
        notify('', errorMessage, 'info');
        throw error;
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <aside className="hidden w-96 h-full overflow-y-auto border-l border-gray-200 bg-white p-8 lg:block">
      <div className="space-y-6 pb-16">
        <AutomationSidebarHeader name={name} handleChange={(val) => setName(val)} handleDelete={handleDelete} />
        <div>
          <h3 className="font-medium text-gray-900">Information</h3>
          <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Published</dt>
              <Toggle enabled={enabled} setEnabled={setEnabled} />
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Created At</dt>
              <dd className="whitespace-nowrap text-gray-900">
                {DateService.getDDMoYYYYFormat(automation.created_at || '')}
              </dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Last Updated</dt>
              <dd className="whitespace-nowrap text-gray-900">
                {automation.modified_at ? DateService.getDDMoYYYYFormat(automation.modified_at) : ''}
              </dd>
            </div>
          </dl>
        </div>
        <div>
          <h3 className="font-medium text-gray-900">Description</h3>
          <div className="mt-2 flex items-center justify-between">
            {!editingDescription && (
              <p className="text-sm italic text-gray-500">{description || DESCRIPTION_PLACEHOLDER}</p>
            )}
            {!editingDescription && (
              <button
                type="button"
                onClick={() => setEditingDescription(true)}
                className="relative flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <span className="absolute -inset-1.5" />
                <PencilIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Add description</span>
              </button>
            )}
            {editingDescription && (
              <textarea
                rows={4}
                name="description"
                id="description"
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={description}
                placeholder={DESCRIPTION_PLACEHOLDER}
                onChange={(e) => setDescription(e.target.value)}
              />
            )}
          </div>
          {editingDescription && (
            <button
              type="button"
              onClick={() => setEditingDescription(false)}
              className="relative flex mt-1 p-2 items-center justify-center rounded-md bg-white text-blue-500 text-xs font-medium hover:bg-gray-100 hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <span>Save</span>
            </button>
          )}
        </div>
        <div>
          <h3 className="font-medium text-gray-900">Shared with</h3>
          <ul className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
            {groups.map((group) => (
              <li key={group.id} className="flex items-center justify-between py-3">
                <div className="flex items-center">
                  {/* <img src={person.imageUrl} alt="" className="h-8 w-8 rounded-full" /> */}
                  <p className="ml-4 text-sm font-medium text-gray-900">{group.name}</p>
                </div>
                <button
                  type="button"
                  onClick={() => handleRemoveGroup(group)}
                  className="ml-6 rounded-md bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Remove<span className="sr-only"> {group.name}</span>
                </button>
              </li>
            ))}
            {/* <li className="flex items-center justify-between py-2">
              <button
                type="button"
                className="group -ml-1 flex items-center rounded-md bg-white p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                </span>
                <span className="ml-4 text-sm font-medium text-blue-600 group-hover:text-blue-500">Share</span>
              </button>
            </li> */}
            <li>
              <AddGroupInput
                title=""
                store={groupStore}
                isImage={false}
                canEdit
                getName={(group) => group?.name || ''}
                getId={(group) => group?.id || ''}
                getImg={() => ''}
                getDetails={(group) => group.description}
                onAdd={(group) => setGroups([...groups, group])}
              />
            </li>
          </ul>
        </div>
        <div className="flex gap-x-3">
          <button
            type="button"
            onClick={handleCancel}
            className="flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="flex-1 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {!isSaving && 'Save'}
            {isSaving && <Spinner size="small" />}
          </button>
        </div>
      </div>
    </aside>
  );
}
