/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { CollectionElement, TableData } from '../../redux/models/database.model';
import classNames from '../../utils/tailwind';
import CalendarSiteListItem from './CalendarSideListItem';
import { getElementType, getFileElement, getImgEntity, getPrimaryElement, getRows } from '../grid/gridview.utils';
import SelectElement from './SelectElement';
import { ELEMENT_TYPE_ID } from '../../redux/slices/element.models';
import { resetPage, selectCollectionUrl, selectRequest } from '../../redux/slices/database';
import { CollectionFilter } from '../../redux/slices/collection.models';
import Spinner from '../shared/Spinner';
import { notify } from '../shared/Notification';
import useAppDispatch from '../../hooks/useAppDispatch';
import EntityApiProvider from '../../services/EntityApiProvider';

interface DayView {
  isToday: boolean;
  isSelected: boolean;
  isCurrentMonth: boolean;
  day: Moment;
}

const createView = (date: Moment, currentMonth: Moment, selected: Moment): DayView => {
  const today = moment();
  return {
    isToday: date.isSame(today, 'day'),
    isSelected: date.isSame(selected, 'day'),
    isCurrentMonth: date.isSame(currentMonth, 'month'),
    day: date,
  };
};

const createDateFilter = (date: Moment, element: CollectionElement) => {
  const field = {
    id: element.name,
    name: `data.${element.property}`,
    type: element.type,
  };
  const start: CollectionFilter = {
    field,
    op: 'gte',
    value: date.startOf('day').toISOString(),
  };
  const end: CollectionFilter = {
    field,
    op: 'lte',
    value: date.endOf('day').toISOString(),
  };
  return [start, end];
};

const createFilters = (filters: CollectionFilter[], date: Moment, element: CollectionElement) => {
  const norms = filters.filter((filter) => filter.field.type !== ELEMENT_TYPE_ID.datetime);
  return [...norms, ...createDateFilter(date, element)];
};

interface Props {
  displayData: TableData;
  search: string;
  onEdit: () => void;
}

export default function CalendarSideList({ displayData, search, onEdit }: Props) {
  const dispatch = useAppDispatch();
  const entityData = displayData.rows || [];
  const elements = displayData.columns || [];
  const dateElements = elements.filter((el) => el.type === ELEMENT_TYPE_ID.datetime);
  const entities = getRows(search, entityData);
  const primary = getPrimaryElement(elements, 'name');
  const start = getElementType(elements, ELEMENT_TYPE_ID.datetime);
  const filters = useSelector(selectRequest);
  const collectionUrl = useSelector(selectCollectionUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const initDateEl = dateElements.length > 0 ? dateElements[0] : null;
  const [queryDate, setQueryDateElement] = useState(initDateEl);
  const [detailElement, setDetailElement] = useState<CollectionElement>(primary);
  const [startDateElement, setStartDateElement] = useState<CollectionElement | undefined | null>(start);
  const [endDateElement, setEndDateElement] = useState<CollectionElement | null | undefined>(null);
  const [isImg, setIsImg] = useState(false);
  const startWeekDay = currentMonth.clone().startOf('month').day();
  const endWeekDay = currentMonth.clone().endOf('month').day();

  const daysInMonth = currentMonth.daysInMonth();

  const prevMonthDays: DayView[] = [];
  for (let i = startWeekDay - 1; i >= 0; i--) {
    const prevDate = currentMonth
      .clone()
      .startOf('month')
      .subtract(i + 1, 'days');
    const prevView = createView(prevDate, currentMonth, selectedDate);
    prevMonthDays.push(prevView);
  }

  const nextMonthDays: DayView[] = [];
  for (let i = 1; i <= 6 - endWeekDay; i++) {
    const nextDate = currentMonth.clone().endOf('month').add(i, 'days');
    const nextView = createView(nextDate, currentMonth, selectedDate);
    nextMonthDays.push(nextView);
  }

  const days: DayView[] = [
    ...prevMonthDays,
    ...Array.from({ length: daysInMonth }).map((_, i) => {
      const currDate = currentMonth.clone().date(i + 1);
      return createView(currDate, currentMonth, selectedDate);
    }),
    ...nextMonthDays,
  ];

  const handleDateChange = async (m: Moment) => {
    setSelectedDate(m);
    try {
      setIsLoading(true);
      const req = {
        ...filters,
        filters: queryDate ? createFilters(filters.filters, m, queryDate) : filters.filters,
      };
      const response = await EntityApiProvider.getEntityData(collectionUrl, req);
      const data = EntityApiProvider.parseResponse(response);
      const totalCount = data.total_count;
      dispatch(resetPage({ data, filters: req, totalCount }));
    } catch (error) {
      if (error instanceof Error) {
        notify(
          'Unable to apply filters',
          `Error getting records from the database. ${error.message}. Please try resetting the filters`,
          'error'
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleQueryElementChange = async (element: CollectionElement | null | undefined) => {
    if (element) {
      await setQueryDateElement(element);
      handleDateChange(selectedDate);
    }
  };
  useEffect(() => {
    const initLoad = async () => {
      try {
        setIsLoading(true);
        const req = {
          ...filters,
          filters: queryDate ? createFilters(filters.filters, selectedDate, queryDate) : filters.filters,
        };
        const response = await EntityApiProvider.getEntityData(collectionUrl, req);
        const data = EntityApiProvider.parseResponse(response);
        const totalCount = data.total_count;
        dispatch(resetPage({ data, filters: req, totalCount }));
      } catch (error) {
        if (error instanceof Error) {
          notify(
            'Unable to apply filters',
            `Error getting records from the database. ${error.message}. Please try resetting the filters`,
            'error'
          );
        }
      } finally {
        setIsLoading(false);
        setIsInitialised(true);
      }
    };
    const elementData = displayData.columns || [];
    const fileElement = getFileElement(elementData);
    if (fileElement) {
      const imgs = getImgEntity(entities, fileElement);
      if (imgs) setIsImg(true);
    }
    if (!isInitialised) initLoad();
  }, [displayData, entities, isInitialised, collectionUrl, dispatch, filters, selectedDate, queryDate]);
  return (
    <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
      <div className="md:pr-14">
        <div className="flex items-center">
          <h2 className="flex-auto text-sm font-semibold text-gray-900">{currentMonth.format('MMMM YYYY')}</h2>
          <button
            type="button"
            onClick={() => setCurrentMonth(currentMonth.clone().subtract(1, 'month'))}
            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            onClick={() => setCurrentMonth(currentMonth.clone().add(1, 'month'))}
            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div className="mt-2 grid grid-cols-7 text-sm">
          {days.map((day, dayIdx) => (
            <div key={day.day.toISOString()} className={classNames(dayIdx > 6 && 'border-t border-gray-200', 'py-2')}>
              <button
                type="button"
                onClick={() => handleDateChange(day.day)}
                className={classNames(
                  day.isSelected && 'text-white',
                  !day.isSelected && day.isToday && 'text-blue-600',
                  !day.isSelected && !day.isToday && day.isCurrentMonth && 'text-gray-900',
                  !day.isSelected && !day.isToday && !day.isCurrentMonth && 'text-gray-400',
                  day.isSelected && day.isToday && 'bg-blue-600',
                  day.isSelected && !day.isToday && 'bg-gray-900',
                  !day.isSelected && 'hover:bg-gray-200',
                  (day.isSelected || day.isToday) && 'font-semibold',
                  'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                )}
              >
                <time dateTime={day.day.toISOString()}>{day.day.format('D')}</time>
              </button>
            </div>
          ))}
        </div>
      </div>
      <section className="mt-12 md:mt-0 md:pl-14">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Schedule for <time dateTime={selectedDate.format('YYYY-MM-DD')}>{selectedDate.format('MMMM D, YYYY')}</time>
        </h2>
        <div className="md:grid md:grid-cols-4 pt-2">
          <div className="px-1 text-xs text-gray-600">
            <p>Date Filter:</p>
            <SelectElement
              name="Date Fileter"
              value={queryDate}
              options={dateElements}
              onChange={(el) => handleQueryElementChange(el)}
            />
          </div>
          <div className="px-1 text-xs text-gray-600">
            <p>Title:</p>
            <SelectElement
              name="Detail"
              value={detailElement}
              options={elements}
              onChange={(el) => {
                if (el) setDetailElement(el);
              }}
            />
          </div>
          <div className="px-1 text-xs text-gray-600">
            <p>Subtitle:</p>
            <SelectElement
              name="Start Time"
              value={startDateElement}
              options={elements}
              onChange={(el) => {
                if (el) setStartDateElement(el);
              }}
            />
          </div>
          <div className="px-1 text-xs text-gray-600">
            <p>Detail:</p>
            <SelectElement
              name="Finish Time"
              value={endDateElement}
              options={elements}
              allowEmpty
              onChange={(el) => setEndDateElement(el)}
            />
          </div>
        </div>
        {isLoading && (
          <div className="flex w-full h-full items-center justify-center">
            <Spinner />
          </div>
        )}
        {!isLoading && (
          <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
            {entities.map((entity) => (
              <CalendarSiteListItem
                key={entity.entity_id}
                entity={entity}
                elements={elements}
                isImage={isImg}
                primary={detailElement}
                start={startDateElement}
                end={endDateElement}
                onEdit={onEdit}
              />
            ))}
          </ol>
        )}
      </section>
    </div>
  );
}
