import { EntityContainer } from './core.models';
import { User, UserDirectory } from '../models/auth.models';

export const createDirectory = (users: User[]): UserDirectory[] => {
  const container = users.reduce((dir: EntityContainer<User[]>, user: User) => {
    const name = user.data.name || '';
    const firstLetter = name.charAt(0);
    if (dir[firstLetter]) {
      return {
        ...dir,
        [firstLetter]: [...dir[firstLetter], user],
      };
    }
    return {
      ...dir,
      [firstLetter]: [user],
    };
  }, {});
  return Object.keys(container)
    .map((title: string) => ({
      letter: title,
      data: [...container[title]].sort((a: User, b: User) => {
        const aName = a.data.name || '';
        const bName = b.data.name || '';
        return aName.localeCompare(bName);
      }),
    }))
    .sort((a: UserDirectory, b: UserDirectory) => {
      return a.letter.localeCompare(b.letter);
    });
};

export const createUserContainer = () => {};
