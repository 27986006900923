/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props {
  name: string;
  value: string;
  type?: string;
  placeholder?: string;
  onChange: (value: any) => void;
}

function FormTextInput({ name, value, type, placeholder, onChange }: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = type === 'number' ? parseFloat(e.target.value) : e.target.value;
    onChange(val);
  };
  return (
    <input
      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6r"
      type={type}
      name={name}
      placeholder={placeholder}
      defaultValue={value || ''}
      onChange={handleChange}
    />
  );
}

FormTextInput.defaultProps = {
  placeholder: '',
  type: 'text',
};

export default FormTextInput;
