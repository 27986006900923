/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/20/solid';
import { DropdownSelect } from '../../components/shared/DropdownSelect';
import { formFilterOperatorArr, columnOperators } from '../../constants/formValue.contants';
import { CollectionElement } from '../../redux/models/database.model';
import FilterFormValueInput from './FilterFormValueInput';
import FormErrorMessage from '../Form/FormErrorMessage';
import { Filter } from './FilterButton.modals';

const propertyPlaceholder = 'Property';
const opPlaceholder = 'Condition';

interface Props {
  idx: number;
  columns: CollectionElement[];
  formik: any;
  filter: Filter;
  propOptions: string[];
  deleteRow: (idx: number) => void;
}

export default function FilterFormRow({ idx, filter, propOptions, formik, columns, deleteRow }: Props) {
  const [name, setName] = useState<string | null>(filter?.field?.id || null);
  const { errors } = formik;
  const element = columns.find((ele: CollectionElement) => ele.name === filter.field?.id);
  const options = element?.options || [];
  const type = element?.type || '';
  const isEmpty = filter.op?.includes('Empty');
  return (
    <div className="flex justify-between items-center pb-4">
      {idx < 1 ? (
        <p>Where</p>
      ) : (
        <DropdownSelect
          dropdownOptions={formFilterOperatorArr}
          setSelected={formik.setValues}
          idx={idx}
          errors={formik.errors.operator}
          name="operator"
          dataCy={`collection_header_filter_form_operator_${idx}`}
          displayName={formik.values.operator}
          typeSelector={false}
          placeholder={opPlaceholder}
          setValue={() => {}}
          columns={columns}
        />
      )}
      <DropdownSelect
        dropdownOptions={propOptions}
        setSelected={formik.setValues}
        idx={idx}
        errors={errors?.filters?.length > 0 ? errors.filters[idx]?.field : undefined}
        name="field"
        dataCy={`collection_header_filter_form_field_${idx}`}
        displayName={name}
        typeSelector
        placeholder={propertyPlaceholder}
        setValue={setName}
        columns={columns}
      />
      {name && (
        <DropdownSelect
          dropdownOptions={type ? columnOperators[type]?.values : []}
          setSelected={formik.setValues}
          errors={errors?.filters?.length > 0 ? errors.filters[idx]?.op : undefined}
          idx={idx}
          name="op"
          dataCy={`collection_header_filter_form_condition_${idx}`}
          displayName={filter?.op}
          placeholder={opPlaceholder}
          typeSelector
          setValue={() => {}}
          columns={columns}
        />
      )}
      {!isEmpty ? (
        <FilterFormValueInput
          element={element}
          filter={filter}
          options={options}
          formik={formik}
          idx={idx}
          errors={errors}
          columns={columns}
          dataCy={`collection_header_filter_form_value_${idx}`}
        />
      ) : null}
      {errors?.filters?.length > 0 && errors.filters[idx]?.value && (
        <FormErrorMessage error={errors.filters[idx].value || 'errror'} />
      )}
      <TrashIcon
        data-cy={`collection_header_filter_form_delete_condition_btn_${idx}`}
        onClick={() => deleteRow(idx)}
        className="text-gray-500 ml-2 mr-1 h-4 w-4 group-hover:text-gray-500"
      />
    </div>
  );
}
