/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';
import { initialPageLoad, loadEntity, selectEntityTitle, setCollection } from '../redux/slices/database';
import useAppDispatch from '../hooks/useAppDispatch';
import Table from '../tables';
import Navbar from '../components/navbar/Navbar';
import { getLocalOrganisation, getUserOrgByCode, persistOrganisation } from '../redux/slices/organisation';
import { Organisation } from '../redux/models/database.model';
import { loadGroups } from '../redux/slices/groups';
import EntityApiProvider from '../services/EntityApiProvider';
import { getBaseUrl, getCollection, getEndpoint } from '../redux/slices/database.utils';

function Dashboard() {
  const dispatch: any = useAppDispatch();
  const { collectionPath, orgCode }: any = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const name = useSelector(selectEntityTitle);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const org: Organisation = getLocalOrganisation();

  useEffect(() => {
    if (orgCode) {
      dispatch(loadGroups(orgCode));
    }
  }, [orgCode, dispatch]);

  useEffect(() => {
    const loadOrg = async (code: string) => {
      const fetchedOrg = await getUserOrgByCode(code);
      if (fetchedOrg) {
        await dispatch(persistOrganisation(fetchedOrg));
        await dispatch(initialPageLoad());
        const col = getCollection(fetchedOrg, collectionPath);
        if (col) {
          const filters = EntityApiProvider.createFilters(searchParams, col.elements);
          dispatch(setCollection(col));
          /// update app base url here
          const baseUrl = getBaseUrl(fetchedOrg, col);
          if (baseUrl) {
            const endpoint = getEndpoint(baseUrl, col);
            dispatch(loadEntity(endpoint, col, fetchedOrg, filters));
          }
        }
      } else {
        navigate('/organisations');
      }
    };
    if (!org) {
      loadOrg(orgCode);
    } else {
      const col = getCollection(org, collectionPath);
      if (col) {
        const baseUrl = getBaseUrl(org, col);
        const shouldReload = name !== col.name;
        if (baseUrl && shouldReload) {
          dispatch(setCollection(col));
          const filters = EntityApiProvider.createFilters(searchParams, col.elements);
          const endpoint = getEndpoint(baseUrl, col);
          dispatch(loadEntity(endpoint, col, org, filters));
        }
      }
    }
  }, [org, orgCode, collectionPath, dispatch, searchParams, name, navigate]);

  return (
    <div className="min-h-full relative">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1">
          <Table />
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
