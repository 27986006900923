/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';

interface Props {
  name: string;
  values: string[];
  placeholder?: string;
  onChange: (value: any) => void;
}

function MultiStringInput({ name, values, placeholder, onChange }: Props) {
  const data = values || [];
  const [newItem, setNewItem] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewItem(e.target.value);
  };
  const handleSave = () => {
    onChange([...data, newItem]);
    setNewItem('');
  };
  const handleDelete = (index: number) => {
    const updates = [...data] || [];
    if (index !== -1) {
      onChange([...updates.slice(0, index), ...updates.slice(index + 1)]);
    }
  };
  return (
    <div>
      {data.map((value, idx) => (
        <div key={`${value}-${idx}`} className="flex">
          <p>{value}</p>
          <button
            type="button"
            onClick={() => handleDelete(idx)}
            className="ml-2 rounded-md bg-white hover:bg-red-500 hover:text-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <TrashIcon className="h-4 w-4" />
          </button>
        </div>
      ))}
      <div className="flex">
        <input
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6r"
          type="text"
          name={name}
          placeholder={placeholder}
          value={newItem || ''}
          onChange={handleChange}
        />
        <button
          type="button"
          onClick={handleSave}
          className="ml-2 rounded-md bg-white hover:text-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-500"
        >
          <PlusIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
}

MultiStringInput.defaultProps = {
  placeholder: '',
};

export default MultiStringInput;
