import React from 'react';
import { Group, GroupDirectory } from '../../../redux/models/auth.models';
import { getSearchedGroups } from './directory.utils';
import DirectoryList from './DirectoryList';
import DirectoryListItem from './DirectoryListItem';

interface Props {
  directory: GroupDirectory[];
  searchText: string;
  isLoaded: boolean;
  setSelected: React.Dispatch<React.SetStateAction<Group | null>>;
}

export default function GroupDirectoryList({ directory, searchText, setSelected, isLoaded }: Props) {
  if (!isLoaded) {
    return null;
  }
  return (
    <DirectoryList
      directory={directory}
      searchText={searchText}
      searchResults={getSearchedGroups}
      keyExtractor={({ id }) => id}
      renderListItem={(group) => (
        <DirectoryListItem title={group.name} details={group.description} setSelected={setSelected} data={group} />
      )}
    />
  );
}
