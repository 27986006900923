/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import DirectoryItemInput from '../DirectoryItemInput';

interface FormProps {
  name: string;
  description: string;
  handleChange: (prop: string, value: string) => void;
  onSubmit: () => void;
}

export default function AddGroupForm({ name, description, handleChange, onSubmit }: FormProps) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <form onSubmit={handleSubmit} className="my-12">
      <DirectoryItemInput
        name="Name"
        type="text"
        placeholder="Group name"
        isValid={name.length > 0}
        value={name}
        prop="name"
        errorMessage="Must include a name"
        handleChange={handleChange}
      />
      <DirectoryItemInput
        name="Description"
        type="text"
        placeholder="Group description"
        isValid={description.length > 0}
        value={description}
        prop="description"
        errorMessage="Must include a description"
        handleChange={handleChange}
      />
    </form>
  );
}
