/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ListItem from './ListItem';

export default function IntegrationList({ integrations }: any) {
  return (
    <div className="overflow-hidden pt-12 sm:rounded-md">
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {integrations.map((integration: any) => (
          <ListItem key={integration.entity_id} integration={integration} />
        ))}
      </ul>
    </div>
  );
}
