/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import React from 'react';
import Loader from './Loader';
import { App, AppAction, AppTrigger } from '../../../../redux/models/app.models';

interface Props {
  children: React.ReactNode;
  app: App | null | undefined;
  trigger?: AppTrigger | null;
  action?: AppAction | null;
  showTrigger?: boolean;
  apps: App[];
  isLoadingApps: boolean;
  handleSelectApp: (app: App | undefined) => void;
  handleSelectTrigger?: (trigger: AppTrigger | undefined) => void;
  handleSelectAction?: (trigger: AppAction | undefined) => void;
}

function AutomationFormHeader({
  apps,
  app,
  trigger,
  action,
  showTrigger,
  children,
  isLoadingApps,
  handleSelectApp,
  handleSelectTrigger,
  handleSelectAction,
}: Props) {
  const triggers = app?.data.triggers || [];
  const actions = app?.data.actions || [];
  const onSelectApp = (id: string) => {
    const selected = apps.find((ap) => ap.entity_id === id);
    handleSelectApp(selected);
  };

  const onSelectTrigger = (id: string) => {
    if (!handleSelectTrigger) return;
    const selected = triggers.find((ap) => ap.trigger_id === id);
    handleSelectTrigger(selected);
  };

  const onSelectAction = (id: string) => {
    if (!handleSelectAction) return;
    const selected = actions.find((ap) => ap.action_id === id);
    handleSelectAction(selected);
  };

  return (
    <div>
      {isLoadingApps && <Loader />}
      {!isLoadingApps && (
        <div>
          <h6 className="font-medium text-gray-800">App</h6>
          <select
            name="App"
            value={app?.entity_id || ''}
            onChange={(e) => onSelectApp(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value="">Select</option>
            {apps.map((item) => (
              <option key={item.entity_id || ''} value={item.entity_id || ''}>
                {item.data.name || ''}
              </option>
            ))}
          </select>
          {app && showTrigger && (
            <div>
              <h6 className="font-medium text-gray-800 mt-4">Trigger</h6>
              <select
                name="Trigger"
                value={trigger?.trigger_id || ''}
                onChange={(e) => onSelectTrigger(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select</option>
                {triggers.map((item, idx) => (
                  <option key={`${item.trigger_id || ''}-${idx}`} value={item.trigger_id || ''}>
                    {item.name || ''}
                  </option>
                ))}
              </select>
            </div>
          )}
          {app && !showTrigger && (
            <div>
              <h6 className="font-medium text-gray-800 mt-4">Action</h6>
              <select
                name="Action"
                value={action?.action_id}
                onChange={(e) => onSelectAction(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select</option>
                {actions.map((item, idx) => (
                  <option key={`${item.action_id || ''}-${idx}`} value={item.action_id || ''}>
                    {item.name || ''}
                  </option>
                ))}
              </select>
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  );
}

AutomationFormHeader.defaultProps = {
  showTrigger: false,
  trigger: undefined,
  handleSelectTrigger: undefined,
  action: undefined,
  handleSelectAction: undefined,
};

export default AutomationFormHeader;
