import React from 'react';
import CalendarSideList from './CalendarSideList';
import { TableData } from '../../redux/models/database.model';

interface Props {
  displayData: TableData;
  search: string;
  onEdit: () => void;
}

function CalendarView({ displayData, search, onEdit }: Props) {
  return (
    <div className="px-8 py-4">
      <CalendarSideList displayData={displayData} search={search} onEdit={onEdit} />
    </div>
  );
}

export default CalendarView;
