import React from 'react';

function FormErrorMessage({ error }: { error: string }) {
  return (
    <div className="ml-3 text-red-500 text-xs font-medium w-full">
      <p>{error}</p>
    </div>
  );
}

export default FormErrorMessage;
