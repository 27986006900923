/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useAppDispatch from '../hooks/useAppDispatch';
import { loadCurrentUser, selectPermissions } from '../redux/slices/auth';
import useAuth from '../hooks/useAuth';

export const UserContext = React.createContext([]);

export function UserProvider({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const dispatch = useAppDispatch();
  const permissions = useSelector(selectPermissions);
  useEffect(() => {
    if (isAuthenticated && isInitialized) dispatch(loadCurrentUser());
  }, [dispatch, isAuthenticated, isInitialized]);
  const providerValue = useMemo(() => [...permissions], [permissions]);
  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
}
