/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { addNewEntity, deleteSingleDisplayEntity, setSelectedEntity } from '../../redux/slices/database';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import useAppDispatch from '../../hooks/useAppDispatch';
import SingleInfoModalElement from './SingleInfoModalElement';
import { CollectionElement } from '../../redux/models/database.model';
import { Entity, Group } from '../../redux/models/auth.models';
import { notify } from '../../components/shared/Notification';
import { selectGroupsList } from '../../redux/slices/groups';
import EntityModal from './EntityModal';
import Spinner from '../../components/shared/Spinner';
import EntityApiProvider from '../../services/EntityApiProvider';

interface Props {
  title: string;
  open: boolean;
  formik: any;
  inputValues: any;
  collectionUrl: string;
  entityId: string;
  editable: boolean;
  isLoading: boolean;
  isSaving: boolean;
  onReload: (entity: Entity) => void;
  onClose: () => void;
}

function SingleInfoModal({
  title,
  isLoading,
  open,
  formik,
  inputValues,
  collectionUrl,
  entityId,
  editable,
  isSaving,
  onReload,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const [_, setSearchParams] = useSearchParams();
  const groups = useSelector(selectGroupsList);
  const [permissionSelected, setPermissionSelected] = useState<Group | null>(null);
  const [roleId] = formik.values.role_ids || [];

  const handleDelete = async () => {
    const check = window.confirm('Are you sure you want to delete your data?');
    if (check) {
      try {
        await HopperServiceApiProvider.deleteSingleEntityItem(`${collectionUrl}/${entityId}`);
        dispatch(deleteSingleDisplayEntity(entityId));
      } catch (error) {
        const errorMessage = `Unable to delete record from the database`;
        notify(errorMessage, 'Please contact support if the issue persists', 'error');
      } finally {
        onClose();
      }
    }
  };

  const handleDuplicate = async () => {
    const response = await EntityApiProvider.addEntityData(collectionUrl, formik.values);
    if (response.status === 200) {
      const duplicate = response.data.data;
      await dispatch(setSelectedEntity(duplicate));
      dispatch(addNewEntity(duplicate));
      setSearchParams({ entity_id: duplicate.entity_id });
      onReload(duplicate);
      notify('Success!', 'Sucessfully duplicated record.', 'success');
    } else {
      notify('Unable to duplicate record', 'Please contact support if the issue persists', 'error');
    }
  };

  const handleAddGroup = (group: Group) => {
    if (group) {
      setPermissionSelected(group);
      formik.setFieldValue('role_ids', [group.id]);
      formik.handleSubmit();
    }
  };

  const handleSave = () => {
    formik.handleSubmit();
    onClose();
  };

  useEffect(() => {
    if (roleId && groups) {
      const firstGroup = groups.find((group) => group.id === roleId);
      if (firstGroup) {
        setPermissionSelected(firstGroup);
      }
    }
  }, [roleId, groups]);

  return (
    <EntityModal
      open={open}
      title={title}
      selectedGroup={permissionSelected}
      editable={editable}
      isSaving={isSaving}
      canDelete={editable}
      isLoading={isLoading}
      onSave={handleSave}
      onAddGroup={handleAddGroup}
      onClose={onClose}
      onDuplicate={handleDuplicate}
      onDelete={handleDelete}
    >
      {isLoading && (
        <div className="flex w-full h-full items-center justify-center">
          <Spinner />
        </div>
      )}
      {!isLoading &&
        inputValues.map((element: CollectionElement) => {
          return (
            <SingleInfoModalElement
              entityId={entityId}
              key={element.name}
              element={element}
              formik={formik}
              elements={inputValues}
              editable={editable}
            />
          );
        })}
    </EntityModal>
  );
}

export default SingleInfoModal;
