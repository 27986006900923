/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import axios from 'axios';
import { axiosInstance, hopperInstance, axiosCustomInstance } from '../utils/axios';
import HopperServiceConfigProvider from './hopperServiceConfigProvider';
import { CollectionRequest } from '../redux/slices/collection.models';

// This is where the interception happens when you make an api call to your cloud server. The interception is needed because the header with the token needs to be added.
class HopperServiceApiProvider {
  static getOrganisation(organisationId: string) {
    return hopperInstance.get(HopperServiceConfigProvider.getOrganisation(organisationId));
  }

  static async getEntityData(url: string, data: CollectionRequest) {
    return axiosCustomInstance({
      method: 'POST',
      url: `${url}/find`,
      data,
      validateStatus: () => true,
    });
  }

  static async getEntityById(url: string) {
    return axiosCustomInstance({
      method: 'GET',
      url,
      validateStatus: () => true,
    });
  }

  static async addEntityData(url: string, body: any) {
    return axiosCustomInstance({
      method: 'POST',
      url,
      data: body,
      validateStatus: () => true,
    });
  }

  static async filterEntityData(url: string, body: any) {
    return axiosCustomInstance.post(`${url}/find`, body);
  }

  static async updateEntity(url: string, body: any) {
    return axiosCustomInstance({
      method: 'PUT',
      url,
      data: body,
      validateStatus: () => true,
    });
  }

  static async uploadFile(url: string, body: any) {
    return axiosCustomInstance.post(`${url}/file/upload`, body);
  }

  static async updateS3Bucket(url: string, body: any) {
    return axios.put(url, body);
  }

  static async downloadFile(url: string, body: any) {
    return axiosCustomInstance.post(`${url}/file/download`, body);
  }

  static async deleteSingleEntityItem(organisationId: string) {
    return axiosCustomInstance.delete(organisationId);
  }

  static async getAutomationData(organisationId: string, isEditor: boolean) {
    return axiosInstance.get(HopperServiceConfigProvider.getOrganisationAutomations(organisationId, isEditor));
  }

  static async getPermissions(organisationId: string) {
    return axiosInstance.get(HopperServiceConfigProvider.getPermissions(organisationId));
  }

  /**
   * @param path path for the entity
   * @param entityId entity_id
   * @param body Entity object containing entity_id, version and entity.data
   * @returns
   */
  static async updateHopperEntity(path: string, entityId: string, body: any) {
    return axiosInstance.put(`${path}/${entityId}`, body);
  }

  static async getIntegrationData(organisationId: string) {
    return axiosInstance.get(HopperServiceConfigProvider.getOrganisationIntegrations(organisationId));
  }

  static async getAutomationStepData(stepIds: string[]) {
    return axiosInstance.post(HopperServiceConfigProvider.getAutomationStepData(), stepIds);
  }
}

export default HopperServiceApiProvider;
