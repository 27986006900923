/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { initialPageLoad } from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';

import { getUserOrgByCode, persistOrganisation, selectOrganisation } from '../../redux/slices/organisation';
import { getValueFromSessionStorage } from '../../utils/core.utils';
import { ALLCOLLECTIONS, SIDEBAR } from '../../constants/core.contants';
import ReportContainer from './containers/ReportContainer';
import { loadReports, selectConfig } from '../../redux/slices/reports';

function Reports() {
  const { orgCode }: any = useParams();
  const navigate = useNavigate();
  const org = useSelector(selectOrganisation);
  const dispatch: any = useAppDispatch();
  const configs = useSelector(selectConfig);
  const sidebarItem = getValueFromSessionStorage(SIDEBAR);
  const collections = getValueFromSessionStorage(ALLCOLLECTIONS);
  const organisationId = org?.entity_id || '';

  useEffect(() => {
    const loadOrg = async (code: string) => {
      const fetchedOrg = await getUserOrgByCode(code);
      if (fetchedOrg) {
        await dispatch(persistOrganisation(fetchedOrg));
        dispatch(initialPageLoad());
      } else {
        navigate('/organisations');
      }
    };
    if (org.entity_id === '') {
      loadOrg(orgCode);
    }
  }, [org, orgCode, dispatch, navigate]);

  useEffect(() => {
    if (!collections || !sidebarItem) {
      dispatch(initialPageLoad());
    }
  }, [collections, sidebarItem, dispatch]);

  useEffect(() => {
    if (organisationId !== '') {
      dispatch(loadReports(organisationId));
    }
  }, [dispatch, organisationId]);
  return <ReportContainer isLoading={false} reports={configs} />;
}

export default Reports;
