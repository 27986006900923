import icons from '../components/Features/icons';
import { Colours, CTA, Feature, Hero, LandingPage } from '../models';

const hero: Hero = {
  header: 'Welcome to',
  headerSpan: 'My Trade Dashboard',
  subtext:
    'My Trade Dashboard is the backbone for import businesses. For ambitious companies importing goods to Canada, Apex makes moving goods as simple, borderless, and painless as the rest of the internet.',
  imageUrl:
    'https://static.wixstatic.com/media/3c566d_339d24699a594ccda244752c6f1c886e~mv2.jpg/v1/fill/w_388,h_259,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/2046790685-huge%20(1).jpg',
  buttonText: 'Log In',
};

const cta: CTA = {
  header: 'Unique Customs clearance solutions',
  subtext:
    'Advancing trade partnerships using innovation, technology, and transparency to provide custom solutions for international business.',
};

const features: Feature[] = [
  {
    header: 'Trade Advice',
    details:
      'Researching Import Fundamentals to help you get it right. Avoid costly mistakes and penalties and speak with a broker',
    imageUrl:
      'https://static.wixstatic.com/media/52800e_112a0d39f124400db3aba9a9488b243a~mv2.png/v1/fill/w_392,h_342,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/52800e_112a0d39f124400db3aba9a9488b243a~mv2.png',
    items: [
      {
        icon: icons.currency,
        header: '',
        details: 'Find the correct duty rates',
      },
      {
        icon: icons.clipboard,
        header: '',
        details: 'Documentation and permit requirements for all government agencies',
      },
      {
        icon: icons.globe,
        header: '',
        details: 'Confidence to make a business decision',
      },
    ],
  },
  {
    header: 'Customs Management',
    details: '',
    imageUrl:
      'https://static.wixstatic.com/media/11062b_bd311e264c3b4cdc97b58f8dd136947b~mv2.jpg/v1/fill/w_392,h_324,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/11062b_bd311e264c3b4cdc97b58f8dd136947b~mv2.jpg',
    items: [
      {
        icon: icons.trendingUp,
        header: 'Any Industry',
        details: 'Unique Customs & Trade Advice',
      },
      {
        icon: icons.star,
        header: 'Reduced Shipping Costs',
        details: 'Customs clearance in any Mode of Transport, at Any Port of Entry',
      },
      {
        icon: icons.sparkles,
        header: 'Easy Account Setup',
        details:
          'Assist with CBSA Import BN#, Tariff Classification, Utilizing Free Trade Agreements, Communicating with your carrier',
      },
    ],
  },
];

const colours: Colours = {
  primary: 'sky',
  buttonBg: 'bg-sky-500',
  buttonHover: 'hover:bg-sky-700',
  textAccent: 'text-sky-500',
};

const ApexHomePage: LandingPage = {
  colours,
  hero,
  cta,
  features,
  footer: '© 2023 Apex Customs Brokers, Inc. All rights reserved.',
};

export default ApexHomePage;
