/* eslint-disable import/no-cycle */
import { DEFAULT_DATA_MODEL, NetworkError } from '../slices/core.models';
import { Collection } from './database.model';

const DEFAULT_COLLECTION: Collection = {
  ...DEFAULT_DATA_MODEL,
  data: {
    elements: [],
    entity_id: '',
    name: '',
    editable: false,
    path: '',
    visible: false,
  },
};

export type CollectionState = {
  isCollectionLoading: boolean;
  errorOnLoadingData: boolean;
  collection: Collection;
  error: NetworkError | null;
};

export const initialCollectionState = {
  isCollectionLoading: false,
  errorOnLoadingData: false,
  collection: DEFAULT_COLLECTION,
  error: null,
};
