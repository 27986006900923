/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable radix */
/* eslint-disable no-alert */
import React, { useState } from 'react';
import { CollectionData, CollectionElement, CollectionElementOption } from '../../../../redux/models/database.model';
import {
  CollectionTextInput,
  CollectionElementTypeInput,
  CollectionElementOptionsInput,
  RelOptionsInput,
} from './CollectionInputs';
import { ELEMENT_TYPE_ID } from '../../../../redux/slices/element.models';
import ElementOptionModal from './modal/ElementOptionModal';

interface Props {
  element: CollectionElement;
  collections: CollectionData[];
  idx: number;
  handleChange: (element: CollectionElement, idx: number) => void;
  handleRemove: (idx: number) => void;
}

export default function CollectionElementForm({ element, collections, idx, handleChange, handleRemove }: Props) {
  const [optionOpen, setOptionOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<CollectionElementOption | null>(null);
  const options = element.options || [];
  const isRel = element.type.includes('rel');
  const isDate = element.type === ELEMENT_TYPE_ID.datetime;
  const isOptions = element.type.includes('select');

  const handleElementUpdate = (val: string, prop: string) => {
    const isWidth = prop === 'width';
    const updates = {
      ...element,
      position: idx,
      [prop]: isWidth ? parseInt(val) : val,
    };
    handleChange(updates, idx);
  };
  const handleTypeUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updates = {
      ...element,
      position: idx,
      type: e.target.value,
    };
    handleChange(updates, idx);
  };
  const handleAddOption = async () => {
    const option: CollectionElementOption = {
      id: crypto.randomUUID(),
      name: '',
      color: 'blue',
    };
    const updates = {
      ...element,
      position: idx,
      options: [...options, option],
    };
    handleChange(updates, idx);
    await setSelectedOption(option);
    setOptionOpen(true);
  };
  const handleEditOption = async (option: CollectionElementOption) => {
    await setSelectedOption(option);
    setOptionOpen(true);
  };
  const handleRemoveOption = (option: CollectionElementOption) => {
    const confirmResult = window.confirm(`Are you sure you want to delete ${option.name}?`);
    if (confirmResult) {
      const updatedOptions = options.filter((op) => op.id !== option.id);
      const updates = {
        ...element,
        position: idx,
        options: updatedOptions,
      };
      handleChange(updates, idx);
      setSelectedOption(option);
      setOptionOpen(true);
    }
  };
  const onSaveOption = (option: CollectionElementOption) => {
    const updates = options.map((op) => {
      if (op.id === option.id) {
        return option;
      }
      return op;
    });
    const elementUpdates = {
      ...element,
      position: idx,
      options: updates,
    };
    handleChange(elementUpdates, idx);
    setSelectedOption(null);
    setOptionOpen(false);
  };
  const handleCloseOptionModal = () => {
    setSelectedOption(null);
    setOptionOpen(false);
  };
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">{element.name}</h2>
        <button
          type="button"
          onClick={() => handleRemove(idx)}
          className="rounded-md bg-white mt-2 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Remove Element
        </button>
      </div>

      <div className="max-w-2xl space-y-10 md:col-span-2">
        <CollectionTextInput
          name="Name"
          type="text"
          prop="name"
          value={element.name}
          handleChange={handleElementUpdate}
        />
        <CollectionElementTypeInput value={element.type} handleChange={handleTypeUpdate} />
        {isRel && <RelOptionsInput element={element} collections={collections} handleChange={handleElementUpdate} />}
        {isDate && (
          <CollectionTextInput
            name="Format"
            type="text"
            prop="format"
            value={element.format}
            handleChange={handleElementUpdate}
          />
        )}
        <CollectionTextInput
          name="Property"
          type="text"
          prop="property"
          value={element.property}
          handleChange={handleElementUpdate}
        />
        {isRel && (
          <CollectionTextInput
            name="Rel filter Prop"
            type="text"
            prop="rel_filter_prop"
            value={element.rel_filter_prop}
            handleChange={handleElementUpdate}
          />
        )}
        {isOptions && (
          <CollectionElementOptionsInput
            options={options}
            handleAdd={handleAddOption}
            handleEdit={handleEditOption}
            handleRemove={handleRemoveOption}
          />
        )}
        <CollectionTextInput
          name="Width"
          type="number"
          prop="width"
          value={element.width}
          handleChange={handleElementUpdate}
        />
      </div>
      {selectedOption && isOptions && (
        <ElementOptionModal
          option={selectedOption}
          open={optionOpen}
          setOption={setSelectedOption}
          onSubmit={onSaveOption}
          onClose={handleCloseOptionModal}
        />
      )}
    </div>
  );
}
