/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import GroupApiProvider from '../../services/GroupApiProvider';
import { Group } from '../models/auth.models';
import { createPermissionStore } from './auth.utils';
// import { EntityContainer } from './core.models';
import { Organisation } from '../models/database.model';
import { GroupStore, createGroupContainer, createGroupDirectory } from './groups.utils';
import { getLocalOrganisation } from './organisation';
import { AppState } from '../models/app.models';
import { GroupsState } from '../models/group.models';

const SLICE_NAME = 'groups';
const store = new GroupStore();

const initialState: GroupsState = {
  isGroupsLoaded: false,
  isGroupsLoading: false,
  isPermissionsLoaded: false,
  errorOnLoadingData: false,
  groups: {},
  list: [],
  view: [],
  permissions: {},
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setGroups(state, action) {
      const groups: Group[] = action.payload;
      groups.sort((a, b) => {
        const aName = a.name || '';
        const bName = b.name || '';
        return aName.localeCompare(bName);
      });
      state.groups = createGroupContainer(groups);
      state.list = groups;
      state.view = createGroupDirectory(groups);
    },
    setAddGroup(state, action) {
      const groups = {
        ...state.groups,
        [action.payload.id]: action.payload,
      };
      state.groups = groups;
      state.view = createGroupDirectory(Object.values(groups));
    },
    setDeleteGroup(state, action) {
      const groupId = action.payload?.id;
      if (groupId) {
        const groups = {
          ...state.groups,
        };
        delete groups[action.payload.id];
        state.groups = groups;
        state.view = createGroupDirectory(Object.values(groups));
      }
    },
    setOrgPermissions(state, action) {
      state.permissions = createPermissionStore(action.payload);
    },
    setIsGroupsLoaded(state, action) {
      state.isGroupsLoaded = action.payload;
    },
    setIsGroupsLoading(state, action) {
      state.isGroupsLoading = action.payload;
    },
    setIsPermissionsLoaded(state, action) {
      state.isPermissionsLoaded = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.errorOnLoadingData = action.payload;
    },
    resetGroups(state) {
      state = initialState;
    },
  },
});

export const {
  resetGroups,
  setAddGroup,
  setDeleteGroup,
  setGroups,
  setIsGroupsLoaded,
  setIsGroupsLoading,
  setErrorOnLoadingData,
  setIsPermissionsLoaded,
  setOrgPermissions,
} = slice.actions;

export const loadGroups: any = (organizationCode: string) => async (dispatch: any) => {
  const isLoaded = store.exists(organizationCode);
  if (isLoaded) return;
  dispatch(setIsGroupsLoading(false));
  try {
    const org: Organisation = getLocalOrganisation();
    const permissions = org?.data.permissions || [];
    const isUser = permissions.find((permission) => permission.id === 'user');
    if (isUser) {
      const { data } = await GroupApiProvider.getGroups(organizationCode);
      const groups = data?.data || [];
      store.add(organizationCode, groups);
      dispatch(setIsGroupsLoaded(true));
      dispatch(setGroups(groups));
    }
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = `Couldn't load groups. ${error.message}. Please contact support if the problem persists.`;
      throw new Error(errorMessage);
    }
  } finally {
    dispatch(setIsGroupsLoading(false));
  }
};

export const loadGroupById: any = async (groupId: string) => {
  try {
    const { data } = await GroupApiProvider.getGroupById(groupId);
    const group: Group = data?.data;
    return group;
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = `Couldn't load groups. ${error.message}. Please contact support if the problem persists.`;
      throw new Error(errorMessage);
    }
  }
};

export const loadOrgPermissions = (organizationId: string) => async (dispatch: any) => {
  dispatch(setIsPermissionsLoaded(false));
  try {
    const { data } = await GroupApiProvider.getOrgPermissions(organizationId);
    const permissions = data?.data || [];
    dispatch(setOrgPermissions(permissions));
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = `Couldn't load permissions. ${error.message}. Please contact support if the problem persists.`;
      throw new Error(errorMessage);
    }
  } finally {
    dispatch(setIsPermissionsLoaded(true));
  }
};

const groupsReducer = slice.reducer;
export default groupsReducer;
export const allState = (state: AppState) => state[SLICE_NAME];
export const selectGroups = (state: AppState) => state[SLICE_NAME].groups;
export const selectGroupsList = (state: AppState) => state[SLICE_NAME].list;
export const selectGroupDirectory = (state: AppState) => state[SLICE_NAME].view;
export const selectIsGroupsLoaded = (state: AppState) => state[SLICE_NAME].isGroupsLoaded;
export const selectIsGroupsLoading = (state: AppState) => state[SLICE_NAME].isGroupsLoading;
export const selectIsPermissionsLoaded = (state: AppState) => state[SLICE_NAME].isPermissionsLoaded;
export const selectPermissions = (state: AppState) => state[SLICE_NAME].permissions;
export const errorOnLoadingGroups = (state: AppState) => state[SLICE_NAME].errorOnLoadingData;
