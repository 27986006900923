import React, { useEffect, useState } from 'react';
import { EntityContainer } from '../../../redux/slices/core.models';
import { Group, User } from '../../../redux/models/auth.models';
import DeleteProfile from '../shared/DeleteProfile';
import UserApiProvider from '../../../services/UserApiProvider';
import Spinner from '../../../components/shared/Spinner';
import { notify } from '../../../components/shared/Notification';
import GroupUsers from '../group/GroupUsers';
import DeleteDirectoryItemModal from '../directory/DeleteDirectoryItemModal';

const coverImageUrl =
  'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/6154dc84c184d62c5be5e8d3_img-casestudy-hero-bg-tablet.png';
const fields: EntityContainer<string> = {
  name: '',
  email: '',
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

interface Props {
  user: User | null;
  orgId: string;
  groups: EntityContainer<Group>;
  canEdit: boolean;
  handleDelete: (item: User | null) => void;
}

export default function UserProfile({ user, groups, orgId, canEdit, handleDelete }: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDeleting, setDelete] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [userGroups, setUserGroups] = useState<Group[]>([]);
  const [inFlight, setInFlight] = useState<EntityContainer<boolean>>({});
  const imgUrl = user?.data?.img_url || '';
  const name = user?.data?.name || '';
  const userId = user?.entity_id;
  const addLoading = async (loadingPermissionId: string) => {
    const loadingUpdates = {
      ...inFlight,
      [loadingPermissionId]: true,
    };
    await setInFlight(loadingUpdates);
  };

  const removeLoading = async (loadingPermissionId: string) => {
    const loadingUpdates = {
      ...inFlight,
    };
    delete loadingUpdates[loadingPermissionId];
    await setInFlight(loadingUpdates);
  };

  const onDeleteUser = async () => {
    if (user && canEdit) {
      setDelete(true);
      try {
        const { entity_id: entityId } = user;
        const response = await UserApiProvider.delete(entityId);
        if (response.status === 200) {
          handleDelete(user);
          setDelete(false);
          notify('Success', `${user.data.name} successfully deleted from your organization`, 'success');
        } else {
          notify('Error Deleting User', `Couldn't remove ${user.data.name} from your organization`, 'error');
        }
      } catch (error) {
        notify('Error Deleting User', `Couldn't remove ${user.data.name} from your organization`, 'error');
      } finally {
        setShowDelete(false);
      }
    }
  };

  const onConfirmDelete = async () => {
    setShowDelete(true);
  };

  const handleRemoveGroup = async (data: Group) => {
    if (userId && canEdit) {
      try {
        addLoading(data.id);
        const removedGroupIds = userGroups.filter((gr: Group) => gr.id === data.id).map((gr: Group) => gr.id);
        const groupResonse = await UserApiProvider.removeGroups(userId, { group_ids: removedGroupIds });
        if (groupResonse.status === 200) {
          const updates = userGroups.filter((gr) => gr.id !== data.id);
          updates.sort((a, b) => {
            const aName = a.name || '';
            const bName = b.name || '';
            return aName.localeCompare(bName);
          });
          setUserGroups(updates);
        } else {
          notify('Delete error', `Couldn't remove user from ${name}`, 'error');
        }
      } catch (error) {
        notify('Delete error', `Couldn't remove user from ${name}`, 'error');
      } finally {
        removeLoading(data.id);
      }
    }
  };

  const handleAddGroup = async (data: Group) => {
    const isInGroup = userGroups.find((gr) => gr.id === data.id);
    if (!isInGroup && userId && canEdit) {
      try {
        const response = await UserApiProvider.addGroups(userId, { group_ids: [data.id] });
        if (response.status === 200) {
          const updatedGroups = [...userGroups, data];
          updatedGroups.sort((a, b) => {
            const aName = a.name || '';
            const bName = b.name || '';
            return aName.localeCompare(bName);
          });
          setUserGroups(updatedGroups);
        } else {
          notify('Error', `Couldn't add ${user.data.name} to ${name}`, 'error');
        }
      } catch (error) {
        notify('Error', `Couldn't add ${user.data.name} to ${name}`, 'error');
      }
    }
  };

  useEffect(() => {
    const loadUser = async (entityId: string) => {
      setLoading(true);
      try {
        setUserGroups([]);
        const response = await UserApiProvider.getUserById(entityId, orgId);
        if (response.status === 200) {
          const userData: User = response.data.data;
          const groupData = userData.data.groups || [];
          groupData.sort((a, b) => {
            const aName = a.name || '';
            const bName = b.name || '';
            return aName.localeCompare(bName);
          });
          if (groupData.length) {
            setUserGroups(groupData);
          }
        }
      } catch (error) {
        notify('Error loading profile', '', 'error');
      } finally {
        setLoading(false);
      }
    };
    if (userId) {
      loadUser(userId);
    }
  }, [userId, orgId]);

  return (
    <article>
      {/* Profile header */}
      <div>
        <div>
          <img className="h-32 w-full object-cover lg:h-48" src={coverImageUrl} alt="" />
        </div>
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={imgUrl} alt="" />
            </div>
            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                <h1 className="truncate text-2xl font-bold text-gray-900">{name}</h1>
              </div>
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">{name}</h1>
          </div>
        </div>
      </div>
      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="mt-12 border-b border-gray-200" />
      </div>
      {/* Description list */}
      <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {Object.keys(fields).map((field) => {
            const data: EntityContainer<string> = {
              name: user?.data?.name || 'No name set',
              email: user?.data.email || 'No email set',
            };
            const val = data[field];
            return (
              <div key={field} className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(field)}</dt>
                <dd className="mt-1 text-sm text-gray-900">{val}</dd>
              </div>
            );
          })}
        </dl>
      </div>

      {/* Spinner */}
      {isLoading ? (
        <div className="flex pt-32 w-full h-full items-center justify-center">
          <Spinner />
        </div>
      ) : null}

      {/* Groups list */}
      {!isLoading && (
        <GroupUsers
          title="Add Groups"
          data={userGroups}
          store={groups}
          inFlight={inFlight}
          isImage={false}
          canEdit={canEdit}
          getName={(group) => group?.name || ''}
          getId={(group) => group?.id || ''}
          getImg={() => ''}
          getDetails={(group) => group.description}
          onAdd={handleAddGroup}
          onRemove={handleRemoveGroup}
        />
      )}
      {!isLoading && canEdit && (
        <DeleteProfile
          title="Delete User"
          description="The user will be removed and  will no longer be able to access the database."
          onDelete={onConfirmDelete}
          isDeleting={isDeleting}
          data={user}
        />
      )}
      {showDelete ? (
        <DeleteDirectoryItemModal
          title="Delete User"
          details="Are you sure you want to delete this user? This person will lose access to your database."
          open={showDelete}
          setOpen={setShowDelete}
          onDelete={onDeleteUser}
        />
      ) : null}
    </article>
  );
}
