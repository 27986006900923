/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { auth0Client } from '../context/Auth0Context';
import { LOCAL_STORAGE_TOKEN_KEY } from '../config';
import { getOrgCode } from './core.utils';

/**
 * Base Url: Hopper Platorm
 * X-Organization-Code: Org Code
 */
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    const hdrs: any = {
      Authorization: `Bearer ${window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)}`,
    };
    const orgCode = getOrgCode();
    if (orgCode) {
      hdrs['X-Organization-Code'] = orgCode;
    }
    config.headers = hdrs;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/**
 * Base Url: Hopper Platorm
 * X-Organization-Code: Hopper
 */
export const hopperInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

hopperInstance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    const hdrs: any = {
      Authorization: `Bearer ${window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)}`,
    };
    hdrs['X-Organization-Code'] = 'hopper';
    config.headers = hdrs;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 0 && !originalRequest.retry) {
      originalRequest.retry = true;
      const accessToken = await auth0Client.getTokenSilently();
      window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, accessToken);
      return axiosInstance(originalRequest);
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

/**
 * Base Url: App
 * X-Organization-Code: Org Code
 */
export const axiosCustomInstance = axios.create();

axiosCustomInstance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    const hdrs: any = {
      Authorization: `Bearer ${window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)}`,
    };
    const orgCode = getOrgCode();
    if (orgCode) {
      hdrs['X-Organization-Code'] = orgCode;
    }
    config.headers = hdrs;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosCustomInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 0 && !originalRequest.retry) {
      originalRequest.retry = true;
      const accessToken = await auth0Client.getTokenSilently();
      window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, accessToken);
      return axiosInstance(originalRequest);
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);
