/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Sidebar from '../../../components/sidebar/Sidebar';
import PageHeader from '../../../components/headers/PageHeader';
import Navbar from '../../../components/navbar/Navbar';
import Spinner from '../../../components/shared/Spinner';
import ReportView from '../components/ReportView';
import { Report } from '../../../redux/models/report.models';

interface Props {
  isLoading: boolean;
  reports: Report[];
}

export default function ReportContainer({ isLoading, reports }: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="min-h-full">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1">
          <PageHeader title="Dashboard" />
          <div className="">
            {isLoading && (
              <div className="flex pt-32 w-full h-full items-center justify-center">
                <Spinner />
              </div>
            )}
            {!isLoading ? (
              <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 m-8">
                {reports.map((report) => (
                  <div key={report.entity_id} className="overflow-hidden rounded-xl">
                    <ReportView report={report} />
                  </div>
                ))}
              </ul>
            ) : null}
          </div>
        </main>
      </div>
    </div>
  );
}
