/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import OrganisationsList from './OrganisationsList';

import { persistOrganisation, userOrganisations } from '../../redux/slices/organisation';
import { Organisation } from '../../redux/models/database.model';
import Analytics from '../../utils/analytics';
import { notify } from '../../components/shared/Notification';
import { resetGroups } from '../../redux/slices/groups';

const TITLE = 'Select Organisation';
const LOADING_TEXT = 'Loading...';
const AUTOMATIONS = 'automations';
const COLLECTIONS = 'collections';

function OrganisationList() {
  const [isOrgsLoading, setIsOrgsLoading] = useState(false);
  const [orgs, setOrgs] = useState<Organisation[]>([]);
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const handleSelect = async (org: Organisation) => {
    dispatch(persistOrganisation(org));
    const isAdmin = org.data.permissions.filter((permission) => permission.id === 'automation');
    const route = isAdmin ? AUTOMATIONS : COLLECTIONS;
    const orgCode = org.data.organization_code;
    Analytics.launchProject(org.data.name);
    navigate(`/${orgCode}/${route}`);
  };

  useEffect(() => {
    const fetchOrgs = async () => {
      setIsOrgsLoading(true);
      try {
        const data = await userOrganisations();
        const orgData = data || [];
        if (orgData.length && orgData.length === 1) {
          const [org] = orgData;
          const isAdmin = org.data.permissions.filter((permission) => permission.id === 'automation');
          const route = isAdmin ? AUTOMATIONS : COLLECTIONS;
          const orgCode = org.data.organization_code;
          dispatch(persistOrganisation(org));
          Analytics.launchProject(org.data.name);
          navigate(`/${orgCode}/${route}`);
        } else {
          setOrgs(orgData);
        }
      } catch (error) {
        notify('Error loading organisations', 'Please contact support', 'error');
      } finally {
        setIsOrgsLoading(false);
      }
    };
    fetchOrgs();
    dispatch(resetGroups());
  }, [dispatch, navigate]);

  return (
    <section className="flex bg-gray-100  h-screen w-screen items-center flex-col pb-24">
      {isOrgsLoading ? null : <h3 className="font-semibold text-lg mt-20 mb-8">{TITLE}</h3>}
      {isOrgsLoading ? <p>{LOADING_TEXT}</p> : <OrganisationsList organisations={orgs} onSelect={handleSelect} />}
    </section>
  );
}

export default OrganisationList;
