/* eslint-disable @typescript-eslint/no-explicit-any */
import { App } from '../redux/models/app.models';
import { Automation, AutomationStepData } from '../redux/slices/automation.models';
import { axiosInstance } from '../utils/axios';
import HopperServiceConfigProvider from './hopperServiceConfigProvider';

export interface BuildFormBody {
  page: number;
  config: any;
}

export interface UpdateTriggerBody {
  app: App;
  trigger_id: string;
  type: string;
  config: any;
}

export interface PostActionBody {
  app: App;
  action_id: string;
  type: string;
  integration: any | null;
  config: any;
}

class AutomationApiProvider {
  static async getAutomationDetails(automationId: string) {
    return axiosInstance.get(HopperServiceConfigProvider.automationDetails(automationId));
  }

  static getTriggerApps() {
    return axiosInstance.get(HopperServiceConfigProvider.getTriggerApps());
  }

  static getActionApps() {
    return axiosInstance.get(HopperServiceConfigProvider.getActionApps());
  }

  static getTokens(automationId: string) {
    return axiosInstance.get(HopperServiceConfigProvider.getTokens(automationId));
  }

  static getTrigger(automationId: string) {
    return axiosInstance.get(HopperServiceConfigProvider.getAutomationTrigger(automationId));
  }

  static saveTrigger(automationId: string, data: UpdateTriggerBody) {
    return axiosInstance({
      method: 'PUT',
      url: HopperServiceConfigProvider.getAutomationTrigger(automationId),
      data,
      validateStatus: () => true,
    });
  }

  static updateAutomation(automation: Automation) {
    return axiosInstance({
      method: 'PUT',
      url: HopperServiceConfigProvider.automationDetails(automation.entity_id),
      data: automation,
      validateStatus: () => true,
    });
  }

  static deleteAutomation(automationId: string) {
    return axiosInstance({
      method: 'DELETE',
      url: HopperServiceConfigProvider.automationDetails(automationId),
      validateStatus: () => true,
    });
  }

  static deleteAutomationStep(automationId: string, stepId: string) {
    return axiosInstance({
      method: 'DELETE',
      url: HopperServiceConfigProvider.automationStep(automationId, stepId),
      validateStatus: () => true,
    });
  }

  static getStep(automationId: string, stepId: string) {
    return axiosInstance.get(HopperServiceConfigProvider.getAutomationStep(automationId, stepId));
  }

  static postStep(automationId: string, step: PostActionBody) {
    return axiosInstance({
      method: 'POST',
      url: HopperServiceConfigProvider.createAutomationStep(automationId),
      data: step,
      validateStatus: () => true,
    });
  }

  static updateStep(automationId: string, step: AutomationStepData) {
    return axiosInstance({
      method: 'PUT',
      url: HopperServiceConfigProvider.getAutomationStep(automationId, step.automation_step_id),
      data: step,
      validateStatus: () => true,
    });
  }

  static buildForm(formId: string, config: BuildFormBody) {
    return axiosInstance({
      method: 'POST',
      url: HopperServiceConfigProvider.buildForm(formId),
      data: config,
      validateStatus: () => true,
    });
  }

  static create(name: string) {
    return axiosInstance({
      method: 'POST',
      url: HopperServiceConfigProvider.postAutomation(),
      data: {
        name,
        description: '',
        is_on: false,
        role_ids: [],
      },
      validateStatus: () => true,
    });
  }
}

export default AutomationApiProvider;
