/* eslint-disable import/no-cycle */
import { getValueFromSessionStorage } from '../../utils/core.utils';
import { ORGANISATION } from '../slices/slices';
import { Organisation } from './database.model';

export type OrganisationState = {
  isOrganisationLoaded: boolean;
  errorOnLoadingData: boolean;
  data: Organisation;
};

const DEFAULT_ORG: Organisation = {
  author: '',
  data: {
    admin_id: '',
    base_url: '',
    name: '',
    apps: [],
    logo_url: '',
    members: [],
    organization_code: '',
    permissions: [],
  },
  entity_id: '',
  entity_type: '',
  roles: [],
  version: '',
};

export const getInitialState = (): OrganisationState => ({
  isOrganisationLoaded: false,
  errorOnLoadingData: false,
  data: getValueFromSessionStorage(ORGANISATION) || DEFAULT_ORG,
});
