/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React from 'react';

import { FileValues } from '../../TableCell.modals';
import TableAttachmentCellItem from '../MultiAttachment/TableAttachmentCellItem';

interface Props {
  queueId: string;
  file: FileValues;
  width: string;
  minWidth: string;
}

function TableAttachmentCell({ queueId, file, width, minWidth }: Props) {
  return (
    <div className="table-cell-row relative flex align-middle" style={{ width, minWidth }}>
      <div className="table-inner-cell hover:cursor-pointer flex align-middle  overflow-hidden">
        <TableAttachmentCellItem key={`table-attachment-cell-item-${file.name}`} file={file} queueId={queueId} />
      </div>
    </div>
  );
}

export default TableAttachmentCell;
