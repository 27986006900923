/* eslint-disable */
import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { TextFieldToken, textInputStyles } from './FormTextInput.utils';

interface Props {
  name: string;
  value: string;
  type?: string;
  placeholder?: string;
  tokens: TextFieldToken[];
  onChange: (value: any) => void;
}

function FormTokenInput({ name, value, type, placeholder, tokens, onChange }: Props) {
  const handleChange = (data: string) => {
    const val = type === 'number' ? parseFloat(data) : data;
    onChange(val);
  };
  return (
    <MentionsInput
      singleLine
      value={value || ''}
      placeholder={placeholder}
      a11ySuggestionsListLabel={name}
      onChange={(e) => handleChange(e.target.value)}
      style={textInputStyles}
      className="mt-2 block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6r"
    >
      <Mention
        trigger="@"
        data={tokens}
        className="bg-gray-200 rounded-sm"
      />
    </MentionsInput>
  );
}

FormTokenInput.defaultProps = {
  placeholder: '',
  type: 'text',
};

export default FormTokenInput;
