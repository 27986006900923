/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { DebouncedFunc } from 'lodash';
import { MagnifyingGlassIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import {
  selectDisplayTableData,
  selectDisplayGroup,
  setGroupData,
  selectGroupByColumns,
  isListView,
  selectRequest,
  selectCollectionUrl,
  setPageSize,
  selectCollection,
} from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';
import GroupButton from './GroupButton';
import { COLLECTION_VIEW_TYPE, CollectionElement, TableData } from '../../redux/models/database.model';
import FilterButton from './FilterButton';
import { createCSVFile } from '../../utils/csv/csv';
// import { getTableWidth } from '../Body/table.utils';
// import classNames from '../../utils/tailwind';
import {
  PaginationDropdown,
  CollectionViewDropdown,
  ConfigureViewDropdown,
} from '../../components/shared/DropdownSelect';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { ELEMENT_TYPE_ID } from '../../redux/slices/element.models';
import Permission from '../../components/guards/Permissions';
import { ResourcePermission, Resources } from '../../redux/models/permissions.models';

const EditCollectionPermissions = {
  [Resources.Organization]: ResourcePermission.Write,
};

interface Props {
  viewType: string;
  selectView: (view: string) => void;
  resetFilters: () => void;
  resetGroup: () => void;
  selectGroup: (element: CollectionElement) => void;
  handleSearch: DebouncedFunc<(e: React.ChangeEvent<HTMLInputElement>) => void>;
}

function Header({ handleSearch, viewType, selectView, selectGroup, resetFilters, resetGroup }: Props) {
  const dispatch = useAppDispatch();
  const features = useFeatureFlags();
  const displayData = useSelector(selectDisplayTableData);
  const collection = useSelector(selectCollection);
  const url = useSelector(selectCollectionUrl);
  const request = useSelector(selectRequest);
  const groupData = useSelector(selectDisplayGroup);
  const groupByColumns = useSelector(selectGroupByColumns);
  const isList = useSelector(isListView);
  const [openFilterId, setOpenFilterId] = useState<string | null>(null);
  const selectedEntityTitle = collection?.name || '';
  const pageSize = request.pagination.page_size;
  const total = displayData.count || 0;
  const isCalendarEnabled = features.calendar_view?.is_enabled || false;
  const isGridEnabled = features.grid_view?.is_enabled || false;
  const isBoardEnabled = features.board_view?.is_enabled || false;
  const showViewType = isGridEnabled || isBoardEnabled;
  const isGoupAvailabile = viewType !== COLLECTION_VIEW_TYPE.GRID;
  const isBoardSelected = viewType === COLLECTION_VIEW_TYPE.BOARD;
  const groupByElements = isBoardSelected
    ? groupByColumns.filter((el) => el.type === ELEMENT_TYPE_ID.select)
    : groupByColumns;

  const clearGroup = () => {
    resetGroup();
  };

  const handleExpandAll = (isOpen: boolean) => {
    const data = groupData.data.map((item: TableData) => {
      return {
        ...item,
        isOpen,
      };
    });
    dispatch(setGroupData(data));
  };

  const handleDownload = () => {
    createCSVFile(displayData);
  };

  const handlePageSize = (size: number) => {
    dispatch(setPageSize(size, url, request));
  };

  return (
    <div className="bg-white pb-5 top-0 z-20 fixed w-full">
      <div className="border-b-2	w-full pb-5 mb-4 pt-2 px-8 my-4">
        <h1
          className="text-lg font-medium leading-6 text-gray-900 sm:truncate"
          data-cy={`${selectedEntityTitle}-page-header`}
        >
          {selectedEntityTitle}
        </h1>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex flex-row px-8">
          <div className="search-contianer flex justify-center items-center hover:bg-gray-100 rounded ">
            <MagnifyingGlassIcon className="text-gray-500 ml-2 mr-1 h-4 w-4 group-hover:text-gray-500" />
            <input
              data-cy="collection_header_search_input"
              className="focus:shadow appearance-none rounded w-full py-2 px-3 text-gray-700 focus:outline-none leading-tight hover:bg-gray-100"
              onChange={handleSearch}
              placeholder="Search"
              type="text"
              name="search"
            />
          </div>
          <FilterButton
            filterKey="filterButton"
            openFilter={openFilterId}
            setOpenFilter={setOpenFilterId}
            onReset={resetFilters}
          />
          {showViewType && (
            <Permission resources={EditCollectionPermissions}>
              <CollectionViewDropdown
                isCalendarEnabled={isCalendarEnabled}
                isBoardEnabled={isBoardEnabled}
                isGridEnabled={isGridEnabled}
                view={viewType}
                filterKey="viewType"
                openFilter={openFilterId}
                setOpenFilter={setOpenFilterId}
                selectView={selectView}
              />
            </Permission>
          )}
          {showViewType && (
            <Permission resources={EditCollectionPermissions}>
              <ConfigureViewDropdown filterKey="configure" openFilter={openFilterId} setOpenFilter={setOpenFilterId} />
            </Permission>
          )}
          {isGoupAvailabile && (
            <GroupButton
              group={groupData.group}
              elements={groupByElements}
              filterKey="groupData"
              openFilter={openFilterId}
              setOpenFilter={setOpenFilterId}
              onSelect={selectGroup}
              onClear={clearGroup}
              onExpandAll={handleExpandAll}
            />
          )}
          <div className="flex items-center">
            <PaginationDropdown
              pageSize={pageSize}
              filterKey="pagination"
              openFilter={openFilterId}
              setOpenFilter={setOpenFilterId}
              selectPageSize={handlePageSize}
            />
            <span className="text-gray-400 text-xs">of {total} results</span>
          </div>
          {isList ? (
            <button
              type="button"
              onClick={() => handleDownload()}
              className="text-xs text-blue-600 hover:text-blue-800 hover:font-medium ml-2"
            >
              <ArrowDownTrayIcon className="text-gray-500 ml-2 mr-1 h-4 w-4 hover:text-gray-900" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Header;
