/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { Automation, AutomationStep, AutomationStepData } from './automation.models';
import { notify } from '../../components/shared/Notification';
import { initialAutomationDetailsState, StepContainer } from '../models/automation.details.models';
import { AUTOMATION_DETAILS } from './slices';
import { AppState } from '../models/app.models';
import AutomationApiProvider, { UpdateTriggerBody } from '../../services/AutomationApiProvider';

const SLICE_NAME = AUTOMATION_DETAILS;

const createStepContainer = (automation: Automation) => {
  if (automation && automation.data) {
    const steps = automation.data.automation_steps || [];
    return steps.reduce((prev: StepContainer, curr: AutomationStep) => {
      return {
        ...prev,
        [curr.entity_id]: curr,
      };
    }, {});
  }
  return {};
};

const initialState = initialAutomationDetailsState;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAutomation(state, action) {
      state.automation = action.payload;
      state.steps = createStepContainer(action.payload);
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    setStep(state, action) {
      state.selectedStepId = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.errorOnLoad = action.payload;
    },
    resetAutomation(state) {
      state.automation = null;
    },
  },
});

export const { resetAutomation, setAutomation, setIsLoaded, setIsLoading, setErrorOnLoadingData, setStep } =
  slice.actions;

/**
 * =========================== Automations ===========================
 */
export const loadAutomation: any = (automationId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  dispatch(setErrorOnLoadingData(false));
  try {
    const { data } = await AutomationApiProvider.getAutomationDetails(automationId);
    dispatch(setAutomation(data.data));
    dispatch(setIsLoaded(true));
  } catch (error) {
    dispatch(setErrorOnLoadingData(true));
    if (error instanceof Error) {
      const errorMessage = `Couldn't get Automations. ${error.message}. Please contact support if the problem persists.`;
      notify('', errorMessage, 'info');
    }
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateAutomation = (automation: Automation) => {
  return AutomationApiProvider.updateAutomation(automation);
};

export const deleteAutomation = (automationId: string) => {
  return AutomationApiProvider.deleteAutomation(automationId);
};

/**
 * =========================== Automation Triggers ===========================
 */

export const loadTriggerApps: any = () => {
  return AutomationApiProvider.getTriggerApps();
};

export const loadTrigger = (automationId: string) => {
  return AutomationApiProvider.getTrigger(automationId);
};

export const saveTrigger = (automationId: string, data: UpdateTriggerBody) => {
  return AutomationApiProvider.saveTrigger(automationId, data);
};

/**
 * =========================== Automation Steps ===========================
 */

export const loadActionApps = () => {
  return AutomationApiProvider.getActionApps();
};

/// Add Step to local state
export const addStep: any = (automation: Automation) => async (dispatch: any) => {
  const defaultStep: AutomationStepData = {
    action: {
      action_id: '',
      config: null,
      form_id: '',
      integration: null,
      name: '',
      start_page: 0,
      type: '',
    },
    action_id: '',
    app: null,
    automation_step_id: '',
    config: null,
    integration: null,
    type: '',
  };
  const steps = automation.data.steps || [];
  const data = {
    ...automation,
    data: {
      ...automation.data,
      steps: [...steps, defaultStep],
    },
  };
  dispatch(setAutomation(data));
};

/// Save new step to database
export const saveStep = (automation: Automation, step: AutomationStepData) => {
  if (!step.app) return;
  if (step.automation_step_id !== '') return AutomationApiProvider.updateStep(automation.entity_id, step);
  return AutomationApiProvider.postStep(automation.entity_id, {
    app: step.app,
    action_id: step.action_id,
    integration: null,
    type: step.type,
    config: step.config,
  });
};

export const deleteStep = (automation: Automation, step: AutomationStepData) => {
  return AutomationApiProvider.deleteAutomationStep(automation.entity_id, step.automation_step_id);
};

export const loadStep = (automationId: string, stepId: string) => {
  return AutomationApiProvider.getStep(automationId, stepId);
};

export const buildForm = (formId: string, page: number, config: any) => {
  return AutomationApiProvider.buildForm(formId, { page, config });
};

export const getTokens = (automationId: string) => {
  return AutomationApiProvider.getTokens(automationId);
};

const automationsReducer = slice.reducer;
export default automationsReducer;

export const selectAutomation = (state: AppState) => state[SLICE_NAME].automation;
export const selectIsAutomationLoaded = (state: AppState) => state[SLICE_NAME].isLoaded;
export const selectIsAutomationLoading = (state: AppState) => state[SLICE_NAME].isLoading;
export const errorOnLoadingData = (state: AppState) => state[SLICE_NAME].errorOnLoad;
export const selectStepId = (state: AppState) => state[SLICE_NAME].selectedStepId;
export const selectIsStepSelected = (state: AppState) => selectStepId(state) !== '';
export const selectStep = (state: AppState, stepId: string) => state[SLICE_NAME].steps[stepId];
export const selectSteps = (state: AppState) => {
  const automation = selectAutomation(state);
  if (automation && automation.data) {
    return automation.data.automation_steps || [];
  }
  return [];
};
