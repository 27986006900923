/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from '../../components/sidebar/Sidebar';
import { initialPageLoad, isPageLoaded } from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';
import { getValueFromSessionStorage } from '../../utils/core.utils';
import { ALLCOLLECTIONS, SIDEBAR } from '../../constants/core.contants';
import Navbar from '../../components/navbar/Navbar';
import UserCollections from './UserCollections';
import { getLocalOrganisation } from '../../redux/slices/organisation';

function CollectionList() {
  const dispatch: any = useAppDispatch();
  const isLoaded = useSelector(isPageLoaded);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const org = getLocalOrganisation();
  const sidebarItem = getValueFromSessionStorage(SIDEBAR);
  const collections = getValueFromSessionStorage(ALLCOLLECTIONS);

  useEffect(() => {
    if (!collections || !sidebarItem) {
      dispatch(initialPageLoad());
    }
  }, [collections, sidebarItem, dispatch]);

  useEffect(() => {
    console.log(isLoaded);
  }, [isLoaded]);

  return (
    <div className="min-h-full relative">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1 w-full h-full justify-center items-center pt-24">
          {org && <UserCollections org={org} />}
        </main>
      </div>
    </div>
  );
}

export default CollectionList;
