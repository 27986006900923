/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
import { compareOpShorts } from '../constants/formValue.contants';
import {
  CollectionFilter,
  Field,
  CollectionRequest,
  DEFAULT_REQUEST_PAGINATION,
  DEFAULT_REQUEST_BODY,
  QueryOperator,
} from '../redux/slices/collection.models';
import { ELEMENT_TYPE_ID } from '../redux/slices/element.models';
import { CollectionElement } from '../redux/models/database.model';

export const validateFilter = (values: any) => {
  const errors: any = {};
  if (values.operator === '') {
    values.operator = QueryOperator.And;
  }
  values.filters.forEach((ele: any) => {
    if (ele.op?.includes('Empty')) {
      ele.value = '';
    }
  });

  if (!values.filters || values.filters.length === 0) {
    errors.filters = 'Filters should not be an empty array';
  } else {
    values.filters.forEach((filter: any, index: number) => {
      const { field, op, value } = filter;
      if (!field || !field.name || !field.type) {
        errors.filters = errors.filters || [];
        errors.filters[index] = {
          field: 'Field name and type are required',
        };
      } else {
        if (field.name.trim() === '') {
          errors.filters = errors.filters || [];
          errors.filters[index] = {
            field: 'Field name should not be an empty string',
          };
        }
        if (field.type.trim() === '') {
          errors.filters = errors.filters || [];
          errors.filters[index] = {
            field: 'Field type should not be an empty string',
          };
        }
      }

      if (!op || op.trim() === '') {
        errors.filters = errors.filters || [];
        errors.filters[index] = {
          op: 'Operator is required',
        };
      } else if (op.includes('Empty') && value !== '' && value !== null) {
        errors.filters = errors.filters || [];
        errors.filters[index] = {
          value: 'Value should be empty string or null for Empty operator',
        };
      } else if (!op.includes('Empty') && !value && field.type.includes('select')) {
        errors.filters = errors.filters || [];
        errors.filters[index] = {
          value: `Value should not be empty string or null for ${op} operator`,
        };
      }
    });
  }

  if (values.filters && values.filters.length >= 2 && (!values.operator || values.operator.trim() === '')) {
    errors.operator = 'Operator is required for more than one filter';
  }

  return errors;
};

const getFilterValue = (field: Field, filter: CollectionFilter) => {
  if (field.type === 'rel' && filter.value === '') {
    return null;
  }
  if (field.type === 'number') {
    return parseFloat(filter.value);
  }
  return filter.value;
};

const createFilters = (filters: CollectionFilter[]) => {
  return filters.map((filter) => {
    const { field, op } = filter;
    return {
      ...filter,
      field: { ...field },
      value: getFilterValue(field, filter),
      op:
        (field.type === 'string' || field.type.includes('string')) && op === 'Contains' ? 'regex' : compareOpShorts[op],
    };
  });
};

export const updateFilterValues = (values: any, request: CollectionRequest): CollectionRequest => {
  const selected: CollectionFilter[] = values.filters || [];
  const filters = createFilters(selected);
  return {
    ...request,
    pagination: DEFAULT_REQUEST_PAGINATION,
    operator: values.operator || QueryOperator.And,
    filters,
  };
};

const isSelect = (element: CollectionElement) => {
  const type = element.type || '';
  return (
    type === ELEMENT_TYPE_ID.select ||
    type === ELEMENT_TYPE_ID.multi_select ||
    type === ELEMENT_TYPE_ID.role ||
    type === ELEMENT_TYPE_ID.multi_role
  );
};

const isValidSearchElement = (element: CollectionElement) => {
  const type = element.type || '';
  return type === ELEMENT_TYPE_ID.string || element.type === ELEMENT_TYPE_ID.number || isSelect(element);
};

const createField = (element: CollectionElement): Field => ({
  id: element.name,
  name: `data.${element.property}`,
  type: element.type,
});

const createStringSearchFilter = (value: string, element: CollectionElement): CollectionFilter => {
  return {
    field: createField(element),
    op: 'Contains',
    value,
  };
};

const createSelectSearchFilters = (value: string, element: CollectionElement): CollectionFilter[] => {
  const options = element.options || [];
  const val = value.toLocaleLowerCase();
  const matching = options.filter((option) => option.name.toLocaleLowerCase().includes(val));
  const isMulti = element.type === ELEMENT_TYPE_ID.multi_select || element.type === ELEMENT_TYPE_ID.multi_role;
  return matching.map((option) => {
    return {
      field: createField(element),
      op: isMulti ? 'Contains' : 'Equals',
      value: option.id,
    };
  });
};

const createNumberSearchFilter = (value: string, element: CollectionElement): CollectionFilter => {
  return {
    field: createField(element),
    op: 'Equals',
    value: parseFloat(value),
  };
};

/**
 * Take search text and convert it to AQI Query
 * @param search search text
 * @param elements collection elements
 */
export const createSearchFilters = (search: string, elements: CollectionElement[]): CollectionRequest => {
  const searchElements = elements.filter((element) => isValidSearchElement(element));
  const filterData: CollectionFilter[] = searchElements.flatMap((el) => {
    if (el.type === ELEMENT_TYPE_ID.string) return createStringSearchFilter(search, el);
    if (el.type === ELEMENT_TYPE_ID.number) return createNumberSearchFilter(search, el);
    if (isSelect(el)) return createSelectSearchFilters(search, el);
    return {
      field: {
        id: el.name,
        name: `data.${el.property}`,
        type: el.type,
      },
      op: 'Contains',
      value: search,
    };
  });
  const filters = createFilters(filterData);
  return {
    ...DEFAULT_REQUEST_BODY,
    filters,
    operator: QueryOperator.Or,
  };
};
