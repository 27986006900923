/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import { Automation, Token } from '../../../redux/slices/automation.models';
import TriggerLayoutItem from './TriggerLayoutItem';
import StepLayoutItem from './StepLayoutItem';
import Analytics from '../../../utils/analytics';
import { getTokens } from '../../../redux/slices/automation.details';

interface Props {
  automation: Automation;
}

export default function FormLayout({ automation }: Props) {
  const [openStep, setOpen] = useState(0);
  const [isTokensLoaded, setTokensLoaded] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const automationId = automation.entity_id;
  const steps = automation.data.steps || [];
  const trigger = automation.data.trigger || {};
  useEffect(() => {
    const load = async (id: string) => {
      try {
        setTokensLoaded(false);
        const response = await getTokens(id);
        if (response.status === 200) {
          setTokensLoaded(true);
          const responseData = response.data.data || [];
          setTokens(responseData);
        }
      } catch (error) {
        Analytics.capture(error);
      }
    };
    if (!isTokensLoaded) load(automationId);
  }, [automationId, isTokensLoaded]);
  return (
    <div className="grid grid-cols-1 gap-8 sm:grid-cols-1 w-full">
      <TriggerLayoutItem
        automationId={automation.entity_id}
        trigger={trigger}
        order={1}
        tokens={tokens}
        openStep={openStep}
        setOpen={setOpen}
      />
      {steps.map((step, idx) => (
        <StepLayoutItem
          key={step.action_id}
          automation={automation}
          step={step}
          order={idx + 2}
          tokens={tokens}
          openStep={openStep}
          setOpen={setOpen}
        />
      ))}
    </div>
  );
}
