/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Entity } from '../../redux/models/auth.models';
import classNames from '../../utils/tailwind';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setSelectedEntity } from '../../redux/slices/database';
import { CollectionElement } from '../../redux/models/database.model';
import { getCsvEntityValue } from '../../redux/slices/database.utils';
import { FileValues } from '../../tables/Body/TableCell.modals';
import { getFileElement } from '../grid/gridview.utils';
import AttachmentModalImage from '../../tables/Body/Cells/MultiAttachment/AttachmentModelImage';
import DateService from '../../utils/dateService';
import { ELEMENT_TYPE_ID } from '../../redux/slices/element.models';

interface Props {
  entity: Entity;
  isImage: boolean;
  primary: CollectionElement;
  start: CollectionElement | null | undefined;
  end?: CollectionElement | null;
  elements: CollectionElement[];
  onEdit: () => void;
}

const getValue = (value: string, element: CollectionElement) => {
  if (element.type === ELEMENT_TYPE_ID.datetime) {
    return DateService.format(value, 'hh:mm a');
  }
  return value;
};

function CalendarSiteListItem({ entity, elements, isImage, primary, start, end, onEdit }: Props) {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const [file, setFile] = useState<FileValues | null>(null);
  const name = getCsvEntityValue(entity, primary);
  const startDate = start ? getValue(getCsvEntityValue(entity, start), start) : 'No time selected';
  const endDate = end ? getValue(getCsvEntityValue(entity, end), end) : null;
  const handleEdit = async () => {
    await dispatch(setSelectedEntity(entity));
    setSearchParams({ entity_id: entity.entity_id });
    onEdit();
  };
  useEffect(() => {
    const fileElement = getFileElement(elements);
    if (fileElement) {
      const fileData = entity.data[fileElement.property];
      if (fileData) setFile(fileData);
    }
  }, [elements, entity]);
  return (
    <li
      key={entity.entity_id}
      className="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100"
    >
      {isImage && (
        <div className="h-12 w-12 flex-none rounded-full bg-white object-cover ring-1 ring-gray-900/10">
          {file && <AttachmentModalImage size="small" rounding="rounded-full" file={file} />}
        </div>
      )}
      <div className="flex-auto">
        <p className="text-gray-900">{name}</p>
        <p className="flex mt-0.5">
          <p className="mr-1">{`${startDate}`}</p>
          <p>{endDate && `- ${endDate}`}</p>
        </p>
      </div>
      <Menu as="div" className="relative opacity-0 focus-within:opacity-100 group-hover:opacity-100">
        <div>
          <Menu.Button className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={handleEdit}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    Edit
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </li>
  );
}

CalendarSiteListItem.defaultProps = {
  end: undefined,
};

export default CalendarSiteListItem;
