import React, { useEffect, useState } from 'react';

import { EntityContainer } from '../../../redux/slices/core.models';
import { User, Group, PermissionStore, RolePermission } from '../../../redux/models/auth.models';
import CollectionPermissions from '../permissions';
import { loadGroupById } from '../../../redux/slices/groups';
import { createPermissionStore } from '../../../redux/slices/auth.utils';
import GroupUsers from './GroupUsers';
import DeleteProfile from '../shared/DeleteProfile';
import GroupApiProvider from '../../../services/GroupApiProvider';
import Spinner from '../../../components/shared/Spinner';
import UserApiProvider from '../../../services/UserApiProvider';
import { notify } from '../../../components/shared/Notification';
import DeleteDirectoryItemModal from '../directory/DeleteDirectoryItemModal';
import { App } from '../../../redux/models/app.models';

const fields: EntityContainer<string> = {
  name: '',
  description: '',
};

const getAppName = (app: App, orgName: string) => {
  if (app.data.name === 'Hopper User Management') {
    return 'App Access';
  }
  if (app.data.name === orgName) {
    return `${app.data.name} Database Access`;
  }
  return `${app.data.name} Access`;
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

interface Props {
  group: Group | null;
  apps: App[];
  orgId: string;
  orgName: string;
  canEdit: boolean;
  userStore: EntityContainer<User>;
  permissionStore: PermissionStore;
  handleDelete: (item: Group | null) => void;
}

export default function GroupProfile({
  group,
  apps,
  orgName,
  orgId,
  canEdit,
  permissionStore,
  userStore,
  handleDelete,
}: Props) {
  const name = group?.name || '';
  const groupId = group?.id;
  const [users, setUsers] = useState<User[]>([]);
  const [isDeleting, setDelete] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [inflightPermissions, setInflightPermissions] = useState<EntityContainer<boolean>>({});
  const [groupPermissions, setGroupPermissions] = useState<PermissionStore>({});

  const addLoading = async (loadingPermissionId: string) => {
    const loadingUpdates = {
      ...inflightPermissions,
      [loadingPermissionId]: true,
    };
    await setInflightPermissions(loadingUpdates);
  };

  const removeLoading = async (loadingPermissionId: string) => {
    const loadingUpdates = {
      ...inflightPermissions,
    };
    delete loadingUpdates[loadingPermissionId];
    await setInflightPermissions(loadingUpdates);
  };

  const onDeleteGroup = async () => {
    if (group && canEdit) {
      const response = await GroupApiProvider.deleteGroup(group.id);
      if (response.status === 200) {
        handleDelete(group);
        setDelete(false);
        notify('Success', `${group.name} successfully deleted from your organization`, 'success');
      } else {
        notify('Error Deleting Group', `Couldn't delete ${group.name}`, 'error');
      }
    }
  };

  const confirmDeleteGroup = () => {
    setShowDelete(true);
  };

  const handleRemoveUser = async (data: User) => {
    if (groupId && canEdit) {
      try {
        addLoading(data.entity_id);
        const userResponse = await UserApiProvider.getUserById(data.entity_id, orgId);
        const userData: User = userResponse.data.data;
        const userGroups = userData.data.groups || [];
        const removedGroupIds = userGroups.filter((gr: Group) => gr.id === groupId).map((gr: Group) => gr.id);
        const groupResonse = await UserApiProvider.removeGroups(data.entity_id, { group_ids: removedGroupIds });
        if (groupResonse.status === 200) {
          const updates = users.filter((us: User) => us.entity_id !== data.entity_id);
          setUsers(updates);
        } else {
          notify('Delete error', `Couldn't remove user from ${name}`, 'error');
        }
      } catch (error) {
        notify('Delete error', `Couldn't remove user from ${name}`, 'error');
      } finally {
        removeLoading(data.entity_id);
      }
    }
  };

  const handleAddUser = async (user: User) => {
    const isInGroup = users.find((us) => us.entity_id === user.entity_id);
    if (!isInGroup && groupId && canEdit) {
      try {
        const response = await UserApiProvider.addGroups(user.entity_id, { group_ids: [groupId] });
        if (response.status === 200) {
          const updatedUsers = [...users, user];
          setUsers(updatedUsers);
        } else {
          notify('Error', `Couldn't add ${user.data.name} to ${name}`, 'error');
        }
      } catch (error) {
        notify('Error', `Couldn't add ${user.data.name} to ${name}`, 'error');
      }
    }
  };

  const handleRemovePermissions = async (resourceId: string) => {
    // get permissions for resource
    const removed = groupPermissions[resourceId];
    if (removed && groupId && canEdit) {
      await addLoading(resourceId);
      const removedIds = removed.map((rp: RolePermission) => rp.name);
      const updatedPermissions = {
        ...groupPermissions,
      };
      delete updatedPermissions[resourceId];
      setGroupPermissions(updatedPermissions);
      try {
        const response = await GroupApiProvider.removePermissions(groupId, { permission_ids: removedIds });
        if (response.status >= 300) {
          notify('Error', `Couldn't remove permissions from ${resourceId}`, 'error');
        }
      } catch (error) {
        notify('Error', `Couldn't remove permissions from ${resourceId}`, 'error');
      } finally {
        removeLoading(resourceId);
      }
    }
  };

  const handleAddPermissions = async (resourceId: string) => {
    // get permissions for resource
    const permissions = permissionStore[resourceId] || [];
    const [defaultPermissions] = permissions.filter((rp: RolePermission) =>
      rp.name.includes(`${resourceId}:read:author`)
    );
    if (defaultPermissions && groupId && canEdit) {
      const updatedPermissions = {
        ...groupPermissions,
      };
      updatedPermissions[resourceId] = [defaultPermissions];
      setGroupPermissions(updatedPermissions);
      try {
        await addLoading(resourceId);
        const response = await GroupApiProvider.addPermissions(groupId, { permission_ids: [defaultPermissions.name] });
        if (response.status >= 300) {
          throw new Error('Error adding permissions');
        }
      } catch (error) {
        notify('Error', `Couldn't add permissions to ${resourceId}`, 'error');
      } finally {
        removeLoading(resourceId);
      }
    }
  };

  /**
   * Remove existing permissions for resource and replace with selected
   * @param resourceId resource id of the collection - ie automation, integration etc etc
   * @param access org | group | user
   * @param permission read | write
   */
  const handleUpdatePermissions = async (resourceId: string, permission: string, access: string) => {
    // get permissions for resource
    const permissions = permissionStore[resourceId] || [];
    const [defaultPermissions] = permissions.filter((rp: RolePermission) =>
      rp.name.includes(`${resourceId}:${permission}:${access}`)
    );
    if (defaultPermissions && groupId && canEdit) {
      const updatedPermissions = {
        ...groupPermissions,
      };
      updatedPermissions[resourceId] = [defaultPermissions];
      setGroupPermissions(updatedPermissions);
      try {
        await addLoading(resourceId);

        // 1. Remove existing group permissions
        const removedPermissions = groupPermissions[resourceId] || [];
        if (removedPermissions.length) {
          const removedIds = removedPermissions.map((rp: RolePermission) => rp.name);
          const removeResponse = await GroupApiProvider.removePermissions(groupId, {
            permission_ids: removedIds,
          });
          if (removeResponse.status >= 300) {
            throw new Error(`Couldn't update permissions on ${resourceId}`);
          }
        }

        // 2. Add new group permissions
        const addResponse = await GroupApiProvider.addPermissions(groupId, {
          permission_ids: [defaultPermissions.name],
        });
        if (addResponse.status >= 300) {
          throw new Error(`Couldn't update permissions on ${resourceId}`);
        }
      } catch (error) {
        notify('Error', `Couldn't update permissions on ${resourceId}`, 'error');
      } finally {
        removeLoading(resourceId);
      }
    }
  };

  useEffect(() => {
    const handleLoad = async (roleId: string) => {
      setLoading(true);
      setUsers([]);
      setGroupPermissions({});
      const data: Group = await loadGroupById(roleId);
      const userData = data?.users || [];
      userData.sort((a: User, b: User) => {
        const auser = a.data.name || '';
        const buser = b.data.name || '';
        return auser.localeCompare(buser);
      });
      const groupPermissionData = data?.permissions || [];
      const groupStore = createPermissionStore(groupPermissionData);
      setLoading(false);
      setGroupPermissions(groupStore);
      setUsers(userData);
    };
    if (groupId) {
      handleLoad(groupId);
    }
  }, [groupId]);
  return (
    <article>
      {/* Profile header */}
      <div>
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="mt-20 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                <h1 className="truncate text-2xl font-bold text-gray-900">{name}</h1>
              </div>
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">{name}</h1>
          </div>
        </div>
      </div>
      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="mt-12 border-b border-gray-200" />
      </div>
      {/* Description list */}
      <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {Object.keys(fields).map((field) => {
            const data: EntityContainer<string> = {
              name: group?.name || 'No name set',
              description: group?.description || 'No description set',
            };
            const val = data[field];
            return (
              <div key={field} className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(field)}</dt>
                <dd className="mt-1 text-sm text-gray-900">{val}</dd>
              </div>
            );
          })}
        </dl>
      </div>
      {isLoading ? (
        <div className="flex pt-32 w-full h-full items-center justify-center">
          <Spinner />
        </div>
      ) : null}
      {/* Group member list */}
      {!isLoading && (
        <GroupUsers
          title="Add Group Memebers"
          data={users}
          store={userStore}
          inFlight={inflightPermissions}
          isImage
          canEdit={canEdit}
          getName={(user) => user?.data.name || ''}
          getId={(user) => user?.entity_id || ''}
          getImg={(user) => user?.data.img_url || ''}
          getDetails={(user) => user?.data.email || ''}
          onAdd={handleAddUser}
          onRemove={handleRemoveUser}
        />
      )}
      {/* Collection permissions */}
      {!isLoading &&
        apps.map((app) => {
          const colData = app.data.collections || [];
          const collections = [...colData].sort((a, b) => {
            const aname = a.name || '';
            const bname = b.name || '';
            return aname.localeCompare(bname);
          });
          return (
            <div key={app.entity_id} className="mx-auto mt-12 max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
              <h2 className="text-sm font-medium text-gray-500 mb-4">{getAppName(app, orgName)}</h2>
              <CollectionPermissions
                orgName={orgName}
                groupName={group?.name || ''}
                groupPermissions={groupPermissions}
                collections={collections}
                inFlight={inflightPermissions}
                canEdit={canEdit}
                onRemove={handleRemovePermissions}
                onAdd={handleAddPermissions}
                onUpdate={handleUpdatePermissions}
              />
            </div>
          );
        })}
      {!isLoading && canEdit && (
        <DeleteProfile
          title="Delete Group"
          description="The group will be removed and group members will no longer be able to access the database configured above."
          onDelete={confirmDeleteGroup}
          isDeleting={isDeleting}
          data={group}
        />
      )}
      {showDelete ? (
        <DeleteDirectoryItemModal
          title="Delete Group"
          details={`Are you sure you want to delete ${
            group?.name || 'this'
          } group? All group members will no longer be able to access database items created by or shared with the group.`}
          open={showDelete}
          setOpen={setShowDelete}
          onDelete={onDeleteGroup}
        />
      ) : null}
    </article>
  );
}
