import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import automationsReducer from './slices/automations';
import collectionsReducer from './slices/collections';
import databaseReducer from './slices/database';
import groupsReducer from './slices/groups';
import integrationsReducer from './slices/integrations';
import organisationReducer from './slices/organisation';
import automationDetailsReducer from './slices/automation.details';
import reportsReducer from './slices/reports';
import usersReducer from './slices/users';
import {
  AUTH,
  AUTOMATIONS,
  INTEGRATIONS,
  DATABASE,
  GROUPS,
  ORGANISATION,
  USERS,
  AUTOMATION_DETAILS,
  COLLECTIONS,
  REPORTS,
} from './slices/slices';

const store = configureStore({
  reducer: {
    [AUTH]: authReducer,
    [AUTOMATIONS]: automationsReducer,
    [AUTOMATION_DETAILS]: automationDetailsReducer,
    [COLLECTIONS]: collectionsReducer,
    [DATABASE]: databaseReducer,
    [GROUPS]: groupsReducer,
    [INTEGRATIONS]: integrationsReducer,
    [ORGANISATION]: organisationReducer,
    [REPORTS]: reportsReducer,
    [USERS]: usersReducer,
  },
});

export default store;
