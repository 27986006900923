import React from 'react';
import { CollectionElement } from '../../redux/models/database.model';
import { getElementId } from '../grid/gridview.utils';

interface InputProps {
  name: string;
  options: CollectionElement[];
  value: CollectionElement | null | undefined;
  allowEmpty?: boolean;
  onChange: (element: CollectionElement | null | undefined) => void;
}

function SelectElement({ name, options, value, allowEmpty, onChange }: InputProps) {
  const selectedId = value ? getElementId(value) : '';
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const el = options.find((element) => getElementId(element) === id);
    onChange(el);
  };
  return (
    <select
      id={`select-element-${name}`}
      name={name}
      value={selectedId}
      onChange={handleChange}
      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
    >
      {allowEmpty && <option value="">[None]</option>}
      {options.map((element) => {
        const id = getElementId(element);
        return (
          <option key={id} value={id}>
            {element.name || ''}
          </option>
        );
      })}
    </select>
  );
}

SelectElement.defaultProps = {
  allowEmpty: false,
};

export default SelectElement;
