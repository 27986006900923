/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { cloneDeep } from 'lodash';
import { Entity } from '../models/auth.models';
import { CollectionElement } from '../models/database.model';
import { ELEMENT_TYPE_ID } from './element.models';

export const getFileName = (entityData: any, col: CollectionElement) => {
  return col.type.includes('file') ? entityData[col.property] : entityData[col.property].name || '';
};

/**
 * Should the POST body role_ids be applied to entity.roles ??
 * @param entity Entity
 * @param columns Collection element
 * @returns returns deep copy of the entity data with formatted image file name
 */
export const getInitalValues = (entity: Entity, columns: CollectionElement[]) => {
  const entityData = entity?.data || {};
  const vals = cloneDeep(entityData);
  const roles = entity?.roles || [];
  if (roles.length > 0) {
    vals.role_ids = roles;
  }
  columns.forEach((col) => {
    if (col.type === ELEMENT_TYPE_ID.file) {
      const val = getFileName(entityData, col);
      vals[col.property] = val;
    }
  });
  return vals;
};

export const isElementEqual = (a: CollectionElement, b: CollectionElement) => {
  return a.type === b.type && a.name === b.name && a.position === b.position && a.property === b.property;
};
