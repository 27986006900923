/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, Fragment } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from '../../utils/tailwind';
import { CollectionElementOption } from '../../redux/models/database.model';

interface DropdownCellProps {
  placeholder: string;
  width: string;
  minWidth: string;
  name: string;
  setFormikValue: any;
  reset: any;
  formikValue: any;
  setReset: any;
  options: CollectionElementOption[];
  single: boolean;
  isForm: boolean;
}

const getOptionName = (id: string, options: CollectionElementOption[], placeholder: string) => {
  const option = options.find((op) => op.id === id);
  return option?.name || placeholder;
};

export function DropdownCell({
  placeholder,
  width,
  minWidth,
  name,
  setFormikValue,
  reset,
  formikValue,
  setReset,
  options,
  single = false,
  isForm = false,
}: DropdownCellProps) {
  const [dropdownOptionsState, setDropdownOptionsState] = useState<CollectionElementOption[]>([]);
  const remainingOptions = options || [];
  const dropdownHeight = remainingOptions.length * 35 + 10;

  useEffect(() => {
    if (reset) {
      setDropdownOptionsState([]);
      setReset(false);
    }
  }, [setReset, reset]);

  const handleSelect = (e: any) => {
    const selectedName = e.target.textContent;
    const option = remainingOptions.find((op) => op.name === selectedName);
    if (option) {
      const optionId = option.id || '';
      const selectedIds = dropdownOptionsState.map((op) => op.id);
      const newArr = single ? optionId : [...selectedIds, optionId];
      setFormikValue(name, newArr);
      setDropdownOptionsState([...dropdownOptionsState, option]);
    }
  };

  const handleXClick = (option: CollectionElementOption) => {
    const newArr = dropdownOptionsState.filter((ele) => ele.id !== option.id);
    setDropdownOptionsState(newArr);
  };

  return (
    <div className="table-cell-row justify-between align-middle" style={{ width, minWidth }}>
      <div className="table-inner-cell flex-col items-start h-full">
        <div className="flex">
          {!single
            ? dropdownOptionsState.map((item) => {
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={item.id}
                    className="break-words hover:border text-sm rounded-md bg-cell-content text-cell-input-text m-1 px-1 flex items-center"
                  >
                    <span>{item.name}</span>
                    <FontAwesomeIcon
                      fixedWidth
                      height={24}
                      width={24}
                      icon={faXmark}
                      className="pr-1 cursor-pointer"
                      onClick={() => handleXClick(item)}
                    />
                  </div>
                );
              })
            : !isForm && !single && (dropdownOptionsState || '+')}
        </div>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-1 focus:ring-offset-2 focus:ring-offset-gray-100">
              {!single ? 'Options' : getOptionName(formikValue, remainingOptions, placeholder)}
              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{ height: `${dropdownHeight}px` }}
              className="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll"
            >
              <div className="py-1">
                {remainingOptions.length >= 1 ? (
                  remainingOptions.map((ele) => {
                    return (
                      <Menu.Item key={ele.id}>
                        {({ active }: any) => (
                          <button
                            type="button"
                            onClick={handleSelect}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm w-full text-left'
                            )}
                          >
                            {ele.name}
                          </button>
                        )}
                      </Menu.Item>
                    );
                  })
                ) : (
                  <Menu.Item>
                    {({ active }: any) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900 ' : ' text-gray-700',
                          'block px-4 py-2 text-sm w-full text-left opacity-40 cursor-default'
                        )}
                      >
                        No items
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

interface Input {
  type: string;
  placeholder: string;
  name: string;
  formikValue: string;
  required: boolean;
  formikChange: any;
  width: string;
  minWidth: string;
}

export function CellInput({ type, placeholder, name, formikValue, formikChange, required, width, minWidth }: Input) {
  return (
    <div style={{ width, minWidth }} className="">
      <input
        className="w-11/12 mx-1 rounded h-10 pl-2 placeholder:text-gray-400"
        data-cy={`edit_entity_input-${name}`}
        type={type}
        placeholder={`Enter ${placeholder}...`}
        name={name}
        value={formikValue}
        onChange={formikChange}
        required={required}
      />
    </div>
  );
}
