/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { XCircleIcon } from '@heroicons/react/20/solid';
import HopperServiceApiProvider from '../../../services/hopperServiceApiProvider';
import TableCell from '../../Body/Cells/TableCell/TableCell';
import { updateEntity, selectCollectionUrl, isEditable, setSelectedEntity } from '../../../redux/slices/database';
import DeleteModal from '../../Modal/DeleteModal';
import { ELEMENT_TYPE_ID } from '../../../redux/slices/element.models';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { notify } from '../../../components/shared/Notification';
import { ServerError } from '../../../redux/slices/core.models';
import TableMultiAttachmentCell from '../../Body/Cells/MultiAttachment/MultiAttachmentCell';
import TableRelCell from '../../Body/Cells/TableRelCell/TableRelCell';
import TableMultiRelCell from '../../Body/Cells/TableRelCell/TableMultiRelCell';
import { Entity } from '../../../redux/models/auth.models';
import { CollectionElement } from '../../../redux/models/database.model';
import TableDateTimeCell from '../../Body/Cells/TableDateTimeCell/TableDateTimeCell';
import { getCellWidth } from '../../Body/table.utils';
import TableDropdownCell from '../../Body/Cells/TableDropdownCell/TableDropdownCell';
import TableLinkCell from '../../Body/Cells/TableLinkCell/TableLinkCell';
import TableMultiCell from '../../Body/Cells/TableMultiCell/TableMultiCell';
import TableAttachmentCell from '../../Body/Cells/TableAttachmentCell/TableAttachmentCell';
import { getInitalValues, isElementEqual } from '../../../redux/slices/database.entity.utils';
import CheckboxCell from '../../Body/Cells/CheckboxCell/CheckboxCell';

interface Props {
  entity: Entity;
  col: CollectionElement;
  columns: CollectionElement[];
}

function DataCell({ entity, col, columns }: Props) {
  const dispatch = useAppDispatch();
  const initialValueData = getInitalValues(entity, columns);
  const collectionUrl = useSelector(selectCollectionUrl);
  const [initialValues, setInitialValues] = useState({ ...initialValueData });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const editable = useSelector(isEditable);
  const entityId = entity.entity_id;

  const openDelete = () => {
    setOpenDeleteModal(true);
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (!isEqual(initialValues, values)) {
        const updatedEntityData = { ...entity, data: values };
        try {
          const response = await HopperServiceApiProvider.updateEntity(
            `${collectionUrl}/${entityId}`,
            updatedEntityData
          );
          if (response.status < 300) {
            const { data } = response;
            const updatedValues = getInitalValues(data.data, columns);
            setInitialValues(updatedValues);
            dispatch(updateEntity(data.data));
          } else {
            if (response.status >= 500) {
              const error = ServerError;
              notify("Couldn't update record", error.message, 'error');
            } else {
              const message = response?.data?.description || '';
              notify("Couldn't update record", message, 'error');
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            const errorMessage = `Unable to update to items to Database`;
            // eslint-disable-next-line no-console
            console.error(errorMessage);
          }
        }
      }
    },
  });

  useEffect(() => {
    const isStateEqual = isEqual(initialValueData, initialValues);
    if (!isStateEqual) {
      setInitialValues(initialValueData);
      formik.setValues(initialValueData);
    }
  }, [initialValueData, entity, formik, initialValues]);
  return (
    <form
      className="table-columns"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          entityId={entity.entity_id}
          collectionUrl={collectionUrl}
        />
      )}
      {[col].map((element) => {
        const key = element.property;
        const data = {
          ...formik.values,
        };
        const value = data.hasOwnProperty(key) ? data[key] : '';
        const widthStyles = getCellWidth(element);
        const {
          bool,
          datetime,
          file,
          link,
          multi_file,
          multi_rel,
          multi_role,
          multi_select,
          multi_string,
          rel,
          role,
          select,
        } = ELEMENT_TYPE_ID;
        if (element.type === multi_string) {
          return (
            <TableMultiCell
              tagsValues={value}
              key={key}
              formikProperty={key}
              setFormikValue={formik.setFieldValue}
              submitFormik={formik.handleSubmit}
              editable={editable}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
            />
          );
        }
        if (element.type === multi_select || element.type === multi_role) {
          const options = columns.find((ele) => isElementEqual(ele, element))?.options || [];
          return (
            <TableDropdownCell
              options={options}
              dropdownOptions={value}
              key={key}
              formikProperty={key}
              setFormikValue={formik.setFieldValue}
              submitFormik={formik.handleSubmit}
              editable={editable}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
              single={false}
            />
          );
        }
        if (element.type === select || element.type === role) {
          const options = columns.find((ele) => isElementEqual(ele, element))?.options || [];
          return (
            <TableDropdownCell
              options={options}
              dropdownOptions={value}
              key={key}
              formikProperty={key}
              setFormikValue={formik.setFieldValue}
              submitFormik={formik.handleSubmit}
              single
              editable={editable}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
            />
          );
        }
        if (element.type === rel) {
          return (
            <TableRelCell
              errors={null}
              element={element}
              submitFormik={formik.handleSubmit}
              formikProperty={key}
              setFormikValue={formik.setFieldValue}
              formikValue={value}
              key={key}
              isForm={false}
              editable={editable}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
            />
          );
        }
        if (element.type === multi_rel) {
          return (
            <TableMultiRelCell
              element={element}
              submitFormik={formik.handleSubmit}
              formikProperty={key}
              setFormikValue={formik.setFieldValue}
              formikValue={value}
              key={key}
              editable={editable}
              isForm={false}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
            />
          );
        }
        if (element.type === datetime) {
          return (
            <TableDateTimeCell
              element={element}
              submitFormik={formik.handleSubmit}
              formikProperty={key}
              setFormikValue={formik.setFieldValue}
              formikValue={formik.values[key]}
              key={key}
              showPlaceholder={false}
              editable={editable}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
            />
          );
        }
        if (element.type === file) {
          return (
            <TableAttachmentCell
              queueId={`entity-${entityId}-table-cell-multi-${element.property}`}
              file={formik.values[key] || {}}
              key={key}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
            />
          );
        }
        if (element.type === multi_file) {
          return (
            <TableMultiAttachmentCell
              queueId={`entity-${entityId}-table-cell-multi-${element.property}`}
              formikProperty={key}
              setFormikValue={formik.setFieldValue}
              formikValue={formik.values[key] || {}}
              key={key}
              editable={editable}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
            />
          );
        }
        if (element.type === link) {
          return (
            <TableLinkCell
              key={key}
              value={formik.values[key]}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
            />
          );
        }
        if (element.type === bool) {
          return (
            <CheckboxCell
              key={key}
              width={widthStyles.width}
              minWidth={widthStyles.minWidth}
              placeholder={element.name}
              formikValue={formik.values[element.property]}
              editable
              setFormikValue={formik.setFieldValue}
              formikProperty={element.property}
              submitFormik={formik.handleSubmit}
            />
          );
        }
        return (
          <TableCell
            submitFormik={formik.handleSubmit}
            formikProperty={key}
            setFormikValue={formik.setFieldValue}
            formikValue={formik.values[key]}
            key={key}
            editable={editable}
            width={widthStyles.width}
            minWidth={widthStyles.minWidth}
          />
        );
      })}
    </form>
  );
}

export default DataCell;
