/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import KanbanColumn from './KanbanColumn';
import {
  saveEntity,
  selectCollection,
  selectCollectionUrl,
  selectDisplayGroup,
  selectIsDatabaseLoaded,
  updateEntity,
} from '../../redux/slices/database';
import KanbanCard from './KanbanCard';
import DropWrapper from './DropWrapper';
import { CollectionElementOption } from '../../redux/models/database.model';
import { ItemModel } from './Kanban.models';
import useAppDispatch from '../../hooks/useAppDispatch';
import Spinner from '../shared/Spinner';
import { Entity } from '../../redux/models/auth.models';
import { notify } from '../shared/Notification';
import { EntityContainer } from '../../redux/slices/core.models';
import Analytics from '../../utils/analytics';

interface Props {
  onEdit: () => void;
}

function KanbanBoard({ onEdit }: Props) {
  const dispatch = useAppDispatch();
  const groupData = useSelector(selectDisplayGroup);
  const collection = useSelector(selectCollection);
  const collectionUrl = useSelector(selectCollectionUrl);
  const isLoaded = useSelector(selectIsDatabaseLoaded);
  const element = groupData.group;
  const elements = collection?.elements || [];
  const visibleElements = elements.filter((col) => col.visible);
  const options = element?.options || [];
  const initialData = groupData.data.flatMap((gr) => gr.rows);
  const [entityData, setEntityData] = useState(initialData);
  const [inFlight, setInFlight] = useState<EntityContainer<boolean>>({});

  const handleInFlight = (entity: Entity) => {
    setInFlight((prevState) => ({
      ...prevState,
      [entity.entity_id]: true,
    }));
  };

  const handleOutFlight = (entity: Entity) => {
    setInFlight((prevState) => {
      const newState = {
        ...prevState,
      };
      delete newState[entity.entity_id];
      return newState;
    });
  };

  const handleUpdate = (updatedEntity: Entity) => {
    setEntityData((prevState) => {
      return prevState.map((entity) => {
        if (entity.entity_id === updatedEntity.entity_id) {
          return updatedEntity;
        }
        return entity;
      });
    });
  };
  const onDrop = async (item: ItemModel, _: any, option: CollectionElementOption) => {
    const savedItem = entityData.find((ent) => ent.entity_id === item.entity.entity_id);
    if (!element || !savedItem) return;
    const prop = element.property;
    const newGroupId = option.id;
    const updatedEntity: Entity = {
      ...savedItem,
      data: {
        ...savedItem.data,
        [prop]: newGroupId,
      },
    };
    handleUpdate(updatedEntity);
    try {
      handleInFlight(updatedEntity);
      const response = await saveEntity(collectionUrl, updatedEntity.entity_id, updatedEntity);
      if (response.status === 200 && response.data.data) {
        handleUpdate(response.data.data);
        dispatch(updateEntity(updatedEntity));
      } else {
        notify('', `Couldn't update ${element?.name || 'record'}.`, 'error');
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      handleOutFlight(updatedEntity);
    }
  };

  const moveItem = () => {};

  return (
    <div className="container mx-auto">
      {!isLoaded && <Spinner />}
      <DndProvider backend={HTML5Backend}>
        <div className="flex h-full px-4">
          {element &&
            options.map((option) => {
              const entities = entityData.filter((entity) => {
                const prop = element.property;
                return entity.data[prop] === option.id;
              });
              const primary = elements[0];
              const title = option.name;
              return (
                <DropWrapper key={option.id} option={option} element={element} onDrop={onDrop}>
                  <KanbanColumn key={title} title={title}>
                    {entities.map((entity, index) => (
                      <KanbanCard
                        key={entity.entity_id}
                        index={index}
                        entity={entity}
                        primary={primary}
                        elements={visibleElements}
                        inFlight={inFlight}
                        handleUpdateCard={moveItem}
                        onEdit={onEdit}
                      />
                    ))}
                  </KanbanColumn>
                </DropWrapper>
              );
            })}
        </div>
      </DndProvider>
    </div>
  );
}

export default KanbanBoard;
