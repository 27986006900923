/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import GridViewItem from '../grid/GridViewItem';
import { Entity } from '../../redux/models/auth.models';
import { CollectionElement } from '../../redux/models/database.model';
import { CARD_TYPE } from './Kanban.models';
import { EntityContainer } from '../../redux/slices/core.models';

interface Props {
  index: number;
  entity: Entity;
  primary: CollectionElement;
  elements: CollectionElement[];
  inFlight: EntityContainer<boolean>;
  handleUpdateCard: (dragIndex: number, hoverIndex: number) => void;
  onEdit: () => void;
}

function KanbanCard({ entity, index, primary, elements, inFlight, handleUpdateCard, onEdit }: Props) {
  const ref = useRef<any | null>(null);
  const isSaving = inFlight[entity.entity_id] || false;
  const [, drop] = useDrop(() => ({
    accept: CARD_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref?.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      handleUpdateCard(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  }));
  const [{ isDragging }, drag] = useDrag(() => ({
    type: CARD_TYPE,
    item: { type: CARD_TYPE, entity, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  drag(drop(ref));

  return (
    <div className="mb-4 w-full list-none" ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <GridViewItem entity={entity} elements={elements} primary={primary} isSaving={isSaving} onEdit={onEdit} />
    </div>
  );
}

export default KanbanCard;
