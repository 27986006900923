import React from 'react';
import { DocumentTextIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FileValues } from '../../TableCell.modals';
import AttachmentModalImage from './AttachmentModelImage';

interface Props {
  file: FileValues;
  idx: number;
  editable: boolean;
  handleOpen: (file: FileValues) => void;
  handleDelete: (idx: number) => void;
}

function AttachmentRowItem({ file, idx, editable, handleOpen, handleDelete }: Props) {
  const isImg = file.filetype === 'image/png' || file.filetype === 'image/jpg' || file.filetype === 'image/jpeg';
  const isPdf = file.filetype === 'application/pdf';
  return (
    <span className="flex items-center w-full py-2" key={idx + file.filetype}>
      <button type="button" onClick={() => handleOpen(file)}>
        <span className="flex items-center">
          {isImg && <AttachmentModalImage file={file} />}
          {isPdf && <DocumentTextIcon className="w-6 h-6 mr-2" />}
          <p className="text-xs text-gray-800 hover:text-gray-900 hover:font-medium">{file.name}</p>
          {!isPdf && !isImg && (
            <p className="text-xs text-gray-500">
              This file type is not currently being supported please contact support for more information
            </p>
          )}
        </span>
      </button>
      {editable && (
        <button type="button" onClick={() => handleDelete(idx)}>
          <XMarkIcon className="w-4 h-4 ml-2 text-gray-400 hover:text-gray-900" />
        </button>
      )}
    </span>
  );
}

export default AttachmentRowItem;
