/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Sidebar from '../../../components/sidebar/Sidebar';
import { initialPageLoad } from '../../../redux/slices/database';
import useAppDispatch from '../../../hooks/useAppDispatch';
import Navbar from '../../../components/navbar/Navbar';
import { getLocalOrganisation, getUserOrgByCode, persistOrganisation } from '../../../redux/slices/organisation';
import { Collection, Organisation } from '../../../redux/models/database.model';
import { loadGroups } from '../../../redux/slices/groups';
import PageHeader from '../../../components/headers/PageHeader';
import {
  loadCollection,
  saveCollection,
  selectCollection,
  selectIsCollectionLoading,
} from '../../../redux/slices/collections';
import Spinner from '../../../components/shared/Spinner';
import CollectionDetailsForm from './CollectionDetailsForm';

function CollectionDetails() {
  const dispatch: any = useAppDispatch();
  const { orgCode, collectionId }: any = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsCollectionLoading);
  const collection = useSelector(selectCollection);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const org: Organisation = getLocalOrganisation();
  const title = collection?.data?.name || '';
  const apps = org?.data.apps || [];
  const app = apps.find((ap) => ap.entity_id === collection?.data.app?.entity_id);

  const handleSave = (data: Collection) => {
    dispatch(saveCollection(data));
  };
  useEffect(() => {
    const loadOrg = async (code: string) => {
      const fetchedOrg = await getUserOrgByCode(code);
      if (fetchedOrg) {
        await dispatch(persistOrganisation(fetchedOrg));
        await dispatch(initialPageLoad());
      } else {
        navigate('/organisations');
      }
    };
    if (!org) {
      loadOrg(orgCode);
    }
  }, [org, orgCode, dispatch, searchParams, navigate]);

  useEffect(() => {
    const loadedOrgCode = org?.data.organization_code;
    if (loadedOrgCode) {
      dispatch(loadGroups(loadedOrgCode));
    }
  }, [org, dispatch, searchParams]);

  useEffect(() => {
    if (collectionId) {
      dispatch(loadCollection(collectionId));
    }
  }, [dispatch, collectionId]);

  return (
    <div className="min-h-full relative">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1">
          <PageHeader title={`Collection Details | ${title}`} />
          {isLoading && <Spinner />}
          <div className="px-8 py-12">
            {!isLoading && app && collection?.data && (
              <CollectionDetailsForm collection={collection} onSave={handleSave} app={app} />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default CollectionDetails;
