import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'; // ChevronDownIcon
import classNames from '../../../utils/tailwind';
import { EntityContainer } from '../../../redux/slices/core.models';

interface Props {
  name: string;
  access: string;
  setSelected: (access: string) => void;
}

const accessLevels = ['org', 'group', 'author'];

const getLabel = (permission: string) => {
  const labels: EntityContainer<string> = {
    org: 'Organization',
    group: 'Group',
    author: 'User',
  };
  return labels[permission];
};

export default function AccessLevelList({ name, access, setSelected }: Props) {
  return (
    <Listbox value={access} onChange={setSelected}>
      {({ open }) => (
        <div className="relative ml-4 mt-2 w-64">
          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
            <span className="block truncate">{`All ${getLabel(access)} ${name}`}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {accessLevels.map((permission) => (
                <Listbox.Option
                  key={permission}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-blue-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-8 pr-4'
                    )
                  }
                  value={permission}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                        {`All ${getLabel(permission)} ${name}`}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 left-0 flex items-center pl-1.5'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
