/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import React from 'react';
import { FormField as FormFieldModel, FormType } from './form.models';
import FormSelectInput from './FormFields/FormSelectInput';
import Form from './Form';
import RelSelectInput from './FormFields/RelSelectInput';
import FormTextInput from './FormFields/FormTextInput';
import DateService from '../../../../utils/dateService';
import { BadgeSelectOptions } from '../components/AutomationInput';
import MultiStringInput from './FormFields/MultiStringInput';
import { TextFieldToken } from './FormFields/FormTextInput.utils';
import FormTokenInput from './FormFields/FormTokenInput';

const daysOfMonth = DateService.getDaysOfMonth();

interface Props {
  field: FormFieldModel;
  idx: number;
  tokens: TextFieldToken[];
  onChange: (field: FormFieldModel, idx: number) => void;
}

function FormField({ field, idx, tokens, onChange }: Props) {
  const isSelect = field.type === FormType.Select || field.type === FormType.MultiSelect;
  const isEmbedded = field.type === FormType.Embedded;
  const isRel = field.type === FormType.Rel;
  const isNumber = field.type === FormType.Int;
  const isDate = field.type === FormType.DateTime || field.type === FormType.Time;
  const isString = field.type === FormType.String;
  const isMultiString = field.type === FormType.MultiString;
  const isDays = field.prop === 'days_of_month';

  const handleChange = (val: any) => {
    const updates: FormFieldModel = {
      ...field,
      value: val,
    };
    onChange(updates, idx);
  };
  const handleDateChange = (val: string) => {
    const iso = DateService.getISOString(val, true);
    const utcIso = DateService.getUtcISOString(iso);
    console.log(utcIso);
    handleChange(utcIso);
  };
  return (
    <div className="py-2">
      <p className="text-xs font-medium text-gray-700 mr-4">{field.name || ''}</p>
      {isSelect && (
        <FormSelectInput
          name={field.name}
          selectedId={field.value || ''}
          options={field.options || []}
          onChange={handleChange}
        />
      )}
      {isEmbedded && <Form form={field} tokens={tokens} onFormChange={handleChange} />}
      {isRel && field.rel_config && (
        <RelSelectInput
          name={field.name || ''}
          value={field.value}
          relConfig={field.rel_config}
          onChange={handleChange}
        />
      )}
      {isNumber && <FormTextInput name={field.name || ''} value={field.value} type="number" onChange={handleChange} />}
      {isDate && (
        <FormTextInput name={field.name || ''} value={field.value} type="datetime-local" onChange={handleDateChange} />
      )}
      {isDays && (
        <BadgeSelectOptions name="Repeats On" data={field.value || []} options={daysOfMonth} onChange={handleChange} />
      )}
      {isString && (
        <FormTokenInput name={field.name || ''} value={field.value} tokens={tokens || []} onChange={handleChange} />
      )}
      {isMultiString && <MultiStringInput name={field.name || ''} values={field.value} onChange={handleChange} />}
    </div>
  );
}

// FormField.defaultProps = {
//   tokens: [],
// };

export default FormField;
