import { EntityContainer } from '../../redux/slices/core.models';

export const DEFAULT_COLOURS = 'bg-blue-200 text-blue-900';
const COLOURS: EntityContainer<string> = {
  red: 'bg-red-200 text-red-900',
  pink: 'bg-pink-200 text-pink-900',
  orange: 'bg-orange-200 text-orange-900',
  yellow: 'bg-yellow-200 text-yellow-900',
  green: 'bg-green-200 text-green-900',
  lime: 'bg-lime-200 text-lime-900',
  teal: 'bg-teal-200 text-teal-900',
  cyan: 'bg-cyan-200 text-cyan-900',
  sky: 'bg-sky-200 text-sky-900',
  blue: 'bg-blue-200 text-blue-900',
  indigo: 'bg-indigo-200 text-indigo-900',
  gray: 'bg-gray-200 text-gray-900',
  black: 'bg-black-200 text-black-900',
};

export const LABEL_COLOUR_KEYS = Object.keys(COLOURS);

export const getLabelColour = (text: string | null) => {
  const colour = text || 'blue';
  return COLOURS[colour] || DEFAULT_COLOURS;
};
