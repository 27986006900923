/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable  no-restricted-syntax */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { decodeToken } from 'react-jwt';
import {
  getValueFromLocalStorage,
  getValueFromSessionStorage,
  removeValueFromSessionStorage,
  saveValueToLocalStorage,
  saveValueToSessionStorage,
  setOrgCode,
} from '../../utils/core.utils';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import { ALLCOLLECTIONS, ENTITYURL, SIDEBAR } from '../../constants/core.contants';
import { Organisation } from '../models/database.model';
import UserApiProvider from '../../services/UserApiProvider';
import { getInitialState } from '../models/organisation.models';
import { ORGANISATION } from './slices';
import { AppState } from '../models/app.models';
import OrganisationApiProvider from '../../services/OrganisationApiProvider';
import { LOCAL_STORAGE_TOKEN_KEY } from '../../config';
import Analytics from '../../utils/analytics';

const SLICE_NAME = ORGANISATION;
const MULTI_ORG_KEY = 'multiOrg';
const FEATURE_FLAG_KEY = 'feature-flags';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: getInitialState(),
  reducers: {
    setOrganisation(state, action) {
      state.data = action.payload;
    },
    setIsOrganisationLoaded(state, action) {
      state.isOrganisationLoaded = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.errorOnLoadingData = action.payload;
    },
  },
});

export const { setOrganisation, setIsOrganisationLoaded, setErrorOnLoadingData } = slice.actions;

export const loadOrganisation: any = (organisationId: string) => async (dispatch: any) => {
  dispatch(setIsOrganisationLoaded(false));
  dispatch(setErrorOnLoadingData(false));
  try {
    const { data } = await HopperServiceApiProvider.getOrganisation(organisationId);
    const organisation = data?.data || {};
    console.log(organisation);
    const orgCode = organisation.data.organization_code || '';
    setOrgCode(orgCode);
    removeValueFromSessionStorage(SLICE_NAME);
    removeValueFromSessionStorage(SIDEBAR);
    removeValueFromSessionStorage(ALLCOLLECTIONS);
    removeValueFromSessionStorage(ENTITYURL);
    saveValueToSessionStorage(SLICE_NAME, organisation);
    dispatch(setOrganisation(organisation));
  } catch (error) {
    dispatch(setErrorOnLoadingData(true));
    Analytics.capture(error);
  } finally {
    dispatch(setIsOrganisationLoaded(true));
  }
};

export const userOrganisations = async () => {
  try {
    const { data } = await UserApiProvider.getUserOrganisations();
    const orgs: Organisation[] = data?.data || [];
    const isMultiOrg = orgs.length > 1;
    saveValueToLocalStorage(MULTI_ORG_KEY, isMultiOrg);
    return orgs.sort((a: Organisation, b: Organisation) => a.data.name.localeCompare(b.data.name));
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = `Couldn't get Organisation. ${error.message}. Please contact support if the problem persists.`;
      throw new Error(errorMessage);
    }
  }
};

export const getUserOrgByCode = async (orgCode: string) => {
  try {
    const { data } = await UserApiProvider.getUserOrganisations();
    const orgs: Organisation[] = data?.data || [];
    const isMultiOrg = orgs.length > 1;
    saveValueToLocalStorage(MULTI_ORG_KEY, isMultiOrg);
    return orgs.find((a: Organisation) => a.data.organization_code === orgCode);
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = `Couldn't get Organisation. ${error.message}. Please contact support if the problem persists.`;
      throw new Error(errorMessage);
    }
  }
};

export const getIsMultiOrgUser = (): boolean => {
  return getValueFromLocalStorage(MULTI_ORG_KEY);
};

export const getLocalOrganisation = (): Organisation => {
  return getValueFromSessionStorage(SLICE_NAME);
};

export const saveLocalOrganisation = (organisation: Organisation) => {
  saveValueToSessionStorage(SLICE_NAME, organisation);
};

export const persistOrganisation: any = (organisation: Organisation) => async (dispatch: any) => {
  const orgCode = organisation.data.organization_code || '';
  setOrgCode(orgCode);
  removeValueFromSessionStorage(SLICE_NAME);
  removeValueFromSessionStorage(SIDEBAR);
  removeValueFromSessionStorage(ALLCOLLECTIONS);
  removeValueFromSessionStorage(ENTITYURL);
  saveValueToSessionStorage(SLICE_NAME, organisation);
  dispatch(setOrganisation(organisation));
};

export const getLocalFeatureFlags = () => {
  return getValueFromLocalStorage(FEATURE_FLAG_KEY);
};

const arrayOverlap = (array1: Array<any>, array2: Array<any>) => {
  const set2 = new Set(array2);
  for (let i = 0; i < array1.length; i++) {
    if (set2.has(array1[i])) {
      return true;
    }
  }
  return false;
};

const isEnabled = (feature: any) => {
  if (feature === undefined || feature === null) return false;
  if (Object.keys(feature).length === 0) return false;
  const accessToken = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
  const decoded: any = decodeToken(accessToken);

  const userResources = decoded?.permissions || [];
  const userOrgs = decoded?.orgs || [];
  const userId = decoded?.sub || '';

  const featureOrgData = feature?.organizations || [];
  const featureOrgs = featureOrgData.map((org: any) => org.data?.organization_code);
  const featureResources = feature?.resource_ids || [];
  const featureUserData = feature?.users || [];
  const featureUsers = featureUserData.map((user: any) => user.data.auth0_id);
  const isOrgsEnabled = arrayOverlap(featureOrgs, userOrgs);
  const isResourcesEnabled = arrayOverlap(featureResources, userResources);
  const isUsersEnabled = featureUsers.includes(userId);
  return isOrgsEnabled || isResourcesEnabled || isUsersEnabled;
};

export const fetchFeatureFlags = async () => {
  const { data } = await OrganisationApiProvider.getFeatureFlags();
  const featureReponse = data?.data?.items || [];
  const featurData = featureReponse.reduce((store: any, feature: any) => {
    const key = feature?.data?.key || '';
    const gd = feature?.data || {};
    return {
      ...store,
      [key]: {
        ...gd,
        is_enabled: isEnabled(gd),
      },
    };
  }, {});
  saveValueToLocalStorage(FEATURE_FLAG_KEY, featurData);
  return featurData;
};

const organisationReducer = slice.reducer;
export default organisationReducer;
export const allState = (state: AppState) => state[SLICE_NAME];
export const selectOrganisation = (state: AppState) => state[SLICE_NAME].data;
export const isOrganisationLoaded = (state: AppState) => state[SLICE_NAME].isOrganisationLoaded;
export const errorOnLoadingData = (state: AppState) => state[SLICE_NAME].errorOnLoadingData;
