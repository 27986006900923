/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid';
import { DebouncedFunc } from 'lodash';

import classNames from '../../../utils/tailwind';
import { Tab } from '../models';

interface Props {
  title: string;
  details: string;
  tabs: Tab[];
  create: boolean;
  handleAddUser: () => void;
  handleAddGroup: () => void;
  handleSelectTab: (tab: Tab) => void;
  handleSearch: DebouncedFunc<(e: React.ChangeEvent<HTMLInputElement>) => void>;
}

export default function DirectoryHeader({
  title,
  details,
  tabs,
  create,
  handleSelectTab,
  handleAddUser,
  handleAddGroup,
  handleSearch,
}: Props) {
  return (
    <div className="px-6 pt-6 pb-4">
      <h2 className="text-lg font-medium text-gray-900" data-cy={`${title}-page-header`}>
        {title}
      </h2>
      <p className="mt-1 text-sm text-gray-600">{details}</p>
      {create ? (
        <div className="flex mt-4">
          <span className="hidden sm:block">
            <button
              type="button"
              onClick={handleAddUser}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              User
            </button>
          </span>
          <span className="ml-3 hidden sm:block">
            <button
              type="button"
              onClick={handleAddGroup}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Group
            </button>
          </span>
        </div>
      ) : null}
      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="border-b border-gray-200">
          <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab: Tab) => (
                <button
                  key={tab.name}
                  type="button"
                  onClick={() => handleSelectTab(tab)}
                  className={classNames(
                    tab.current
                      ? 'border-blue-500 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <form className="mt-6 flex gap-x-4" action="app.gethopper.io">
        <div className="min-w-0 flex-1">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="search"
              name="search"
              id="search"
              onChange={handleSearch}
              className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              placeholder="Search"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
