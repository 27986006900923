import React, { useState } from 'react';
import { PermissionStore } from '../../../redux/models/auth.models';
import { EntityContainer } from '../../../redux/slices/core.models';
import CollectionPermissionItem from './CollectionPermissionItem';
import { CollectionPermission } from './models';

interface Props {
  groupPermissions: PermissionStore;
  orgName: string;
  groupName: string;
  collections: CollectionPermission[];
  inFlight: EntityContainer<boolean>;
  canEdit: boolean;
  onRemove: (resourceId: string) => void;
  onAdd: (resourceId: string) => void;
  onUpdate: (resourceId: string, permission: string, access: string) => void;
}

const isResourceEnabled = (collection: CollectionPermission, groupPermissions: PermissionStore) => {
  const resource = collection?.resource_id || '';
  const enabled = groupPermissions[resource] !== undefined && groupPermissions[resource] !== null;
  return enabled;
};

export default function CollectionPermissions({
  groupPermissions,
  collections,
  orgName,
  groupName,
  inFlight,
  canEdit,
  onRemove,
  onAdd,
  onUpdate,
}: Props) {
  const defaultPermissions = collections.map((col: CollectionPermission) => ({
    ...col,
    enabled: isResourceEnabled(col, groupPermissions),
  }));
  const readPermissions = canEdit ? defaultPermissions : defaultPermissions.filter((col) => col.enabled);
  const [cols, setCollections] = useState<CollectionPermission[]>(readPermissions);

  const handleSetEnabled = (collection: CollectionPermission) => {
    const enabled = !collection.enabled;
    const resourceId = collection.resource_id;
    if (!resourceId || !canEdit) {
      return;
    }
    const updates = cols.map((col: CollectionPermission) => ({
      ...col,
      enabled: col.name === collection.name ? enabled : col.enabled,
    }));
    setCollections(updates);
    if (!enabled) {
      onRemove(resourceId);
    } else {
      onAdd(resourceId);
    }
  };
  return (
    <div>
      {cols.map((collection: CollectionPermission) => (
        <CollectionPermissionItem
          key={collection.entity_id}
          collection={collection}
          groupPermissions={groupPermissions}
          orgName={orgName}
          groupName={groupName}
          inFlight={inFlight}
          canEdit={canEdit}
          handleSetEnabled={handleSetEnabled}
          onUpdate={onUpdate}
        />
      ))}
    </div>
  );
}
