import { CreateUserProps } from '../redux/models/auth.models';
import { axiosInstance } from '../utils/axios';
import HopperServiceConfigProvider from './hopperServiceConfigProvider';

class UserApiProvider {
  static getUserOrganisations() {
    return axiosInstance.get(HopperServiceConfigProvider.getUserOrganizations());
  }

  static getUserData() {
    return axiosInstance.get(HopperServiceConfigProvider.getUserData());
  }

  static getUsers(organizationId: string) {
    return axiosInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.getUsers(organizationId),
      validateStatus: () => true,
    });
  }

  static getUserById(userId: string, orgId: string) {
    return axiosInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.userDetailsById(userId, orgId),
      validateStatus: () => true,
    });
  }

  static removeGroups(userId: string, data: { group_ids: string[] }) {
    return axiosInstance({
      method: 'DELETE',
      url: HopperServiceConfigProvider.userGroups(userId),
      validateStatus: () => true,
      data,
    });
  }

  static removePermissions(userId: string, data: { permission_ids: string[] }) {
    return axiosInstance({
      method: 'DELETE',
      url: HopperServiceConfigProvider.userPermissions(userId),
      validateStatus: () => true,
      data,
    });
  }

  static addGroups(userId: string, data: { group_ids: string[] }) {
    return axiosInstance({
      method: 'POST',
      url: HopperServiceConfigProvider.userGroups(userId),
      validateStatus: () => true,
      data,
    });
  }

  static create(data: CreateUserProps) {
    return axiosInstance({
      method: 'POST',
      url: HopperServiceConfigProvider.user(),
      validateStatus: () => true,
      data,
    });
  }

  static delete(userId: string) {
    return axiosInstance({
      method: 'DELETE',
      url: HopperServiceConfigProvider.userById(userId),
      validateStatus: () => true,
    });
  }
}

export default UserApiProvider;
