import { Entity } from '../../redux/models/auth.models';
import { CollectionElement } from '../../redux/models/database.model';
import { ELEMENT_TYPE_ID } from '../../redux/slices/element.models';
import { searchValues } from '../../redux/slices/database.utils';

export const getPrimaryElement = (elements: CollectionElement[], prop: string) => {
  const name = elements.find((nameEl) => nameEl.name.includes(prop));
  if (name) return name;
  return elements[0];
};

export const getElement = (elements: CollectionElement[], prop: string) => {
  return elements.find((nameEl) => nameEl.name.includes(prop));
};

export const getElementType = (elements: CollectionElement[], type: string) => {
  return elements.find((nameEl) => nameEl.type === type);
};

export const getElementId = (el: CollectionElement) => {
  return `${el.name}-${el.position}-${el.property}-${el.type}`;
};

export const getFileElement = (elements: CollectionElement[]) => {
  return elements.find((el) => el.type === ELEMENT_TYPE_ID.file || el.type === ELEMENT_TYPE_ID.multi_file);
};

export const getImgEntity = (entities: Entity[], fileElement: CollectionElement) => {
  return entities.find((ent) => {
    const file = ent.data[fileElement.property];
    return file?.filetype === 'image/png' || file?.filetype === 'image/jpg' || file?.filetype === 'image/jpeg';
  });
};

export const getRows = (search: string, rows: Entity[]) => {
  if (search === '') {
    return rows;
  }
  return rows.filter((row) => searchValues(row, search));
};
