import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FooterCalculation, FooterItem } from '../../../redux/models/database.model';

interface FooterLabel {
  name: string;
  details: string;
}

export default function CalculationsFlyOutMenu({
  item,
  index,
  onCalculate,
}: {
  item: FooterItem;
  index: number;
  onCalculate: (index: number, calculation: FooterCalculation) => string;
}) {
  const { isSupported, calculations } = item;
  const [details, setDetails] = useState<FooterLabel>({ name: item.title || '', details: item.detail || '' });
  const handleCalculation = (calculation: FooterCalculation, close: () => void) => {
    if (calculation.id === 'none') {
      setDetails({ name: 'Calculate', details: '' });
    } else {
      const name = onCalculate(index, calculation);
      setDetails({ name, details: calculation.name.toUpperCase() });
    }
    close();
  };
  return (
    <Popover className="relative">
      {() => (
        <>
          <Popover.Button>
            <div className="flex justify-end items-center">
              <p className="text-xs font-light pr-2">{details.details || ''}</p>
              <p className="text-xs font-medium">{details.name || ''}</p>
              {isSupported ? (
                <ChevronDownIcon className="text-gray-500 ml-1 h-3 w-3 group-hover:text-gray-500" />
              ) : null}
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="relative left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
              {({ close }) => (
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    {calculations.map((element: FooterCalculation) => (
                      <button
                        key={element.name}
                        type="button"
                        onClick={() => handleCalculation(element, close)}
                        className="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50 text-left"
                      >
                        <p className="text-sm font-medium text-gray-900">{element.name}</p>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
