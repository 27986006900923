/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props {
  value: any;
  width: string;
  minWidth: string;
}

export default function TableLinkCell({ value, width, minWidth }: Props) {
  return (
    <div className="table-cell-row relative truncate" style={{ minWidth, width }}>
      <div className="table-inner-cell truncate">
        <a href={value} target="_blank" rel="noreferrer">
          <p className="flex items-center hover:text-gray-600 w-full text-cell-input-text text-sm min-h-[25px] min-w-[25px] truncate">
            {value}
          </p>
        </a>
      </div>
    </div>
  );
}
