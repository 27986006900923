import React from 'react';
import classNames from '../../../utils/tailwind';

interface Props {
  name: string;
  imgUrl: string;
  isImage: boolean;
  selected: boolean;
}

export default function DirectoryItemSearchResult({ name, imgUrl, isImage, selected }: Props) {
  return (
    <div className="flex items-center">
      {isImage ? <img src={imgUrl} alt={name} className="h-6 w-6 flex-shrink-0 rounded-full" /> : null}
      <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{name}</span>
    </div>
  );
}
