import React from 'react';
import { BoltIcon } from '@heroicons/react/24/outline';

interface Props {
  handleAdd: () => void;
}

export default function AddAutomationButton({ handleAdd }: Props) {
  return (
    <button
      type="button"
      onClick={handleAdd}
      className="mt-12 flex flex-col items-center justify-center relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400"
    >
      <BoltIcon className="h-12 w-12 text-gray-400" aria-hidden="true" />
      <span className="mt-2 block text-sm font-semibold text-gray-900">Add Automation Step</span>
    </button>
  );
}
