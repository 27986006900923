/* eslint-disable @typescript-eslint/no-explicit-any */
interface FileData {
  filetype: string;
  name: string;
  path: string;
}

interface FileList {
  data: FileData[];
}
export const imageDataConvert = (fileName: string, fileObj: FileList): FileData => {
  return {
    ...fileObj.data[0],
    path: fileObj.data[0].name,
    name: fileName,
  };
};

export const downloadDataConvert = (formikValue: any) => {
  const data = [{ ...formikValue, name: formikValue.path }];
  delete data[0].path;
  return data;
};

export const updateDataConvert = (uploadObj: any) => {
  const data = [{ ...uploadObj }];
  delete data[0].path;
  return data;
};
