import { Entity } from '../../redux/models/auth.models';
import { TableData } from '../../redux/models/database.model';

export const CARD_TYPE = 'CARD';

export interface KanbanColumnModel {
  id: string;
  title: string;
  group: TableData;
}

export interface ItemModel {
  type: string;
  entity: Entity;
  index: number;
}
