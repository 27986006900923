/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, JSXElementConstructor } from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { CollectionElement, CollectionElementOption } from '../../redux/models/database.model';
import { CARD_TYPE, ItemModel } from './Kanban.models';

interface Props {
  option: CollectionElementOption;
  element: CollectionElement;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  onDrop: (item: any, monitor: DropTargetMonitor<unknown, unknown>, option: CollectionElementOption) => void;
}

export default function DropWrapper({ option, element, children, onDrop }: Props) {
  const [{ isOver }, drop] = useDrop({
    accept: CARD_TYPE,
    canDrop: (item: ItemModel) => {
      const val = item.entity.data[element.property];
      const options = element.options || [];
      const itemIndex = options.findIndex((op) => op.id === val);
      const statusIndex = options.findIndex((op) => op.id === option.id);
      const can = [itemIndex + 1, itemIndex - 1, itemIndex].includes(statusIndex);
      console.log('canDrop: ', can);
      return true;
    },
    drop: (item, monitor) => {
      onDrop(item, monitor, option);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className="drop-wrapper">
      {React.cloneElement(children, { isOver })}
    </div>
  );
}
