import React from 'react';
import classNames from '../../../../utils/tailwind';
import { getLabelColour } from '../../colours';
import { CollectionElementOption } from '../../../../redux/models/database.model';

export default function SelectLabel({ option }: { option: CollectionElementOption | undefined }) {
  const labelClass =
    'inline-flex items-baseline rounded-full whitespace-nowrap overflow-x-hidden px-2.5 py-0.5 text-xs font-medium md:mt-2 lg:mt-0';
  const colour = option?.color || 'blue';
  const labelColour = getLabelColour(colour);
  return <p className={classNames(labelColour, labelClass)}>{option?.name || '+'}</p>;
}
