/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ArrowsPointingOutIcon } from '@heroicons/react/20/solid';
import { Entity } from '../../../redux/models/auth.models';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { setSelectedEntity } from '../../../redux/slices/database';

interface Props {
  entity: Entity;
  index: number;
  onEdit: () => void;
}

export default function DataCellMenu({ entity, index, onEdit }: Props) {
  const dispatch = useAppDispatch();
  const [_, setSearchParams] = useSearchParams();
  const openModal = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await dispatch(setSelectedEntity(entity));
    setSearchParams({ entity_id: entity.entity_id });
    onEdit();
  };
  return (
    <div className="table-column-key">
      <button
        data-cy={`collection_table-entity_row-open_btn_${index}`}
        type="button"
        className="text-blue-600 flex items-center"
        onClick={openModal}
      >
        <ArrowsPointingOutIcon className="row-buttons h-5 w-5 " aria-hidden="true" />
        <span className="row-button-key">{`${index + 1}`}</span>
      </button>
    </div>
  );
}
