import React, { useState } from 'react';
import Analytics from '../../../../utils/analytics';
import Spinner from '../../../../components/shared/Spinner';

interface Props {
  name: string;
  details: string;
  logoUrl: string;
  isOpen: boolean;
  children: React.ReactNode;
  handleOpen: () => void;
  handleClose: () => void;
  handleSave: () => void;
  handleDelete?: () => void;
}

function AutomationFormContainer({
  name,
  details,
  logoUrl,
  isOpen,
  children,
  handleOpen,
  handleClose,
  handleSave,
  handleDelete,
}: Props) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const onDelete = async () => {
    if (!handleDelete) return;
    try {
      setIsDeleting(true);
      await handleDelete();
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsDeleting(false);
    }
  };
  const onSave = async () => {
    try {
      setIsSaving(true);
      await handleSave();
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <div className="relative flex space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-gray-300 hover:border-gray-400">
      <div className="flex-shrink-0">
        <img className="h-10 w-10 rounded-full" src={logoUrl} alt="" />
      </div>
      <div className="w-full">
        <button type="button" onClick={handleOpen} className="focus:outline-none items-center">
          <h2 className="text-xl font-semibold text-gray-900">{name}</h2>
        </button>
        <p className="truncate text-sm text-gray-500">{details}</p>
        {isOpen && (
          <div className="w-full mt-6">
            {children}
            <div className="mt-6 flex items-center justify-between gap-x-6">
              {handleDelete ? (
                <button
                  onClick={onDelete}
                  type="button"
                  className="text-sm px-2 py-1 rounded-md font-semibold leading-6 text-red-500 ring-1 ring-inset ring-red-500 hover:bg-red-500 hover:text-white"
                >
                  {!isDeleting && 'Delete'}
                  {isDeleting && <Spinner size="small" />}
                </button>
              ) : (
                <div />
              )}
              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  onClick={handleClose}
                  type="button"
                  className="text-sm px-2 py-1 font-semibold leading-6 text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={onSave}
                  className="rounded-md px-2 py-1 bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  {!isSaving && 'Save'}
                  {isSaving && <Spinner size="small" />}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

AutomationFormContainer.defaultProps = {
  handleDelete: undefined,
};

export default AutomationFormContainer;
