/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import { updateEntity, isEditable } from '../../redux/slices/database';
import SingleInfoModal from './SingleInfoModal';
import useAppDispatch from '../../hooks/useAppDispatch';
import { notify } from '../../components/shared/Notification';
import { ServerError } from '../../redux/slices/core.models';
import { Collection } from '../../redux/models/database.model';
import { getInitalValues } from '../../redux/slices/database.entity.utils';
import { Entity } from '../../redux/models/auth.models';
import Analytics from '../../utils/analytics';

interface Props {
  entity: Entity | null;
  collection: Collection | null;
  isLoading: boolean;
  isOpen: boolean;
  isRel: boolean;
  onReload: (entity: Entity) => void;
  handleUpdate: (entity: Entity) => void;
  onClose: () => void;
}

// Add selected entity to redux
function EditEntityModal({ entity, collection, isLoading, isOpen, isRel, onReload, handleUpdate, onClose }: Props) {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const elements = useMemo(() => {
    return collection?.data.elements || [];
  }, [collection]);
  const baseUrl = collection?.data.app?.data.base_url || '';
  const path = collection?.data.path || '';
  const collectionUrl = `${baseUrl}${path}`;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInFlight, setInFlight] = useState(false);
  const vals = entity ? getInitalValues(entity, elements) : {};
  const [initialValues, setInitialValues] = useState(vals);
  const editable = useSelector(isEditable);
  const entityId = entity?.entity_id || '';
  const formik = useFormik({
    validateOnMount: Object.values(initialValues).length !== 0,
    initialValues,
    onSubmit: async (values) => {
      if (!isEqual(initialValues, values) && entity && !isInFlight) {
        const updatedEntityData = { ...entity, data: values };
        try {
          setInFlight(true);
          const response = await HopperServiceApiProvider.updateEntity(
            `${collectionUrl}/${entityId}`,
            updatedEntityData
          );
          if (response.status < 300) {
            const { data } = response;
            const updatedValues = getInitalValues(data.data, elements);
            setInitialValues(updatedValues);
            // if (isRel) {
            //   dispatch(updateRelEntity({ entity: data.data, collectionId: collection?.entity_id || '' }));
            // } else {
            //   dispatch(updateEntity(data.data));
            // }
            if (!isRel) dispatch(updateEntity(data.data));
            handleUpdate(data.data);
          } else {
            if (response.status >= 500) {
              const error = ServerError;
              notify("Couldn't update record", error.message, 'error');
            } else {
              const message = response?.data?.description || '';
              notify("Couldn't update record", message, 'error');
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            const errorMessage = `Unable to update to item`;
            // eslint-disable-next-line no-console
            console.error(errorMessage);
          }
        } finally {
          setInFlight(false);
          Analytics.editRecord(orgCode);
        }
      }
    },
  });

  useEffect(() => {
    const load = async () => {
      if (isLoaded === false && isLoading === false && entity && Object.values(formik.values).length === 0) {
        await setIsLoaded(true);
        const initVals = getInitalValues(entity, elements);
        await setInitialValues(initVals);
        formik.setValues(initVals);
      }
    };
    load();
  }, [entity, isLoading, isLoaded, elements, formik]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <SingleInfoModal
        title={collection?.data.name || ''}
        isLoading={isLoading}
        inputValues={elements}
        open={isOpen}
        onReload={onReload}
        onClose={onClose}
        formik={formik}
        isSaving={isInFlight}
        entityId={entity?.entity_id || ''}
        collectionUrl={collectionUrl}
        editable={editable}
      />
    </form>
  );
}

export default EditEntityModal;
