/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ListItem from './ListItem';
import { Organisation } from '../../../redux/models/database.model';

interface Props {
  organisations: Organisation[];
  onSelect: (org: Organisation) => void;
}

export default function OrganisationsList({ organisations, onSelect }: Props) {
  return (
    <div className="bg-white overflow-y-scroll shadow sm:rounded-md w-5/6 max-w-2xl">
      <ul className="divide-y divide-gray-200">
        {organisations.map((organisation) => (
          <ListItem key={organisation.entity_id} organisation={organisation} onSelect={onSelect} />
        ))}
      </ul>
    </div>
  );
}
