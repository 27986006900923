/* eslint-disable */
import React from 'react';
import { XMarkIcon, PlusSmallIcon } from '@heroicons/react/20/solid';
import classNames from '../../../../utils/tailwind';

interface Props {
  title: string;
  editable: boolean;
  showClose?: boolean;
  showAdd?: boolean;
  onOpen: () => void;
  onClose?: () => void;
  onAdd?: () => void;
}

function RelButton({ title, editable, showClose, showAdd, onOpen, onClose, onAdd }: Props) {
  const extraBtn = showClose || showAdd;
  const handleOpen = () => {
    if (!editable) return;
    onOpen();
  };
  const handleClose = () => {
    if (!editable) return;
    if (onClose) onClose();
  };
  const handleAdd = () => {
    if (!editable) return;
    if (onAdd) onAdd();
  };
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        onClick={handleOpen}
        className={classNames(
          extraBtn ? 'rounded-l-md' : 'rounded-md',
          'relative inline-flex items-center gap-x-1.5 bg-gray-100 hover:bg-gray-50 px-2 py-1 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
        )}
      >
        {title}
      </button>
      {editable && showClose && (
        <button
          type="button"
          onClick={handleClose}
          className="relative -ml-px inline-flex items-center rounded-r-md bg-gray-100 hover:bg-gray-50 px-2 py-1 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <XMarkIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      )}
      {editable && showAdd && (
        <button
          type="button"
          onClick={handleAdd}
          className="relative -ml-px inline-flex items-center rounded-r-md bg-gray-100 hover:bg-gray-50 px-2 py-1 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <PlusSmallIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      )}
    </span>
  );
}

RelButton.defaultProps = {
  showClose: false,
  showAdd: false,
  onClose: undefined,
  onAdd: undefined,
};

export default RelButton;
