/* eslint-disable react/require-default-props */
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import Spinner from '../../../components/shared/Spinner';

function OptionMenu<T>({ data, onDelete }: { data: T; onDelete: (data: T) => void }) {
  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md pl-4 py-2 text-sm font-medium text-gray-600 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <EllipsisVerticalIcon
              className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                <button
                  type="button"
                  onClick={() => onDelete(data)}
                  className="bg-violet-500 text-gray-900 text-gray-600 group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-gray-100 hover:text-red-600"
                >
                  Remove
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

interface Props<T> {
  name: string;
  details: string;
  imgUrl?: string;
  data: T;
  isLoading?: boolean;
  canEdit: boolean;
  onDelete: (data: T) => void;
}

export default function ProfileRelsCard<T>({
  name,
  details,
  imgUrl,
  data,
  isLoading = false,
  canEdit,
  onDelete,
}: Props<T>) {
  return (
    <div className="mt-2 rounded-lg space-x-3 px-6 py-5 border border-gray-300 bg-white shadow-sm hover:border-gray-400">
      <div className="relative flex items-center justify-between">
        <div className="min-w-0 flex">
          {isLoading ? <Spinner size="small" /> : null}
          {imgUrl ? (
            <div className="flex-shrink-0">
              <img className="h-10 w-10 rounded-full" src={imgUrl || ''} alt={name || ''} />
            </div>
          ) : null}
          <div className="ml-2 overflow-hidden">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900">{name || ''}</p>
            <p className="truncate text-sm text-gray-500 w-full">{details || ''}</p>
          </div>
        </div>
        {canEdit && <OptionMenu data={data} onDelete={onDelete} />}
      </div>
    </div>
  );
}
