/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import React from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import classNames from '../../../../utils/tailwind';

interface InputContainerProps {
  name: string;
  id: string;
  children: React.ReactNode;
}

function InputContainer({ name, id, children }: InputContainerProps) {
  return (
    <div className="col-span-full mb-2">
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {name || ''}
      </label>
      <div className="mt-2">{children}</div>
    </div>
  );
}

interface SelectOption {
  name: any;
  id: any;
}

interface InputProps {
  name: string;
  prop: string;
  type?: string;
  options?: SelectOption[];
  value: any;
  onChange: (prop: string, value: any) => void;
}

export function TextInput({ name, prop, type, value, onChange }: InputProps) {
  return (
    <InputContainer name={name} id={prop}>
      <div className="mt-2">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
          <input
            type={type}
            name={prop}
            id={prop}
            value={value}
            onChange={(e) => onChange(prop, e.target.value)}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={`Enter ${name.toLocaleLowerCase()}`}
          />
        </div>
      </div>
    </InputContainer>
  );
}

TextInput.defaultProps = {
  type: 'text',
  options: [],
};

function SelectInput({ name, prop, options, value, onChange }: InputProps) {
  const data = options || [];
  return (
    <select
      id={prop}
      name={name}
      value={value}
      onChange={(e) => onChange(prop, e.target.value)}
      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
    >
      {data.map((item) => (
        <option key={item.id || ''} value={item.id || ''}>
          {item.name || ''}
        </option>
      ))}
    </select>
  );
}

SelectInput.defaultProps = {
  type: 'select',
  options: [],
};

export function OptionsInput({ name, prop, options, value, onChange }: InputProps) {
  const data = options || [];
  return (
    <InputContainer name={name} id={prop}>
      <SelectInput name={name} prop={prop} options={data} value={value} onChange={onChange} />
    </InputContainer>
  );
}

OptionsInput.defaultProps = {
  type: 'select',
  options: [],
};

interface TextListInputProps {
  name: string;
  prop: string;
  type: string;
  defaultValue: any;
  data: any[];
  onChange: (prop: string, data: any[]) => void;
}

export function TextListInput({ name, prop, data, type, defaultValue, onChange }: TextListInputProps) {
  const items = [...data] || [];
  const handleChange = (value: any, idx: number) => {
    const updates = [...data] || [];
    updates[idx] = value;
    onChange(prop, updates);
  };
  const handleAdd = () => {
    const updates = [...data] || [];
    onChange(prop, [...updates, defaultValue]);
  };
  const handleDelete = (index: number) => {
    const updates = [...data] || [];
    if (index !== -1) {
      onChange(prop, [...updates.slice(0, index), ...updates.slice(index + 1)]);
    }
  };
  return (
    <InputContainer name={name} id={prop}>
      {items.map((item, idx) => {
        return (
          <div key={`${name}-text-list-input-${idx}`} className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
              <input
                type={type}
                name={`${name}-text-list-input`}
                id={`${name}-text-list-input`}
                value={item}
                onChange={(e) => handleChange(e.target.value, idx)}
                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Enter email address..."
              />
              <button
                type="button"
                onClick={() => handleDelete(idx)}
                className="rounded-md bg-white hover:bg-red-500 hover:text-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <TrashIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
        );
      })}
      <button
        type="button"
        onClick={handleAdd}
        className="rounded-md mt-2 bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        Add
      </button>
    </InputContainer>
  );
}

interface SelectListInputProps {
  name: string;
  prop: string;
  data: any[];
  options: SelectOption[];
  defaultValue: string;
  onChange: (prop: string, data: any[]) => void;
}

export function SelectListInput({ name, prop, data, options, defaultValue, onChange }: SelectListInputProps) {
  const items = data || [];
  const handleChange = (value: string, idx: number) => {
    const updates = [...items] || [];
    updates[idx] = value;
    onChange(prop, updates);
  };
  const handleAdd = () => {
    const updates = [...items] || [];
    onChange(prop, [...updates, defaultValue]);
  };
  const handleDelete = (index: number) => {
    const updates = [...items] || [];
    if (index !== -1) {
      onChange(prop, [...updates.slice(0, index), ...updates.slice(index + 1)]);
    }
  };
  return (
    <InputContainer name={name} id={prop}>
      {items.map((item, idx) => {
        const val = options.find((op) => op.id === item);
        return (
          <div key={`${name}-option-list-${idx}`} className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
              <SelectInput
                name={name}
                prop={prop}
                options={options}
                value={val?.id || defaultValue}
                onChange={(_, selectedVal) => handleChange(selectedVal, idx)}
              />
              <button
                type="button"
                onClick={() => handleDelete(idx)}
                className="rounded-md bg-white hover:bg-red-500 hover:text-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <TrashIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
        );
      })}
      <button
        type="button"
        onClick={handleAdd}
        className="mt-2 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        Add
      </button>
    </InputContainer>
  );
}

interface MultiSelectOptionsProps {
  name: string;
  data: any[];
  options: SelectOption[];
  onChange: (data: any[]) => void;
}

export function MultiSelectOptions({ name, options, data, onChange }: MultiSelectOptionsProps) {
  const isSelected = (option: SelectOption, items: any[]) => items.includes(option.id);
  const handleSelection = (option: SelectOption, items: any[]) => {
    const { id } = option;
    if (items.includes(id)) {
      onChange([...items].filter((item) => item !== id));
    } else {
      onChange([...items, id]);
    }
  };
  return (
    <fieldset>
      <legend className="text-base font-semibold leading-6 text-gray-900">{name || ''}</legend>
      <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
        {options.map((option) => {
          const selected = isSelected(option, data);
          return (
            <div key={option.id} className="relative flex items-start py-4">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor={`person-${option.id}`} className="select-none font-medium text-gray-900">
                  {option.name}
                </label>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id={`option-${option.id}`}
                  name={`option-${option.id}`}
                  type="checkbox"
                  checked={selected}
                  onChange={() => handleSelection(option, data)}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
            </div>
          );
        })}
      </div>
    </fieldset>
  );
}

export function BadgeSelectOptions({ options, data, onChange }: MultiSelectOptionsProps) {
  const isSelected = (option: SelectOption, items: any[]) => items.includes(option.id);
  const inActiveStyles = 'bg-gray-100 text-gray-800 hover:bg-gray-200 hover:text-gray-900';
  const activeStyles = 'bg-green-100 text-green-800 hover:bg-green-200 hover:text-green-900';
  const handleSelection = (option: SelectOption, items: any[]) => {
    const { id } = option;
    if (items.includes(id)) {
      onChange([...items].filter((item) => item !== id));
    } else {
      onChange([...items, id]);
    }
  };
  return (
    <fieldset>
      {/* <legend className="text-base font-semibold leading-6 text-gray-900">{name || ''}</legend> */}
      <div className="mt-4 relative flex flex-wrap items-start ">
        {options.map((option) => {
          const selected = isSelected(option, data);
          return (
            <div key={option.id}>
              <button type="button" onClick={() => handleSelection(option, data)}>
                <div
                  className={classNames(
                    selected ? activeStyles : inActiveStyles,
                    'inline-flex items-baseline rounded-full px-2.5 py-0.5 my-2 mx-1 text-sm font-medium md:mt-2 lg:mt-0'
                  )}
                >
                  {option.name}
                </div>
              </button>
            </div>
          );
        })}
      </div>
    </fieldset>
  );
}
