import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EntityContainer } from '../../redux/slices/core.models';
import { ResourcePermission } from '../../redux/models/permissions.models';
import { createRequiredPermissions } from '../../redux/slices/permission.utils';
import { selectPermissions } from '../../redux/slices/auth';

interface Props {
  resources: EntityContainer<ResourcePermission>;
  children: JSX.Element;
}

export default function Permission({ resources, children }: Props) {
  const { orgCode } = useParams();
  const permissions = useSelector(selectPermissions);
  const [granted, setGranted] = useState(false);
  const resourceIds = createRequiredPermissions(orgCode || '', resources);
  useEffect(() => {
    const hasAccess = resourceIds.some((resourceId) => {
      return permissions.some((permission) => {
        return permission.includes(resourceId);
      });
    });
    if (hasAccess) setGranted(true);
  }, [granted, resourceIds, permissions]);

  if (!granted) {
    return <div />;
  }
  return children;
}
