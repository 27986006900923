import { CollectionRequest, ElementType, FilterOp, QueryOperator } from '../redux/slices/collection.models';
import { hopperInstance } from '../utils/axios';
import HopperServiceConfigProvider from './hopperServiceConfigProvider';

class OrganisationApiProvider {
  static getFeatureFlags() {
    const appId = process.env.REACT_APP_HOPPER_APP_ID || '';
    const filters: CollectionRequest = {
      filters: [
        {
          field: {
            id: 'app',
            name: 'data.app_id',
            type: ElementType.Rel,
          },
          op: FilterOp.Equals,
          value: appId,
        },
      ],
      pagination: {
        page: 0,
        page_size: 50,
      },
      operator: QueryOperator.And,
    };
    return hopperInstance.post(HopperServiceConfigProvider.findFeatureFlags(), filters);
  }
}

export default OrganisationApiProvider;
