import React, { useState, useEffect } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import Spinner from '../../../../components/shared/Spinner';
import FileApi from './FileApi';
import { FileValues } from '../../TableCell.modals';

const getImageSize = (size: string) => {
  if (size === 'large') {
    return 'max-h-64';
  }
  if (size === 'medium') {
    return 'max-h-24';
  }
  return 'h-12 w-12';
};

// large: 'max-h-64'
interface Props {
  file: FileValues;
  rounding?: string;
  size?: string;
}

function AttachmentModalImage({ size, rounding, file }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>('');
  const imgSize = getImageSize(size || 'medium');

  useEffect(() => {
    const handleDownload = async (fl: FileValues) => {
      try {
        const download = await FileApi.downloadFile(fl);
        if (download.path) setFileUrl(download.path);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoaded(true);
      }
    };
    handleDownload(file);
  }, [file]);
  return (
    <div className="flex items-center">
      {isLoaded && isError && <PhotoIcon className="w-6 h-6" />}
      {isLoaded && !isError && (
        <img className={`${imgSize} mr-4 ${rounding} object-cover`} alt={file.name} src={fileUrl} />
      )}
      {!isLoaded && (
        <div className="flex w-full h-full items-center justify-center">
          <Spinner size="small" />
        </div>
      )}
    </div>
  );
}

AttachmentModalImage.defaultProps = {
  rounding: 'rounded',
  size: 'medium',
};

export default AttachmentModalImage;
