import { ResourcePermission } from '../models/permissions.models';
import { EntityContainer } from './core.models';

const { Read, Write } = ResourcePermission;

const createResourcePermissionItem = (orgCode: string, resource: string, permission: ResourcePermission) => {
  return `${orgCode}:${resource}:${permission}`;
};

/**
 * Create required permissions the user must contain to access the feature.
 * @param resource resouce_id of the hopper collection
 * @param permission required permission of the feature
 * @returns string format of resource_id:permission Read | Write
 */
export const createResourcePermissions = (orgCode: string, resource: string, permission: ResourcePermission) => {
  if (permission === Write) {
    return [createResourcePermissionItem(orgCode, resource, Write)];
  }
  // A user who can read and write will only contain resource_id:write scope so we include
  return [
    createResourcePermissionItem(orgCode, resource, Read),
    createResourcePermissionItem(orgCode, resource, Write),
  ];
};

export const createRequiredPermissions = (orgCode: string, resources: EntityContainer<ResourcePermission>) => {
  return Object.keys(resources).flatMap((resource) =>
    createResourcePermissions(orgCode, resource, resources[resource] || false)
  );
};
