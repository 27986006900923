import React from 'react';

interface Props<T> {
  title: string;
  description: string;
  data: T | null;
  isDeleting: boolean;
  onDelete: (item: T | null) => void;
}

export default function DeleteProfile<T>({ title, description, onDelete, data, isDeleting }: Props<T>) {
  return (
    <div className="mx-auto mt-12 max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
      <div className="mt-4 flex justify-between items-center rounded-lg space-x-3 px-6 py-12 bg-red-100 shadow-sm">
        <div className="pr-12">
          <h2 className="text-sm font-medium text-red-800">{title}</h2>
          <p className="text-sm text-red-900 mt-1">{description}</p>
        </div>
        <button
          type="button"
          onClick={() => onDelete(data)}
          className="bg-red-600 py-2 px-3 rounded-sm text-white text-sm hover:bg-red-800 self-end"
        >
          {isDeleting ? <div className="white-spinner" /> : 'Delete'}
        </button>
      </div>
    </div>
  );
}
