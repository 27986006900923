/* eslint-disable no-console */
import React, { ReactNode } from 'react';

interface Props {
  title: string;
  isOver?: boolean;
  children: ReactNode;
}

function KanbanColumn({ isOver, title, children }: Props) {
  const bg = isOver ? 'bg-gray-200' : 'bg-gray-50';
  return (
    <div className={`p-4 w-64 min-h-screen mx-4 m-4 rounded shadow ${bg}`} style={{ width: '400px' }}>
      <h2 className="text-lg font-semibold mb-2">{title}</h2>
      {children}
    </div>
  );
}

KanbanColumn.defaultProps = {
  isOver: false,
};

export default KanbanColumn;
