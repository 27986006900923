import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { RectangleStackIcon } from '@heroicons/react/20/solid';
import { CollectionElement } from '../../redux/models/database.model';
import classNames from '../../utils/tailwind';

interface Props {
  group: CollectionElement | null;
  elements: CollectionElement[];
  filterKey: string;
  openFilter: string | null;
  setOpenFilter: (filter: string | null) => void;
  onClear: () => void;
  onSelect: (element: CollectionElement) => void;
  onExpandAll: (expand: boolean) => void;
}

export default function GroupButton({
  group,
  elements,
  filterKey,
  openFilter,
  setOpenFilter,
  onClear,
  onSelect,
  onExpandAll,
}: Props) {
  const name = group?.name || '';
  const buttonTitle = name ? `Grouped by ${name}` : 'Group';
  const isOpen = filterKey === openFilter;

  const handleOpen = () => {
    setOpenFilter(isOpen ? null : filterKey);
  };

  const handleSelect = (element: CollectionElement) => {
    onSelect(element);
  };

  const handleClear = () => {
    onClear();
    setOpenFilter(null);
  };

  return (
    <Popover className="relative">
      {() => (
        <>
          <Popover.Button
            onClick={handleOpen}
            data-cy="collection_header_group_menu-toggle_btn"
            className={classNames(
              group
                ? 'bg-blue-100 text-blue-600 hover:text-blue-800 hover:bg-blue-200'
                : 'bg-white hover:bg-gray-100 focus:bg-gray-100',
              'group inline-flex h-full items-center rounded-md text-xs hover:text-gray-900 rounded px-2 py-1 mx-3 cursor-pointer'
            )}
          >
            <div className="flex items-center">
              <RectangleStackIcon
                className={classNames(
                  group ? 'text-blue-600' : 'text-gray-500',
                  'mr-1 h-3 w-3 group-hover:text-gray-500'
                )}
                aria-hidden="true"
              />
              <span>{buttonTitle}</span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
              {() => (
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white px-5 pt-6 sm:gap-8 sm:p-8">
                    <div>
                      <p className="text-xs font-semibold text-gray-600">Group By</p>
                      <p className="pt-1 text-xs text-gray-500">Pick a field to group by</p>
                      <div className="flex mt-2">
                        <button
                          type="button"
                          data-cy="collection_header_group_menu-expand_all"
                          onClick={() => onExpandAll(true)}
                          className="text-xs text-blue-600 hover:text-blue-800 hover:font-medium mr-2"
                        >
                          Expand All
                        </button>
                        <button
                          type="button"
                          data-cy="collection_header_group_menu-collapse_all"
                          onClick={() => onExpandAll(false)}
                          className="text-xs text-blue-600 hover:text-blue-800 hover:font-medium mr-2"
                        >
                          Collapse All
                        </button>
                      </div>
                      <div className="w-full bg-gray-200 h-px mt-4" />
                    </div>
                    <div className="px-2 pt-6 grid gap-6 max-h-64 overflow-auto">
                      {elements.map((element: CollectionElement) => (
                        <button
                          key={element.name}
                          type="button"
                          data-cy={`collection_header_group_menu-${element.name}`}
                          onClick={() => handleSelect(element)}
                          className="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50 text-left"
                        >
                          <p className="text-sm font-medium text-gray-900">{element.name}</p>
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="bg-gray-50 p-4">
                    <button
                      type="button"
                      onClick={() => handleClear()}
                      data-cy="collection_header_group_menu-clear_btn"
                      className="block rounded-md p-3 w-full transition duration-150 ease-in-out hover:bg-gray-100"
                    >
                      <p className="text-xs font-medium text-blue-600">Clear grouping</p>
                    </button>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
