/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import classNames from '../../utils/tailwind';
import { Entity } from '../../redux/models/auth.models';
import { CollectionElement } from '../../redux/models/database.model';
import { getEntityValue } from '../../redux/slices/database.utils';
import GridViewItemRow from './GridViewItemRow';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setSelectedEntity } from '../../redux/slices/database';
import { ELEMENT_TYPE_ID } from '../../redux/slices/element.models';
import AttachmentModalImage from '../../tables/Body/Cells/MultiAttachment/AttachmentModelImage';
import { FileValues } from '../../tables/Body/TableCell.modals';
import Spinner from '../shared/Spinner';
import { getFileElement } from './gridview.utils';

interface Props {
  entity: Entity;
  primary: CollectionElement;
  elements: CollectionElement[];
  limit?: boolean;
  thumbnail?: boolean;
  isSaving?: boolean;
  onEdit: () => void;
}

function GridViewItem({ entity, elements, primary, thumbnail, limit, isSaving, onEdit }: Props) {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const [file, setFile] = useState<FileValues | null>(null);
  const name = getEntityValue(entity, primary);
  const items = limit && elements.length > 3 ? elements.slice(0, 3) : elements;
  const displayElements = items.filter((el) => {
    const type = el.type || '';
    return (
      el.type !== ELEMENT_TYPE_ID.file &&
      el.type !== ELEMENT_TYPE_ID.multi_file &&
      type !== ELEMENT_TYPE_ID.link &&
      type !== ELEMENT_TYPE_ID.role &&
      type !== ELEMENT_TYPE_ID.multi_role &&
      type !== ELEMENT_TYPE_ID.bool
    );
  });

  const handleEdit = async () => {
    await dispatch(setSelectedEntity(entity));
    setSearchParams({ entity_id: entity.entity_id });
    onEdit();
  };

  useEffect(() => {
    const fileElement = getFileElement(elements);
    if (fileElement) {
      const fileData = entity.data[fileElement.property];
      if (fileData) setFile(fileData);
    }
  }, [elements, entity]);

  return (
    <li className="overflow-hidden rounded-xl border border-gray-200 bg-white">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        {thumbnail && (
          <div className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10">
            {file && <AttachmentModalImage size="small" file={file} />}
          </div>
        )}
        <div className="text-sm font-medium leading-6 text-gray-900">{name}</div>
        {isSaving && <Spinner size="small" />}
        <Menu as="div" className="relative ml-auto">
          <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => handleEdit()}
                    className={classNames(
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    )}
                  >
                    Edit
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        {displayElements.map((element) => (
          <GridViewItemRow key={`${element.name}-${entity.entity_id}`} entity={entity} element={element} />
        ))}
      </dl>
    </li>
  );
}

GridViewItem.defaultProps = {
  thumbnail: false,
  limit: false,
  isSaving: false,
};

export default GridViewItem;
