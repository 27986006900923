import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import Spinner from '../shared/Spinner';

interface PageHeaderProps {
  title: string;
  button?: boolean;
  buttonTitle?: string;
  loading?: boolean;
  handleAction?: () => void;
}

function PageHeader({ title, button, buttonTitle, loading, handleAction }: PageHeaderProps) {
  return (
    <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div className="min-w-0 flex-1">
        <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate" data-cy={`${title}-page-header`}>
          {title}
        </h1>
      </div>
      {button && buttonTitle && (
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm hover:bg-blue-800"
          onClick={handleAction}
        >
          {!loading && <PlusIcon className="-ml-0.5 h-5 w-5 text-gray-100" aria-hidden="true" />}
          {!loading && (buttonTitle || '')}
          {loading && <Spinner size="small" />}
        </button>
      )}
    </div>
  );
}

PageHeader.defaultProps = {
  button: false,
  buttonTitle: '',
  loading: false,
  handleAction: () => {},
};

export default PageHeader;
