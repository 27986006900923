/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PlusSmallIcon, EllipsisHorizontalIcon, XMarkIcon } from '@heroicons/react/20/solid';

import { allRel, loadRelData, selectRelApps } from '../../../../redux/slices/database';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import HopperServiceApiProvider from '../../../../services/hopperServiceApiProvider';
import FormErrorMessage from '../../../Form/FormErrorMessage';

import 'react-datepicker/dist/react-datepicker.css';
import { DEFAULT_REQUEST_BODY } from '../../../../redux/slices/collection.models';
import PopupContainer from '../../../../components/popup/PopupContainer';
import { Entity } from '../../../../redux/models/auth.models';
import { notify } from '../../../../components/shared/Notification';
import EditEntityContainer from '../../../Modal/EditEntityContainer';
import RelButton from './RelButton';
import classNames from '../../../../utils/tailwind';
import MultiRelationModal from '../../../Modal/MultiRelationModal';

const buildContainerClasses = (isModal: boolean) => {
  const modal = isModal ? 'flex-wrap' : 'flex-nowrap';
  return `flex ${modal} overflow-hidden`;
};

interface Props {
  formikValue: any;
  setFormikValue: any;
  formikProperty: any;
  submitFormik: any;
  errors?: any;
  isForm?: any;
  editable: boolean;
  showDelete?: boolean;
  element: any;
  width: any;
  minWidth: any;
  isModal?: boolean;
}

function TableMultiRelCell({
  formikValue,
  setFormikValue,
  formikProperty,
  submitFormik,
  errors,
  isForm = false,
  showDelete,
  editable,
  element,
  width,
  minWidth,
  isModal,
}: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [selectedRelId, setSelectedRelId] = useState<string | null>(null);
  const rels = useSelector(allRel);
  const apps = useSelector(selectRelApps);
  const relCollectionId = element.collection_id || '';
  const baseUrl = apps[relCollectionId]?.data?.base_url;
  const currentEntity = rels[element.collection_id];
  const { path } = currentEntity.value;
  const relUrl = `${baseUrl}${path}`;
  const isRelLoaded = currentEntity.dataLoaded;

  const data = Array.isArray(formikValue) ? formikValue : [];
  const [multiValues, setMultiValues] = useState(data);
  const valData = isModal ? multiValues : multiValues.slice(0, 3);
  const { displayName } = currentEntity;
  const timerId = useRef<NodeJS.Timeout>();
  const [showPopup, setShowPopup] = useState(false);
  const handlePopupClick = () => {
    setShowPopup(true);
  };

  const handleSaveSelectedEntities = (entities: Entity[]) => {
    console.log('handleSaveSelectedEntities: ');
    setFormikValue(formikProperty, [...entities]);
    setMultiValues([...entities]);
  };

  const handleXClick = (rel: Entity) => {
    const newArr = multiValues.filter((ele: Entity) => ele.entity_id !== rel.entity_id);
    setMultiValues(newArr);
    setFormikValue(formikProperty, newArr);
    clearTimeout(timerId.current);
    if (!isForm) {
      timerId.current = setTimeout(() => {
        submitFormik();
      }, 1500);
    }
  };

  const handleClick = async () => {
    setOpen(true);
    if (!isRelLoaded) {
      try {
        const response = await HopperServiceApiProvider.getEntityData(relUrl, DEFAULT_REQUEST_BODY);
        const entities = response.data.data.items || [];
        dispatch(loadRelData(element.collection_id, entities));
      } catch (error) {
        if (error instanceof Error) {
          const errorMessage = `${error.message}. Please contact support if the problem persists.`;
          notify(`Couldn't load ${element.name}`, errorMessage, 'error');
        }
      }
    }
  };

  const handleUpdateRel = (relEntity: Entity) => {
    const newArr = multiValues.map((ent: Entity) => {
      if (ent.entity_id === relEntity.entity_id) return relEntity;
      return ent;
    });
    setFormikValue(formikProperty, newArr);
    setMultiValues(newArr);
    clearTimeout(timerId.current);
  };

  useEffect(() => {
    if (Array.isArray(formikValue) && formikValue.length !== multiValues.length) {
      setMultiValues(formikValue);
    }
  }, [formikValue, multiValues]);

  return (
    <div
      className="table-cell-row relative hover:border-2 hover:border-cell-border overflow-clip"
      style={{ minWidth, width }}
    >
      <div>
        {multiValues && (
          <div className={buildContainerClasses(isModal || false)}>
            {valData.map((entity: Entity) => {
              const name = entity.data[displayName];
              return (
                <div key={entity.entity_id} className={classNames(isModal ? 'mr-2 mt-2' : 'mr-1')}>
                  <RelButton
                    title={name}
                    editable={editable}
                    showClose={showDelete}
                    onOpen={() => setSelectedRelId(entity.entity_id)}
                    onClose={() => handleXClick(entity)}
                  />
                </div>
              );
            })}
            {multiValues.length > 3 && !isModal && (
              <div className="mr-4">
                <button
                  type="button"
                  onClick={handlePopupClick}
                  className={classNames(
                    isModal ? 'mt-2' : '',
                    'inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-1.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                  )}
                >
                  <EllipsisHorizontalIcon className="h-4 w-5 text-gray-400" aria-hidden="true" />
                </button>
              </div>
            )}
            {editable && (
              <button
                type="button"
                onClick={handleClick}
                className={classNames(
                  isModal ? 'mt-2' : '',
                  'relative inline-flex items-center rounded-md bg-white px-2 py-1 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10'
                )}
              >
                <PlusSmallIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </button>
            )}
            {showPopup && (
              <PopupContainer open={showPopup} setOpen={setShowPopup}>
                {multiValues.map((ele: Entity, idx: number) => {
                  const name = ele.data[displayName];
                  return (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={name + idx}
                      className="flex justify-between content-center border rounded-md mb-5 p-3 hover:border-blue-500 relative"
                    >
                      <button
                        type="button"
                        onClick={() => setSelectedRelId(ele.entity_id)}
                        className="relative -ml-px inline-flex items-center rounded-md bg-white ml-2 px-2 py-1 text-xs text-gray-900 hover:text-blue-800 focus:z-10"
                      >
                        <span className="whitespace-nowrap truncate text-sm">{name}</span>
                      </button>
                      {editable && (
                        <button
                          type="button"
                          onClick={() => handleXClick(ele)}
                          className="relative -ml-px inline-flex items-center rounded-md bg-white ml-2 px-2 py-1 text-xs text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <XMarkIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                  );
                })}
              </PopupContainer>
            )}
            {selectedRelId && (
              <EditEntityContainer
                entityId={selectedRelId}
                collectionId={relCollectionId}
                isOpen={selectedRelId !== null}
                isRel
                onUpdate={handleUpdateRel}
                onClose={() => setSelectedRelId(null)}
              />
            )}
            {errors && <FormErrorMessage error={errors} />}
          </div>
        )}

        {open && editable && (
          <MultiRelationModal
            element={element}
            relUrl={relUrl}
            rels={currentEntity}
            open={open}
            selectedEntities={multiValues}
            displayName={displayName}
            submitFormik={submitFormik}
            isForm={isForm}
            multi
            setOpen={setOpen}
            saveSelectedEntities={handleSaveSelectedEntities}
          />
        )}
      </div>
    </div>
  );
}

TableMultiRelCell.defaultProps = {
  isForm: false,
  errors: null,
  isModal: false,
  showDelete: false,
};

export default TableMultiRelCell;
