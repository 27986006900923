/* eslint-disable */
import React from 'react';
import Spinner from '../../../../components/shared/Spinner';

export default function Loader() {
  return (
    <div className="mt-4 w-full h-full items-center justify-center">
      <Spinner />
    </div>
  );
}
