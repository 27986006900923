/* eslint-disable @typescript-eslint/no-explicit-any */
import Papa from 'papaparse';
import { CollectionElement, TableData } from '../../redux/models/database.model';
import { Entity } from '../../redux/models/auth.models';
import { EntityContainer } from '../../redux/slices/core.models';
import { getCsvEntityValue } from '../../redux/slices/database.utils';

export const createHeaders = (elements: CollectionElement[]) => {
  return elements.map((el: CollectionElement) => el.name);
};

export const createRows = (elements: CollectionElement[], data: Entity[]) => {
  return data.map((item) => {
    const container: EntityContainer<any> = {};
    return elements.reduce((store, el: CollectionElement) => {
      return {
        ...store,
        [el.name]: getCsvEntityValue(item, el, true),
      };
    }, container);
  });
};

export const createCSVFile = (data: TableData) => {
  const headers = createHeaders(data.columns);
  const config = {
    quotes: false,
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    header: true,
    newline: '\r\n',
    skipEmptyLines: false,
    columns: headers,
  };

  const rows = createRows(data.columns, data.rows);
  const entityData = Papa.unparse(rows, config);
  const csvData = new Blob([entityData], { type: 'text/csv;charset=utf-8;' });
  const dataURI = `data:text/csv;charset=utf-8,${headers}\n${rows}`;
  const URL = window.URL || window.webkitURL;
  const item = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(csvData);
  window.open(item);
};
