/* eslint-disable @typescript-eslint/no-explicit-any */

export interface Field {
  id: string;
  name: string;
  type: string;
}

export interface CollectionFilter {
  field: Field;
  op: string;
  value?: any;
}

interface CollectionPage {
  page: number;
  page_size: number;
}

interface CollectionSorting {
  field: string;
  order: string;
}

interface CollectionGroup {
  field: string;
}

export enum ElementType {
  String = 'string',
  Rel = 'rel',
}

export enum FilterOp {
  Equals = 'eq',
  NotEquals = 'ne',
}

export enum QueryOperator {
  And = 'AND',
  Or = 'OR',
}

export interface CollectionRequest {
  filters: CollectionFilter[];
  operator: string;
  pagination: CollectionPage;
  sorting?: CollectionSorting[];
  grouping?: CollectionGroup;
}

export const DEFAULT_REQUEST_PAGINATION: CollectionPage = {
  page: 0,
  page_size: 50,
};

export const DEFAULT_REQUEST_BODY: CollectionRequest = {
  filters: [],
  operator: QueryOperator.And,
  pagination: DEFAULT_REQUEST_PAGINATION,
};
