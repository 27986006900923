/* eslint-disable @typescript-eslint/no-explicit-any */
interface Auth0ClientType {
  clientId: string | undefined;
  domain: string | undefined;
  audience: string | undefined;
  scope: string | undefined;
  cacheLocation: string | undefined;
}

export const auth0Config: Auth0ClientType = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope: process.env.REACT_APP_AUTH0_SCOPE,
  cacheLocation: 'localstorage',
};

export const LOCAL_STORAGE_TOKEN_KEY = 'accessToken';

export const sidebarTopItems: { title: string; pages: Array<any> | null; path: string; icon: boolean }[] = [
  {
    title: 'Dashboard',
    icon: true,
    path: '/',
    pages: null,
  },
  {
    title: 'Automations',
    icon: true,
    pages: null,
    path: '/automations',
  },
  {
    title: 'Team',
    icon: true,
    pages: null,
    path: '/team',
  },
  {
    title: 'Integrations',
    icon: true,
    pages: null,
    path: '/integrations',
  },
  {
    title: 'Support',
    icon: true,
    pages: null,
    path: '/support',
  },
  {
    title: 'Setting',
    icon: true,
    pages: null,
    path: '/setting',
  },
  {
    title: 'Label',
    icon: true,
    pages: [
      {
        title: 'Label',
        path: '/label',
      },
      {
        title: 'Label',
        path: '/label',
      },
    ],
    path: '/label',
  },
];
