/* eslint-disable no-console */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { notify } from '../../../../components/shared/Notification';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { CreateUserProps } from '../../../../redux/models/auth.models';
import { setAddUser } from '../../../../redux/slices/users';
import UserApiProvider from '../../../../services/UserApiProvider';
import AddDirectoryItemModal from '../AddDirectoryItemModal';
import { isEmailValid, isPasswordMatch, isPasswordValid } from '../form.utils';
import AddUserForm from './AddUserForm';
import { selectOrganisation } from '../../../../redux/slices/organisation';

interface Props {
  organizationId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const isFormValid = (name: boolean, email: boolean, password: boolean, passwordMatch: boolean, newUser: boolean) => {
  if (!newUser) {
    return name === true && email === true;
  }
  return name === true && email === true && password === true && passwordMatch;
};

export default function AddUserModal({ organizationId, open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const org = useSelector(selectOrganisation);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [retypePassword, setEnterPassword] = useState<string>('');
  const [newUser, setNewUser] = useState(true);

  const orgCode = org?.data?.organization_code;
  const isApex = orgCode === 'apex';
  const isNameValid = name.length > 0;
  const emailValid = isEmailValid(email);
  const passwordValid = isPasswordValid(password);
  const passwordMatch = isPasswordMatch(password, retypePassword);
  const isValid = isFormValid(isNameValid, emailValid, passwordValid, passwordMatch, newUser);
  const handleChange = (prop: string, value: string) => {
    if (prop === 'email') {
      setEmail(value);
    } else if (prop === 'password') {
      setPassword(value);
    } else if (prop === 'name') {
      setName(value);
    } else {
      setEnterPassword(value);
    }
  };
  const onSubmit = async () => {
    if (!isValid) {
      return;
    }
    const user: CreateUserProps = {
      name,
      email,
      organization_ids: [organizationId],
      role_ids: [],
    };
    if (newUser) {
      user.password = password;
    }
    const response = await UserApiProvider.create(user);
    if (response.status === 200) {
      setName('');
      setEmail('');
      setPassword('');
      setEnterPassword('');
      dispatch(setAddUser(response.data.data));
      setOpen(false);
    } else {
      notify('Error Creating User', "Couldn't create user", 'error');
    }
  };
  return (
    <AddDirectoryItemModal
      title="Add User"
      open={open}
      setOpen={setOpen}
      onSubmit={onSubmit}
      valid={isValid}
      renderForm={() => (
        <AddUserForm
          name={name}
          isNameValid={isNameValid}
          email={email}
          emailValid={emailValid}
          password={password}
          passwordValid={passwordValid}
          retypePassword={retypePassword}
          passwordMatch={passwordMatch}
          newUser={newUser}
          isApex={isApex}
          setNewUser={setNewUser}
          handleChange={handleChange}
          onSubmit={onSubmit}
        />
      )}
    />
  );
}
