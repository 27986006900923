export enum ResourcePermission {
  Read = 'read',
  Write = 'write',
}

export enum ResourceScope {
  Org = 'org',
  Group = 'group',
  User = 'user',
}

export enum Resources {
  Automation = 'automation',
  Collection = 'collection',
  Group = 'group',
  Integration = 'integration',
  Organization = 'organization',
  User = 'user',
}
