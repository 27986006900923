/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { hopperInstance } from '../utils/axios';
import HopperServiceConfigProvider from './hopperServiceConfigProvider';
import { Collection } from '../redux/models/database.model';

// This is where the interception happens when you make an api call to your cloud server. The interception is needed because the header with the token needs to be added.
class CollectionApiProvider {
  static async getCollectionById(collectionId: string) {
    return hopperInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.collectionById(collectionId),
      validateStatus: () => true,
    });
  }

  static async updateCollection(collection: Collection) {
    return hopperInstance({
      method: 'PUT',
      url: HopperServiceConfigProvider.collectionById(collection.entity_id),
      data: collection,
      validateStatus: () => true,
    });
  }

  static parseResponse(response: AxiosResponse<any, any>) {
    switch (response.status) {
      case 200:
        return response.data.data;
      case 403:
        throw new Error("You don't have permissions to view this error");
      case 500:
        throw new Error('Server error');
      default:
        throw new Error('Error loading response');
    }
  }
}

export default CollectionApiProvider;
