/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Integration } from '../../../redux/slices/integration.models';

export default function ListItem({ integration }: { integration: Integration }) {
  const { entity_id: id, data } = integration;
  const title = data.app?.data?.name || '';
  const imgUrl = data.app?.data?.logo_url || '';
  const details = integration.data?.details || '';
  const status = integration.data?.status || '';
  return (
    <li
      key={id}
      className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg text-center bg-white shadow rounded-r-md border border-gray-200"
    >
      <div className="flex flex-1 flex-col p-8">
        <img className="mx-auto h-32 w-32 flex-shrink-0 object-scale-down rounded-full" src={imgUrl} alt="" />
        <h3 className="mt-6 text-sm font-medium text-gray-900">{title}</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">{details}</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">{status}</span>
          </dd>
        </dl>
      </div>
    </li>
  );
}
