import moment from 'moment';
import { FileValues } from '../../TableCell.modals';
import { EntityContainer } from '../../../../redux/slices/core.models';

interface DownloadCache {
  timestamp: number;
  expiry: number;
  file: FileValues;
}

export default class FileCache {
  private cache: EntityContainer<DownloadCache> = {};

  add(file: FileValues) {
    const key = file.name;
    const now = moment().unix();
    this.cache[key] = {
      timestamp: now,
      expiry: now + 1800,
      file,
    };
  }

  get(key: string) {
    return this.cache[key].file;
  }

  exists(key: string) {
    const now = moment().unix();
    const file = this.cache[key];
    if (file) {
      return now < file.expiry;
    }
    return file !== undefined && file !== null;
  }
}
