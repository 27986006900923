/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-var-requires */
import * as Sentry from '@sentry/react';
import { VERSION_NO } from '../release_notes';

const mixpanel = require('mixpanel-browser');

const isProduction = process.env.REACT_APP_APP_STAGE === 'production';
if (isProduction) mixpanel.init(process.env.REACT_APP_ANALYTICS_ID || '');

export default class Analytics {
  static identify(userId: string) {
    if (!isProduction) {
      return;
    }
    if (userId && userId !== '') {
      mixpanel.identify(userId);
    }
  }

  static track(event: string, props?: object) {
    if (!isProduction) {
      return;
    }
    const extras = props ? { ...props, version: VERSION_NO } : { version: VERSION_NO };
    mixpanel.track(event, extras);
  }

  static launchProject(name: string) {
    Analytics.track('Launch Company App', {
      company: name,
    });
  }

  static editRecord(orgcode: string | undefined) {
    Analytics.track('Edit Database Record', {
      company: orgcode,
    });
  }

  static addRecord(orgcode: string | undefined) {
    Analytics.track('Add Database Record', {
      company: orgcode,
    });
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static capture(error: any) {
    console.log(error);
    if (error instanceof Error && isProduction) Sentry.captureException(error);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static log(error: any) {
    if (error instanceof Error && isProduction) console.log(error);
  }
}
