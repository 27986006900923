/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { debounce } from 'lodash';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import classNames from '../../utils/tailwind';
import FormErrorMessage from '../../tables/Form/FormErrorMessage';
import { allRel } from '../../redux/slices/database';
import Spinner from './Spinner';
import { Entity } from '../../redux/models/auth.models';
import { CollectionRequest, DEFAULT_REQUEST_BODY } from '../../redux/slices/collection.models';
import { CollectionElement, Organisation } from '../../redux/models/database.model';
import { getBaseUrl, getCollection, getEntityValue } from '../../redux/slices/database.utils';
import EntityApiProvider from '../../services/EntityApiProvider';
import { getLocalOrganisation } from '../../redux/slices/organisation';
import { notify } from './Notification';
import { createFilters } from '../../tables/Body/Cells/TableRelCell/utils';

interface Props {
  entity: any;
  placeholder: any;
  errors: any;
  idx: number;
  name: string;
  columns: CollectionElement[];
  formik: any;
  dataCy: string;
}

export default function DropdownSelectAPI({ entity, placeholder, errors, idx, name, columns, formik, dataCy }: Props) {
  const org: Organisation = getLocalOrganisation();
  const [isOpen, setIsOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [isRelLoaded, setIsRelLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedEntityName, setSelectedEntityName] = useState<string>('');
  const rels = useSelector(allRel);
  const newStr = entity.replace(/^data\./, '');
  const element = columns.find((ele) => ele.rel_filter_prop === newStr);
  const collectionId = element?.collection_id || '';
  const { path } = rels[collectionId]?.value || '';
  const selectedEntityId = formik.values.filters[idx]?.value;
  const collection = getCollection(org, collectionId);
  let baseUrl: string | undefined;
  if (collection) baseUrl = getBaseUrl(org, collection);

  const handleClick = (value: any, e: React.MouseEvent) => {
    e.preventDefault();
    formik.setValues((prev: any) => ({
      ...prev,
      filters: [
        ...prev.filters.slice(0, idx),
        { ...prev.filters[idx], value: value.entity_id },
        ...prev.filters.slice(idx + 1),
      ],
    }));
    setDisplayValue(value.data.name || value.data.description);
    setIsOpen(false);
    setIsRelLoaded(false);
  };

  const loadEntity = async (filters: CollectionRequest) => {
    try {
      const response = await EntityApiProvider.getEntityData(`${baseUrl}${path}`, filters);
      const { items } = EntityApiProvider.parseResponse(response);
      setDropdownOptions(items);
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = `Couldn't get Database in filters. ${error.message}. Please contact support if the problem persists.`;
        notify('', errorMessage, 'error');
      }
    }
  };
  const handleOpen = async () => {
    setIsOpen(!isOpen);
    setIsRelLoaded(false);
    setIsLoaded(false);
    if (!isRelLoaded) {
      try {
        loadEntity(DEFAULT_REQUEST_BODY);
      } catch (error) {
        if (error instanceof Error) {
          const errorMessage = `Couldn't get Database in filters. ${error.message}. Please contact support if the problem persists.`;
          notify('', errorMessage, 'error');
        }
      } finally {
        setIsLoaded(true);
        setIsRelLoaded(true);
      }
    }
  };

  const debouncedResults = useMemo(() => {
    const searchRels = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (baseUrl !== '') {
        try {
          setIsLoaded(false);
          const filters = val.length > 0 && element ? createFilters(val, element) : DEFAULT_REQUEST_BODY;
          const response = await EntityApiProvider.getEntityData(`${baseUrl}${path}`, filters);
          const { items } = EntityApiProvider.parseResponse(response);
          setDropdownOptions(items);
        } catch (error) {
          if (error instanceof Error) {
            const errorMessage = `Couldn't get Database in filters. ${error.message}. Please contact support if the problem persists.`;
            notify('', errorMessage, 'error');
          }
        } finally {
          setIsLoaded(true);
        }
      }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      searchRels(e);
    };

    return debounce(handleSearch, 300);
  }, [baseUrl, path, element]);

  useEffect(() => {
    const loadSelectedEntity = async (url: string) => {
      try {
        const { data } = await EntityApiProvider.getEntityById(url);
        const val = getEntityValue(data.data, element);
        setSelectedEntityName(val);
      } catch (error) {
        if (error instanceof Error) {
          const errorMessage = `${error.message}. Please contact support if the problem persists.`;
          notify(`Couldn't load ${element?.name || ''}.`, errorMessage, 'error');
        }
      }
    };
    if (baseUrl && selectedEntityId && path) {
      const url = `${baseUrl}${path}/${selectedEntityId}`;
      loadSelectedEntity(url);
    }
  }, [baseUrl, path, selectedEntityId, element]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          onClick={handleOpen}
          data-cy={`${dataCy}-rel_btn`}
          className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          {displayValue || selectedEntityName || placeholder}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 max-h-96 overflow-scroll origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <div className="px-2">
              <input
                type="text"
                data-cy={`${dataCy}-search_input`}
                onChange={debouncedResults}
                placeholder="Search..."
                className="w-full mb-2 h-8 border-gray-600"
              />
            </div>
            {dropdownOptions && isLoaded ? (
              dropdownOptions?.map((entityOption: Entity, idxInner) => {
                const val = getEntityValue(entityOption, element);
                return (
                  <Menu.Item key={name + idxInner}>
                    {({ active }) => (
                      <a
                        onClick={(e) => handleClick(entityOption, e)}
                        data-cy={`${dataCy}-${val}`}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full text-left'
                        )}
                      >
                        {val}
                      </a>
                    )}
                  </Menu.Item>
                );
              })
            ) : (
              <Spinner size="small" />
            )}
          </div>
        </Menu.Items>
      </Transition>
      {errors && <FormErrorMessage error={errors} />}
    </Menu>
  );
}
