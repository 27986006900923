/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Popover, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import classNames from '../../utils/tailwind';
import FilterForm from './FilterForm';
import { resetPage, selectCollectionUrl, selectDisplayColumn, selectRequest } from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';
import { updateFilterValues, validateFilter } from '../../utils/filter.utils';
import Spinner from '../../components/shared/Spinner';
import { notify } from '../../components/shared/Notification';
import EntityApiProvider from '../../services/EntityApiProvider';
import { CollectionFilter, QueryOperator } from '../../redux/slices/collection.models';

const isFormikEmpty = (filters: CollectionFilter[]) => {
  if (filters && filters.length) {
    const [filter] = filters;
    if (filter && filter.field && filter.field.id) {
      return true;
    }
  }
  return false;
};

const getTitle = (filters: CollectionFilter[]) => {
  if (filters && filters.length) {
    const [filter] = filters;
    if (filter && filter.field && filter.field.id) {
      return `Filtered by ${filter.field.id}`;
    }
  }
  return 'Filter';
};

interface FilterButtonProps {
  filterKey: string;
  openFilter: string | null;
  onReset: () => void;
  setOpenFilter: (filter: string | null) => void;
}

export default function FilterButton({ filterKey, openFilter, setOpenFilter, onReset }: FilterButtonProps) {
  const columns = useSelector(selectDisplayColumn);
  const request = useSelector(selectRequest);
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setLoadingAdd] = useState(false);
  const [entityUrl, setEntityUrl] = useState<string>('');
  const collectionUrl = useSelector(selectCollectionUrl);
  const dispatch = useAppDispatch();
  const initialValues: any = request;
  const isOpen = filterKey === openFilter;

  const handleOpen = () => {
    setOpenFilter(isOpen ? null : filterKey);
  };

  const formik = useFormik({
    initialValues,
    validate: validateFilter,
    onSubmit: async (values) => {
      setLoadingAdd(true);
      try {
        const updatedFilters = updateFilterValues(values, request);
        const response = await EntityApiProvider.getEntityData(collectionUrl, updatedFilters);
        const data = EntityApiProvider.parseResponse(response);
        const totalCount = data.total_count;
        dispatch(resetPage({ data, filters: updatedFilters, totalCount }));
      } catch (error) {
        if (error instanceof Error) {
          notify(
            'Unable to apply filters',
            `Error getting records from the database. ${error.message}. Please try resetting the filters`,
            'error'
          );
        }
      } finally {
        setLoadingAdd(false);
        setOpenFilter(null);
      }
    },
    validateOnChange: false,
  });

  const handleReset = () => {
    formik.setValues({
      filters: [],
      operator: QueryOperator.And,
    });
    setShowFilters(false);
    formik.setErrors({});
    onReset();
  };

  const handleAddCondtion = () => {
    formik.setValues((prev: any) => ({
      ...prev,
      filters: [
        ...prev.filters,
        {
          field: null,
          op: null,
        },
      ],
    }));
    setShowFilters(true);
    formik.setErrors({});
  };

  const handleSubmit = () => {
    formik.submitForm();
  };

  const deleteRow = (idx: number) => {
    const newValues = formik.values;
    newValues.filters.splice(idx, 1);
    formik.setValues(newValues);
    formik.setErrors({});
  };

  const isFilters = isFormikEmpty(request.filters);
  useEffect(() => {
    if (collectionUrl !== entityUrl) {
      setEntityUrl(collectionUrl);
      formik.setValues({
        filters: [],
        operator: '',
      });
      formik.setErrors({});
    }
  }, [collectionUrl, entityUrl, formik]);
  return (
    <Popover className="relative z-50 flex">
      {() => (
        <>
          <Popover.Button
            onClick={handleOpen}
            data-cy="collection_header_filter_menu_btn"
            className={classNames(
              isFilters ? 'bg-blue-100 text-blue-600 hover:text-blue-800 hover:bg-blue-200' : 'bg-white',
              'group inline-flex items-center bg-white text-xs hover:bg-gray-100 focus:bg-gray-100 hover:text-gray-900 rounded px-2 py-1 mx-3 cursor-pointer'
            )}
          >
            <div className="flex items-center">
              <FunnelIcon
                className={classNames(
                  isFilters ? 'text-blue-600' : 'text-gray-500',
                  'mr-1 h-3 w-3 group-hover:text-gray-500'
                )}
                aria-hidden="true"
              />
              <span>{getTitle(request.filters)}</span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute top-[30px] left-[270px] min-w-[600px] z-30 mt-3 w-screen max-w-lg -translate-x-1/2 transform px-2 sm:px-0">
              <div className="h-full rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white px-3 py-4 sm:gap-8 sm:p-8">
                  <div>
                    <p className="text-xs font-semibold text-gray-600">Filter By</p>
                    <p className="pt-1 text-xs text-gray-500">Show records that match:</p>
                    <div className="flex mt-2">
                      <button
                        type="button"
                        onClick={handleReset}
                        data-cy="collection_filter_menu_clear_btn"
                        className="text-xs text-blue-600 hover:text-blue-800 hover:font-medium mr-2"
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                  <FilterForm
                    columns={columns}
                    onSubmit={formik.handleSubmit}
                    formik={formik}
                    showFilters={showFilters}
                    deleteRow={deleteRow}
                  />
                </div>
                <div className="bg-gray-50 p-4 flex justify-center">
                  <button
                    type="button"
                    onClick={handleAddCondtion}
                    data-cy="collection_filter_menu_add_condition_btn"
                    className="block rounded-md p-3 w-full transition duration-150 ease-in-out hover:bg-gray-100"
                  >
                    <p className="text-xs font-medium text-blue-600">Add condition</p>
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    data-cy="collection_filter_menu_search_btn"
                    className="block rounded-md p-3 w-full transition duration-150 ease-in-out hover:bg-gray-100"
                  >
                    {isLoading ? (
                      <div className="w-full flex items-center">
                        <Spinner size="small" />
                      </div>
                    ) : (
                      <p className="text-xs font-medium text-blue-600">Search</p>
                    )}
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
