/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import { FILTER_STORAGE_PREFIX, SETTINGS_STORAGE_PREFIX } from '../constants/core.contants';
import { SamsaraValues } from './core.utils.model';

export const safelyParseJSON = (json: any) => {
  let parsed;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    return null;
  }
  return parsed;
};

export const checkForName = (obj: SamsaraValues) => {
  if (obj) {
    const nameKey = Object.keys(obj).find((key) => key.includes('name')) as keyof SamsaraValues;
    if (nameKey) {
      return obj[nameKey];
    }
  }
  return null;
};

/**
 * ======================== Session Storage ========================
 */

export const saveFilterValueToSessionStorage = (filterName: string, filterValue: any) => {
  sessionStorage.setItem(`${FILTER_STORAGE_PREFIX}${filterName}`, filterValue);
};

export const getFilterValueFromSessionStorage = (filterName: string) => {
  const filter = sessionStorage.getItem(`${FILTER_STORAGE_PREFIX}${filterName}`);
  return filter;
};

export const saveValueToSessionStorage = (key: string, value: any) => {
  const strVal = JSON.stringify(value);
  sessionStorage.setItem(`${key}`, strVal);
};

export const getValueFromSessionStorage = (key: string) => {
  const item = sessionStorage.getItem(`${key}`);
  return safelyParseJSON(item);
};

export const removeValueFromSessionStorage = (key: string) => {
  return sessionStorage.removeItem(`${key}`);
};

/**
 * ======================== Local Storage ========================
 */

export const saveFilterValueToLocalStorage = (filterName: string, filterValue: any) => {
  const value = JSON.stringify(filterValue);
  localStorage.setItem(`${FILTER_STORAGE_PREFIX}${filterName}`, value);
};

export const getFilterValueFromLocalStorage = (filterName: string) => {
  const item = localStorage.getItem(`${FILTER_STORAGE_PREFIX}${filterName}`);
  return safelyParseJSON(item);
};

export const getSettingFromLocalStorage = (settingName: string) => {
  const settingItem = localStorage.getItem(`${SETTINGS_STORAGE_PREFIX}${settingName}`);
  return safelyParseJSON(settingItem);
};

export const saveValueToLocalStorage = (key: string, value: any) => {
  const strValue = JSON.stringify(value);
  localStorage.setItem(`${key}`, strValue);
};

export const getValueFromLocalStorage = (key: string) => {
  const settingItem = localStorage.getItem(`${key}`);
  return safelyParseJSON(settingItem);
};

export const LOCAL_STORAGE_ORG_CODE = 'orgCode';

export const setOrgCode = (org: string) => {
  saveValueToSessionStorage(LOCAL_STORAGE_ORG_CODE, org);
};

export const getOrgCode = () => {
  return getValueFromSessionStorage(LOCAL_STORAGE_ORG_CODE);
};

export const LOCAL_STORAGE_PERMISSIONS_KEY = 'permissions';

export const setPermissions = (permissions: string[]) => {
  const value = JSON.stringify(permissions);
  window.localStorage.setItem(LOCAL_STORAGE_PERMISSIONS_KEY, value);
};

export const getPermissions = (): string[] => {
  const value = window.localStorage.getItem(LOCAL_STORAGE_PERMISSIONS_KEY);
  return value ? JSON.parse(value) : [];
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  // If both are the same object reference or are strictly equal,
  // return true
  if (obj1 === obj2) {
    return true;
  }

  // If either is not an object or is null, return false
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;

    for (let i = 0; i < obj1.length; i++) {
      if (!deepEqual(obj1[i], obj2[i])) {
        return false;
      }
    }

    return true;
  }

  // Get the keys of each object
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If they don't have the same number of keys, return false
  if (keys1.length !== keys2.length) {
    return false;
  }

  // For each key in the first object
  for (const key of keys1) {
    // If the second object doesn't have the key or their values aren't deeply equal, return false
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  // If none of the above conditions were met, the objects are deeply equal
  return true;
};
