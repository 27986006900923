import React from 'react';
import { NavLink } from 'react-router-dom';
import BreadcrumbModel from './breadcrumb.models';

export default function Breadcrumbs({ pages }: { pages: BreadcrumbModel[] }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {pages.map((page, idx) => (
          <li key={page.name}>
            <div className="flex items-center">
              {idx !== 0 && (
                <svg
                  className="h-5 w-5 flex-shrink-0 text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              )}
              <NavLink
                to={page.path}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </NavLink>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
