/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from '../../utils/tailwind';

const activeNav = 'bg-gray-200 text-gray-900';
const inactiveNav = 'text-gray-700 hover:bg-gray-50 hover:text-gray-900';

export default function TopNavItem({ item }: any) {
  return (
    <NavLink
      key={item.name}
      to={item.path}
      className={({ isActive }) =>
        classNames(
          isActive ? activeNav : inactiveNav,
          'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5'
        )
      }
      aria-current={item.current ? 'page' : undefined}
    >
      <item.icon
        className={classNames(
          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-600',
          'mr-3 h-6 w-6 flex-shrink-0'
        )}
        aria-hidden="true"
      />
      {item.name}
    </NavLink>
  );
}
